@require 'includes/*'

.filter
  @media M_and_up
    display block
    max-height 240px
    overflow-y auto

    &__active
        margin-left 15px
        width calc(100% + 175px)

  &__close
    position absolute
    top 10px
    right 10px
    cursor pointer

    @media up_to_M
      display none

  &__buttons
    position absolute
    bottom 10px
    right 25px
    display flex

    @media up_to_M
      display none

  &__item
    font-size 14px

  &__items
    list-style-type none
    margin 0
    padding 0 16px

  &--size
    .filter__item
      display inline-block
      cursor pointer

      input[type=checkbox]
        display none

  &--color
    .filter
      &__item
        display inline-block
        width 20%

        @media up_to_L
          width 25%

        @media up_to_M
          width 100%

        &.disabled
          width 0

        input[type=checkbox]
          display none

      &__label
        display flex
        align-items center
        margin-bottom 0


  &--checkbox
    .filter__item
      display inline-block
      vertical-align top
      margin 8px 15px
      width calc(25% - 30px)

      @media XL_and_up
        width calc(20% - 30px)

      @media up_to_M
        display block
        margin 10px 0
        width 100%


  &__selected-filter
    background-color transparent
    margin-top 8px
    @media up_to_M
      display none

    &--all
      cursor pointer
      line-height 19px
      padding 0 10px 0 10px
      margin-left 4px
      inline-block()
      color $witt-secondary-text
      vertical-align middle
      text-decoration underline
      font-size 14px

      @media up_to_M
        flex-grow 0
        flex-shrink 0
        position absolute
        left 0
        bottom 10px
        margin-left 0px

    &__entry
      cursor pointer
      inline-block()
      margin 0 16px 0 0
      color $witt-secondary-text

      @media up_to_M
        flex-grow 0
        flex-shrink 0
        margin 0 4px 54px 0
        padding 2px 10px 6px 10px
        border 1px solid $witt-secondary-text

      i.icon
        background-color $witt-secondary-text !important

      &:hover
        color $witt-black-text

        i.icon
          background-color $witt-black-text !important


    &__name
      font-size 14px
      line-height 14px
      vertical-align middle
      padding-right 2px
