@require 'includes/*'

.products-column-switch
  align-self flex-end
  border 1px solid $witt-default-border
  border-radius 8px
  padding 4px
  width 100%
  background-color $witt-white
  display flex
  justify-content center
  margin-left 5px
  
  &__columns
    reset-button-style()
    opacity 0.2
    display inline-block
    background-size 70% auto
    background-position center center
    background-repeat no-repeat
    margin-left 0px
    width 32px
    height 32px
    
    &--4
      position relative
      background-image url(../img/app/rwd/icons/icon-grid-3x3.svg)
      
      @media S_to_L
        background-image url(../img/app/rwd/icons/icon-grid-big-mobile.svg)

      @media up_to_S
        background-image url(../img/app/rwd/icons/icon-grid-template-1.svg)
      
    &--6
      background-image url(../img/app/rwd/icons/icon-grid-big.svg)
      
      @media S_to_L
        background-image url(../img/app/rwd/icons/icon-grid-3x3.svg)

      @media up_to_S
        background-image url(../img/app/rwd/icons/icon-grid-template-2.svg)
      
    &--active
      opacity 1
      
  &__seperator
    display inline-block
    content ''
    height 24px
    margin-top 4px
    width 1px
    border-right 1px solid $witt-default-border
    opacity 1
    

