@require 'includes/*'

.add-to-basket-button
  font-size 16px
  line-height 32px
  font-weight bold
  padding 8px 12px
  border-radius 5px
  color $witt-white
  background-color $witt-black
  border 1px solid $witt-black
  cursor pointer
  width 100%
  display flex
  align-items center
  justify-content center

  &:hover, &:focus, &:active
    color $witt-black-text
    background-color $witt-tertiary
    border-color $witt-tertiary

    .add-to-basket-button__icon
      background-color $witt-primary

  &:focus, &:active
    border-color $witt-black

  &__icon
    margin-right 10px

  &:focus
    outline-style: none
    box-shadow: none
    border-color: transparent