@require '../includes/*'

#mv
    .pageheader

        .shoplogo
            @media up_to_M
                width 50px

            @media M_to_L
                height 38px

            @media L_and_up
                width 80px
                height 70px

        &.condensed
            .shoplogo--confirmation
                margin 10px 0px