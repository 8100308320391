@require 'includes/*'

#helline
  color $helline-dark-blue
  font-family $helline-font-primary
  font-weight: 400

  .favicon
    background-image url(/img/app/rwd/favicon/favicon-helline.ico)
    width 48px
    height 48px

  .shoplogo
    background-image url(/img/app/rwd/helline/logo.png)

  span.link
    color $helline-black

  h1
    font-weight 500
    margin-bottom .5rem
    color $helline-dark-blue

  .text-s
    font-size 14px

  .forms__input--rounded
    border-radius unset

  .icon__wishlist
    background-image url(../img/app/rwd/helline/icon-wishlist.svg)
  .icon__wishlist-full
    background-image url(../img/app/rwd/helline/icon-wishlist-full.svg)
    background-color unset !important

  .header-icon
    i.icon
      background-color $helline-white !important

  .bread-crumb
    a
      color $helline-grey
    ul
      li
        color $helline-grey
        &:last-child
          color $helline-dark-blue

    &__arrow
      background-color #66657b !important

  .pagecontent
    @media L_and_up
      width 100%
      max-width unset

  .container-fluid
    padding 0

  .btn__with-icon
    display flex
    justify-content space-between
    align-items center

  .btn__with-icon--hellineonly
    justify-content space-between

  .btn, .helline-btn
    border-radius 0
    padding 12px 15px
    font-size 16px
    line-height 20.8px

    &-small
      padding 6px 10px
      font-size 13px
      line-height 13px

    &-primary
      background-color $helline-dark-blue
      border-color $helline-dark-blue
      font-weight 700


      &:hover, &-hover, &:focus, &-focus, &:active, &-active
        border-color $helline-dark-blue
        background-color $helline-white
        color $helline-dark-blue
        text-decoration none

        i
          background-color $helline-dark-blue !important

      &:focus, &-focus, &:active, &-active
        outline none !important

      &:disabled, &-softdisabled, &-disabled
        background-color $helline-secondary
        border-color $helline-secondary
        color $helline-secondary-hover

        i
          background-color $helline-secondary-hover !important

      i
        background-color $helline-white !important

    &-secondary, &-witt-primary-to-helline-secondary
      background-color $secondary-navigation-pill-background
      border-color $secondary-navigation-pill-background
      color $helline-dark-blue !important
      font-weight 700
      text-transform uppercase

      & i
        background-color $helline-dark-blue !important

        &:hover, &-hover
          background-color $helline-dark-blue !important

      &:hover, &-hover
        text-decoration none
        background-color $helline-secondary-hover
        border-color $helline-secondary-hover

        .go-back-button--icon
          background-color $helline-white

      &:disabled, &-softdisabled, &-disabled
        i
          background-color $helline-secondary-text !important

    &-witt-tertiary-helline-secondary
      background-color $helline-grey-light2
      border-color $helline-grey-light2
      color $helline-dark-blue !important
      font-weight 700
      text-transform uppercase
      padding 8px 15px

      .icon__color--inverted
        background-color $helline-dark-blue !important

      &:hover
        text-decoration none
        background-color $helline-grey
        border-color $helline-grey
        color $helline-dark-blue !important

    &-addwishlist
      color $helline-dark-blue !important
      text-transform none
      font-weight 400

      .icon__wishlist
        background-color $helline-dark-blue !important

      &.active
        .icon__wishlist
          background-image url(../img/app/rwd/helline/icon-wishlist-full.svg)

    &-tertiary
      background-color $helline-dark-blue
      border none
      border-bottom 1px solid $helline-dark-blue
      color $helline-white
      font-weight 700
      text-align left
      font-size 14px
      line-height 1.6
      padding 12px 15px
      text-transform uppercase
      @media screen and (max-width: $medium)
        width 40%

      &:hover, &-hover
        background-color $helline-black !important
        color $helline-white !important

        &:disabled
          background-color #a09faf !important

      &:disabled, &-softdisabled, &-disabled
        background-color #a09faf !important
        border-color #a09faf

        &:hover, &-hover
          background-color #a09faf !important

    &-ghost
      border none
      text-transform uppercase
      font-weight 300
      padding-bottom 4px
      border-bottom 1px solid $helline-grey

    &-helline-arrow
      display block
      text-align left
      font-size 13px
      line-height 13px
      font-weight 700
      padding 24px 50px 24px 20px
      background-image url(/img/app/rwd/helline/arrow-right.svg)
      background-repeat no-repeat
      background-position right 20px center
      background-size 18px auto

    &-helline-arrow-left
      display block
      text-align left
      font-size 13px
      line-height 13px
      font-weight 700
      padding 24px 50px 24px 20px
      background-image url(/img/app/rwd/helline/arrow-left.svg)
      background-repeat no-repeat
      background-position right 20px center
      background-size 18px auto

    &-small
      padding 9px 10px
      font-size 13px
      line-height 13px
      color $helline-white
      border 2px solid transparent
      white-space normal
      border-radius 0

      &.btn-helline-arrow
        padding-right 40px
        background-position right 17px center

      &.btn-helline-arrow-left
        padding-left 40px
        background-position left 17px center

    &-white
      &:hover
        background-color $helline-grey-light
        border-color $helline-grey-light

    &-full-width
      width 100%
    &-with-icon
      &--first
        .icon__arrow-left
          sprite($icon_arrow_left_helline)

  .helline-btn
    &-secondary
      border unset

  .input
    color $helline-dark-blue
    border-radius unset
    border-color $helline-border-secondary

    &:hover, &:focus, &:focus-visible
      border-color $helline-border-neutral

    &:disabled
      border-color $helline-border-secondary
      color $helline-text-disabled

    &.success
      color $helline-dark-blue
      border-color $witt-success

    &.error
      color $helline-dark-blue
      border-color #D60033

    &-label
      color $helline-dark-blue
      &.disabled
        color $helline-text-disabled

  .textarea
    color $helline-dark-blue
    border-radius unset
    border-color $helline-border-secondary

    &:hover, &:focus, &:focus-visible
      border-color $helline-border-neutral

    &:disabled
      border-color $helline-border-secondary
      color $helline-text-disabled

    &.error
      border-color #D60033

    &-label
      color $helline-dark-blue
      &.disabled
        color $helline-text-disabled

  .custom-radio
    &__input
      border-color $helline-border-secondary

      &::before
        background $helline-dark-blue

      &:checked
        border-color $helline-border-neutral

      &:hover, &:focus, &:focus-visible
        border-color $helline-border-neutral

      &:disabled
        border-color $helline-text-disabled

        &::before
          background $helline-text-disabled

      &.error
        border-color #D60033

    &__label
      color $helline-dark-blue

    &--disabled
      .custom-radio__label
        color $helline-text-disabled

  .material
    .select-wrapper
      span
        &.caret
          z-index 1
          right 5px
          font-size 8px

      .select-dropdown
        padding-top 0 !important
        padding-bottom 0 !important

    .error
      border-bottom none !important

    .material__radio, .radio
      input[type=radio]+.material__label
        &::before
          border-color $helline-dark-blue !important

      input[type=radio]:not(:checked)+label:before
        border-color $helline-dark-blue !important

    .material__radio--prem
      label
        margin-bottom 0
        span
          font-weight 800
          font-size 16px

      input[type=radio]+label:before
        border-width 1px !important
      input[type=radio]:not(:checked)+label:before
        border 1px solid $border-color-secondary !important

      input[type='radio']:checked + label:after
        border 2px solid white
        background-color $helline-dark-blue

    input[type='radio']:checked + label:after
      background-color $helline-dark-blue

    input[type='radio']:checked + label:before
      border 1px solid $helline-dark-blue

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number]
      border 1px solid $helline-grey
      color $helline-dark-blue
      font-size 13px !important
      border-radius 0 !important

      &:focus
        border 1px solid $helline-dark-blue

      &.error
        border 1px solid $helline-error !important
        background-image url(/img/app/rwd/helline/form-error.svg)

      &.success
        background-image url(/img/app/rwd/helline/form-success.svg)


      &::-webkit-input-placeholder
        color $helline-grey-lighter

      &::-moz-placeholder
        color $helline-grey-lighter

      &:-ms-input-placeholder
        color $helline-grey-lighter

      &:-moz-placeholder
        color $helline-grey-lighter

    .input-field

      label
        color $helline-dark-blue
        font-size: 13px !important

      textarea
        border 1px solid $helline-grey
        color $helline-dark-blue
        border-radius 0!important

        &:focus
          border 1px solid $helline-dark-blue

    label
      color $helline-dark-blue

      span
        font-weight 400
        color $helline-grey

        &.bold
          color inherit
          font-weight 700

  .checkbox-with-label
    input[type=checkbox]
      border-radius 1px
      border-color $helline-border-secondary

      &:hover
        border-color $helline-border-neutral

      &:checked
        border-color $helline-border-neutral
        background #FFFFFF url('/img/app/rwd/helline/check-checkbox.svg') center center no-repeat

      &:disabled
        $helline-border-secondary

      &.error
        border-color #D60033

  .togglePassword
    font-weight 700

  .custom-dropdown
    box-shadow 0 0 10px rgba(0, 0, 0, 0.3)

    &__opener
      background-color white

      .placeholder
        color $helline-grey

    ul
      box-shadow 0 10px 10px rgba(0, 0, 0, 0.3)
      background-color white

      li
        background-color white

        &:hover
          background-color $helline-grey-light

    h1
      font-family $helline-font-secondary
      background-image url(/img/app/rwd/helline/h-bg.svg)
      background-size auto 122px
      background-position center -31px
      background-repeat no-repeat

    &__tooltip
      top -3px
      transform scale(0.8)

    &__supplementary-text
      font-size 14px
      color $helline-grey

    .tipr_content
      font-family $helline-font-primary
      font-size 12px
      line-height 1.2em
      text-align center
      background-color $helline-dark-yellow

    .tipr_container_top
      margin-top -90px

    .tipr_point_top, .tipr_point_bottom
      background-color $helline-dark-yellow

    .tipr_point_top:after
      border-top-color $helline-dark-yellow

    .tipr_point_bottom:after
      border-bottom-color $helline-dark-yellow

    .unknownEmailError
      border-color $helline-error !important
      color $helline-error !important

  .custom-dropdown--new
    box-shadow none //remove this after removing the old styling

    &.custom-dropdown--error
      .custom-dropdown__opener
        border-color $helline-error

    .custom-dropdown__opener
      border-radius unset
      border-color $helline-border-secondary

      &:hover
        border-color $helline-border-neutral

      &.active
        border-color $helline-border-neutral
        border-bottom-color $helline-border-secondary

  .registration
    h1
      font-family $helline-font-secondary
      background-image url(/img/app/rwd/helline/h-bg.svg)
      background-size auto 17px
      background-position center 23px
      background-repeat no-repeat

    &__supplementary-text
      font-size 14px
      color $color-info-background-neutral

    .info
      font-size 14px
      color $helline-grey

    &__password-strength

      &__headline
        color $helline-grey

      ul
        li
          color $helline-dark-blue
          list-style-position inside

          &:last-child
            margin-bottom 0

          &.negative
            color $helline-error

          &.positive
            color $helline-green

      &__result
        color $helline-dark-blue

        span.password-fail
          color $helline-error

        span.password-success
          color $helline-green

    &.modify
      label
        color $helline-dark-blue !important

  .account
    &__content
      color $helline-dark-blue
      &:bordered
        border-color $helline-grey-light
    &__topheadline
      font-family $helline-font-secondary
      color $helline-dark-blue

    &__divider
      @media screen and (max-width: $medium)
        display block
        border-top 4px solid $helline-divider
        margin-top 4px
        margin-bottom 3px
        width 100px
        left 50%
        position relative
        transform translateX(-50%)

    &__customer-info
      font-family $helline-font-primary
      &__no
        color $helline-dark-blue

      &__salutation-container
        @media screen and (max-width: $medium)
          font-size 15px
        @media screen and (min-width: $medium)
          font-family $helline-font-secondary
        &__salutation, &-name
          font-weight 700
          font-size 20px

          @media screen and (max-width: $medium)
            font-weight 500
            font-size 15px
            margin-bottom 0

    &__navigation
      ul
        li
          border-bottom 1px solid $helline-grey-lighter

          &:hover, &.active
            background-color $helline-grey-light2

      @media screen and (min-width: $medium)
        &__headline
          color $helline-dark-blue

      &__entry
        &:hover,&:active
          background $helline-grey-light2

        @media screen and (max-width: $medium)
          background $helline-grey-light2
          color $helline-dark-blue
          border-radius 0
          &:hover,&:active
            background $helline-dark-blue
            color $helline-white

        &__container
          &--logout
            @media screen and (max-width: $medium)
              border 1px solid $helline-dark-blue
            .account__navigation__headline
              @media screen and (max-width: $medium)
                color $helline-dark-blue
              @media screen and (min-width: $medium)
                color $helline-grey-light3
            &:hover,&:active
              background $helline-grey-light2
              @media screen and (max-width: $medium)
                background $helline-dark-blue

                .account__navigation__headline
                  color $helline-white
                  background $helline-dark-blue
                  &:before
                    &:hover,&:active
                      background-image url(../img/app/rwd/icon-logout-gray.svg)
      &__pills
        .account__navigation__pill
          color $helline-dark-blue !important
          background-color $secondary-navigation-pill-background
        .account__navigation__pill.active,.account__navigation__pill:hover
          background-color $helline-dark-blue
          color $helline-white !important

      &__icon
        background $helline-grey-light3

    &__contact-info
      background-color $helline-brown

    &__content
      h1
        font-family $helline-font-secondary
        background-image url(/img/app/rwd/helline/h-bg.svg)

        background-repeat no-repeat
        padding-bottom 5px
        background-size: auto 193px;
        background-position: 15px -64px;

        @media screen and (max-width: $medium)
          background-size auto 182px
          background-position 15px -61px
          font-size 34px

      &__headline
        font-weight 700

      &__bordered
        background-color $helline-lightest-grey
        border-color $helline-grey-light

    &__download-print
      text-align center
      font-size 14px
      a
        color $helline-dark-blue

    &__entry
        h2
          font-family $helline-font-secondary

    &__orders
      color $helline-dark-blue
      border-color $border-color-secondary

      &__heading
        font-weight 700

      &_detail--premium
        border-color $helline-premium

      &_buttons-row
        border-color $helline-grey-light
      &__table
        color $helline-dark-blue

        a
          color $helline-dark-blue
        th, td
          border-color $border-color-secondary

      .account__orders_parcel_detail
        border-color $border-color-secondary

        &_cell
          .label
            color $helline-grey

      .account__orders_parcel_item
        border-color $border-color-secondary

        .parcel_item_image
          border-color $helline-grey-light

        .parcel_item_detail_heading
          font-family $helline-font-secondary

        .parcel_item_detail_ref
          color $helline-grey

        .parcel_item_detail_label
          color $helline-grey
      .account_order__addresses
        .account__orders_address
          border-color $border-color-secondary

          .address_type
            color $helline-grey

          .address_type_heading
            color $helline-dark-blue

          .address_detail_heading
            color $helline-grey

    &__tab
      &__headline
        font-size 18px
        font-weight 700
        font-family Mulish,sans-serif !important

    &__addresses
      &__headline
        h2
          font-weight 700
      &__box
        color $helline-dark-blue
        margin 0 0 30px 0
        min-height 130px !important
        position relative

        &__content
          margin-left 26px

        &__selection
          position: absolute
          left 15px
          width 20px

          input
            &[type=radio]
              & + label
                border 1px solid $helline-dark-blue

              &:checked
                & + label
                  background: url('/img/app/rwd/helline/adress_radio_button.jpg') center center no-repeat !important;
                  background-color $helline-dark-blue

    &__favorites
      &__article
        &__picture
          &__icon
            background-image url(/img/app/rwd/helline/icon-like.svg)
            background-size 15px auto
            background-position center center
            background-repeat no-repeat
            width 22px
            height 22px
            background-color white
            border-radius: 50%

        &__price
          .new
            color $helline-error

        &__attribute
          &__name
            color $helline-grey

        &__remove
          background-image url(/img/app/rwd/helline/icon-wishlist-remove.svg)
          background-size 12px auto
          background-position left center
          background-repeat no-repeat
          padding-left 17px

    &__password
      &__success
        color $helline-green

    &__returns
      &__headline
        h2
          font-weight 700

  .account-navigation-layer
    @media M_and_up
      top 50px

    &__btn
      .btn-standard
        background-color $helline-dark-blue
        border-color $helline-dark-blue
        font-size 16px
        height 40px
        padding-top 11px
        padding-bottom 11px
        text-transform uppercase
        background-size 22px auto

        &:hover
          background-color $helline-black
          border-color $helline-black

    .icon__arrow-right
      .btn-logout
        padding 0

  .mini-basket-layer-container
    top 17px

  .wishlist
    &__empty
      &__heart
        background-image url(../img/app/rwd/helline/icon-wishlist.svg)

      &__cta
        background-color $witt-secondary

    &__cta
      &__login
        background-color $helline-grey-lighter

        &__link
          color $helline-dark-blue

    &__products
      .article
        &__info
          color $helline-dark-blue

  .wishlist-article

    &__price
      .witt-price
        &__eco-tax
          color $helline-grey-light4

    &__delete
      background-image url(../img/app/rwd/helline/wishlist-delete.svg)

  .wishlist-slider
    h2
      font-family $helline-font-primary
      font-size 32px
      line-height 34px
      text-align center
      display flex
      justify-content center

      @media screen and (max-width: $medium)
        font-size 20px
        line-height 24px

      span
        font-family $helline-font-primary
        color $helline-grey
        font-size 24px
        margin-left 4px

        @media screen and (max-width: $medium)
          font-size 20px

  .icon
    &__wishlist
      &.active, &:hover
        @media screen and (min-width: $medium)
          background-image url(/img/app/rwd/helline/icon-add-wishlist-active.svg)

    &__color--premium
      background-color $helline-premium

  .modal
    .modal-content
      border-radius 0

    .modal-body

      p
        color $helline-grey
        font-size 14px

      .material
        margin-bottom 0

        input[type=radio]:checked+label, input[type=radio]:not(:checked)+label
          height 10px

      .btn
        display flex
        margin 20px auto 0 auto
        align-items center

  .shipping
    margin-bottom 0
    &__headline
      font-family $helline-font-secondary
      color $helline-dark-blue
      font-weight 700

    &__subheadline
      color $helline-dark-blue

    &__detail
      &__head
        &__inner
          border-bottom 1px solid $helline-grey-light

      &__phone-selection
        background-color $helline-grey-light2

    &__block
      &__radio
        background-color $helline-white
        border 1px solid $helline-dark-blue

      &__inner
        &.active
          border 2px solid $helline-dark-blue

          .shipping__block__radio
            border 5px solid $helline-dark-blue

      &__address
        .color
          &--red
            color $helline-dark-blue

      &__sans-supplement
        display none

      &__icon
        &--express
          width 59px
          height 42px
          background-image url(../img/app/rwd/icon-shipping-colissimo.png)
          background-size contain

    &__modal
      .modal-body
        p
          color $helline-dark-blue

  .payment
    margin-bottom 0
    &__headline
      font-family $helline-font-secondary
      color $helline-dark-blue
      font-weight 700

    &__subheadline
      font-family $helline-font-secondary
      color $helline-dark-blue
      font-weight 700
      margin-bottom 20px

    &__text
      color $helline-grey

    &__tab
      &__checkbox
        background-color $helline-white
        box-shadow none
        border 1px solid $helline-grey

      &__subtitle
        color $helline-dark-blue

      &__content
        &__selection
          &__headline
            color $helline-dark-blue

      &:hover
        border 1px solid $helline-dark-blue

      &.active
        border 1px solid $helline-dark-blue

  .confirmation
    margin-bottom 0

    &__headline
      font-family $helline-font-secondary
      color $helline-dark-blue
      font-weight 700

    &__subheadline
      font-family $helline-font-secondary
      color $helline-dark-blue
      font-weight 700

    &__text
      color $helline-grey

      .highlight
        color $helline-dark-blue

    &__shipping
      border-top 1px solid $helline-grey-light

      &__text
        p
          color $helline-grey

  .checkout
    &__advantages

      ul
        li
          border-color $helline-grey-light

      &__text
        span
          color $helline-grey

      &__icon
        &--payment
          background-image url(../img/app/rwd/helline/checkout-advantage-payment.svg)

        &--period
          background-image url(../img/app/rwd/helline/checkout-advantage-period.svg)

        &--returns
          background-image url(../img/app/rwd/helline/checkout-advantage-returns.svg)

    .summary-data
      color $helline-dark-blue
      #btnCheckout
        justify-content space-between

      &__big-headline
        color $helline-dark-blue
        font-family $helline-font-secondary
        margin-bottom 20px

      &__headline
        color $helline-dark-blue

      &__content
        color $helline-dark-blue

      &__article
        .summary-data__headline-small
          color $helline-dark-blue

        &--premium
          .summary-data__headline-small, .summary-data__price-small
            color $helline-premium

      .summary-data__price-small
        &.align-bottom
          margin-bottom 0

      &__block
        border-bottom 1px solid $helline-grey-light

        &:last-child
          border-bottom none

        &--no-border
          border none

        &__total
          padding 16px 8px
          background-color $helline-lighter-blue
          font-size 20px
          line-height 26px
          color $helline-dark-blue
          @media screen and (max-width: $large)
            margin-bottom 16px
            font-weight 800
            padding 16px


      .color--red
        color $helline-dark-blue

    .summary
      &__table

        tr
          td
            padding-bottom 10px

            &.right
              font-weight 700

          td.summary__table-cell-premium
            padding-bottom 0

      &__total
        background-color $helline-lighter-blue

        td
          color $helline-dark-blue
          padding 10px
          font-size 20px

          @media screen and (max-width: $large)
            font-size 13px

      &__savings
        display inline-block
        float right
        padding 3px 10px
        color white
        text-align right
        font-size 16px
        background-color $helline-green-light
        margin-top -4px
        position relative

        &:before
          content ''
          display block
          width 0
          height 0
          position absolute
          border-left 5px solid transparent
          border-right 5px solid transparent
          border-bottom 5px solid $helline-green-light
          top 0
          margin-top -5px
          right 12px
          z-index 1

  .checkout-header
    &__step
      &__circle
        background-color $helline-brown-light
        color $helline-dark-blue
        font-size 16px
        line-height 16px
        font-weight 400
        display flex
        align-items center
        justify-content center

        &--visited
          background-color $helline-white
          background-image url(/img/app/rwd/helline/check.svg)
          background-position center center
          background-repeat no-repeat
          background-size 50% auto

          span
            display none

      &__text
        font-size 16px

  .basket
    margin-bottom 0

    .go-back-button
      &--back
        display flex
        min-width 40px
        height 40px
        transition all 0.1s
        align-items center
        border-radius unset
        padding 10px

      &--icon
        margin-right 5px
        margin-left -10px

      &--text
        display block
        text-transform none

    &__advantages
      background-color $helline-brown

    &__headline
      font-family $helline-font-secondary
      color $helline-dark-blue
      font-weight 700


    &__empty
      &__icon
        display block
        margin 0 auto 10px auto
        width 50px
        height 50px
        background-image url(/img/app/rwd/helline/empty-basket.svg)
        background-position center center
        background-repeat no-repeat
        background-size contain

    .line-item
      border-bottom 1px solid $helline-grey-light

      &__delete
        float right

      &__duplicate-link
        background-color $helline-dark-blue
        border-color $helline-dark-blue
        width 13px
        height 13px

        .icon__color--secondary
          background-color $helline-white !important

      &__link
        text-decoration none !important
        color $helline-grey !important

      &:first-child
        border-top 1px solid $helline-grey-light

      &__picture
        img
          border 1px solid $helline-grey-light

      &__headline
        font-weight 700
        font-family $helline-font-secondary
        font-size 17px

      &__ref
        font-family $helline-font-primary
        color $helline-grey
        font-weight 400

      &__modify
        a
          color $helline-grey

          &.active, &:hover
            text-decoration underline !important

      &__delete
        a
          color $helline-grey

          &.active, &:hover
            text-decoration underline !important

      &__wishlist
        a
          color $helline-grey

          &:before
            background-image url(/img/app/rwd/helline/icon-add-wishlist.svg)

          &[data-remove-from-wishlist]
            &:before
              background-image url(/img/app/rwd/helline/icon-add-wishlist.svg)

        &.active, &:hover
          a
            text-decoration underline !important
            &:before
              background-image url(/img/app/rwd/helline/icon-add-wishlist-active.svg)

      &__price-reduction--percent
        background-color $helline-error

      &__price-reduction--promotion
        background-color $helline-green-light

      &__price
        color $helline-dark-blue

      &__old-price
        color $helline-grey

      &__eco-tax
        color $helline-grey

    &__promo-code
      background-color $helline-grey-light2

      &__icon
        background-image url(/img/app/rwd/helline/basket-percent.svg)

      &__text
        font-family $helline-font-secondary

      &__info
        &__icon
          background-image url(/img/app/rwd/helline/check-circle.svg)
        &__text
          font-family $helline-font-secondary

          span
            font-family $helline-font-primary
            color $helline-turquoise

        &__description
          color $helline-turquoise

    &__contact-info
      background-color $helline-brown

    .direct-order
      &__product
        &__price
          .ecotax
            color $helline-grey

      .icon__direct-order
        background-position center center
        width 30px
        height 30px
        background-size contain
        background-repeat no-repeat
        background-image url(/img/app/rwd/helline/direct-order.svg)

      &__headline
        &__inner
          width calc(100% - 42px)

      .material
        .select-dropdown
          padding-left 5px !important

        .select-wrapper
          ul
            padding-left 0 !important

    &__delete-confirm
      &__popup
        &__link
          color $helline-white
          border 0
          background-color $helline-dark-blue
        &__close
          border 0
          background-color $helline-grey-light2

    &__wishlist-confirm
      &__popup
        background-color $helline-green-lighter

    .recommendations
      &__headline
        font-family $helline-font-primary
        color $helline-dark-blue

  .gift-container
    border none
    width 100%

  .gift
    &.popup
      @media screen and (max-width: $medium)
        margin-left -50px
        margin-right -50px
        width calc(100% + 100px)

  .gifts
    width 100%
    padding 0 10px	0 10px


  .footer
    &__advantages
      width 100%
      background-color $helline-brown
      border-top 10px solid $helline-dark-blue

      &__inner
        width 100%
        max-width 1170px
        margin 0 auto
        padding-left 15px
        padding-right 15px
        padding-top 35px
        padding-bottom 20px
        display flex
        justify-content space-between

        @media up_to_M
          flex-wrap wrap

    &__advantage
      text-align center
      color $helline-dark-blue
      padding 0 20px
      width 20%

      @media up_to_M
        width 100%
        margin-bottom 20px

      a
        &:hover
          text-decoration none

      .advantage

        &__headline
          font-weight 700
          color $helline-dark-blue
          font-size 13px
          line-height 13px

        &__text
          color $helline-dark-blue

  .category
    &__header
      &__back
        background-image url(../img/app/rwd/helline/arrow-left-121037.svg)

        &:hover
          background-color $helline-dark-blue
          background-image url(../img/app/rwd/icons/arrow-left-white.svg)

      &__headline
        h1
          font-family $helline-font-secondary
          color $helline-dark-blue
          font-weight 700
          font-size 28px
          line-height 38px

    &__show-more-articles
      &--button
        background-color $helline-dark-blue
        color $helline-white !important
        font-weight 500

    .article
      color $helline-dark-blue

      &__name
        a
          color $helline-dark-blue

      &__prices
        color $helline-dark-blue

      &__price
        .ecoTax
          font-size 11px
          color $helline-light-blue

        &__amount
          &--with-reduction
            color $helline-error

      &__colors
        &__count
          a
            color $helline-dark-blue

      &__color
        &:hover
          span
            &:after
              border 1px solid $helline-dark-blue

      &__brand
        color $helline-dark-blue

    &__sub-navigation
      ul
        a
          border-bottom none
          color $helline-dark-blue

      &__item
        width unset

        &:hover,
        &:active
          background-color unset


        &__count
          position relative
          right unset
          top unset

    &__options
      @media up_to_M
        background-color $helline-lightest-grey

        &--sticky
          background-color rgba($helline-lightest-grey, 0.5)

      &__sorting-switch
        .products-column-switch
          border 1px solid $helline-dark-blue


  .filter-list
    &__mobile-dropdown
      @media up_to_M
        border 1px solid $helline-dark-blue
        background-color $helline-dark-blue
        border-radius 0

    .dropdown
      @media M_and_up
        &__info--icon
          color $helline-grey


    &__item-button
      font-weight 700
      font-size 14px
      text-decoration none !important

      &:hover
        text-decoration underline !important
        color $helline-dark-blue !important

  .sorting
    &__item
      color $helline-dark-blue

      &:hover,
      &--active
        color $helline-white
        background-color $helline-dark-blue

    &.dropdown
      &--opened
        border none

    .dropdown
      &__header
        border 1px solid $helline-dark-blue
        border-radius 0
        padding 10px
        color $helline-dark-blue

  .products-column-switch
    border-radius 0

    &__columns
      &--4
        background-image url(../img/app/rwd/helline/icon-grid-3x3.svg)

        @media S_to_L
          background-image url(../img/app/rwd/helline/icon-grid-big-mobile.svg)

        @media up_to_S
          background-image url(../img/app/rwd/helline/icon-grid-template-1.svg)

      &--6
        background-image url(../img/app/rwd/helline/icon-grid-big.svg)

        @media S_to_L
          background-image url(../img/app/rwd/helline/icon-grid-3x3.svg)

        @media up_to_S
          background-image url(../img/app/rwd/helline/icon-grid-template-2.svg)

    &__seperator
      border-right 1px solid $helline-dark-blue

  .pager
    &__page
      border-radius 0
      border none
      color $helline-dark-blue
      background-color $helline-grey-light2
      font-weight 700
      height 35px
      padding-top 9px

      &--current
        background-color $helline-lighter-blue

  .product
    .reassurance-question
      padding 12px 10px

    &__title
      font-family $helline-font-secondary
      font-size 30px
      line-height 36px
      font-weight 700

    &__reference
      color $helline-grey

    &__description-features
      color $helline-dark-blue
      font-size 14px
      margin 20px 5%

      @media up_to_L
        margin 15px 0

      h2
        font-family $helline-font-secondary
        border none
        border-bottom 2px solid $helline-grey-light
        font-size 24px
        line-height 38px
        padding-bottom 4px

        @media screen and (max-width: $medium)
          font-size 20px
          line-height 28px
          font-weight 700
          padding 0 0 4px 0

      @media screen and (max-width: $medium)
        &__inner
          font-weight 300

    &__features
      dl
        margin-bottom 16px
        padding-bottom 16px
        border-bottom 1px solid $helline-grey-lighter

        @media screen and (max-width: $medium)
          padding-bottom 4px

      dt
        width 45%

      dd
        width 45%

    &__similar-items
      &__wishlist
        width 24px
        height 24px
        background-image url(/img/app/rwd/helline/icon-add-wishlist.svg)

        &.active, &:hover
          background-image url(/img/app/rwd/helline/icon-add-wishlist-active.svg)

      &__price
        &--highlight
          color $helline-error

    &__carousel
      &__wishlist
        background-image url(/img/app/rwd/helline/icon-add-wishlist.svg)

        &.active
          background-image url(/img/app/rwd/helline/icon-add-wishlist-dark-active.svg)

    #customer-reviews-container
      margin 20px 0 0
      @media screen and (max-width: $medium)
        margin 0
      @media screen and (min-width: $large)
        margin 20px 0 0

    &__summary
      &__reference-reviews
        font-size 12px

    &__customer-reviews
      &__headline
        h2
          font-family $helline-font-secondary
          font-size 24px
          line-height 38px
          padding-bottom 4px
          border-bottom 2px solid $helline-grey-light

          @media screen and (max-width: $medium)
            font-size 20px
            line-height 28px
            font-weight 700
            padding 0 0 4px 0

    &__selection

      .witt-price
        margin 0
        margin-bottom 16px

      .custom-dropdown
        color $helline-dark-blue

      &__add-to-basket
        padding 12px 15px
        border-radius 0
        background-color $helline-dark-blue
        border 1px solid $helline-dark-blue
        box-shadow: 0 0 1px 1px white;

        &:hover
          background-color $helline-beige
          border-color $helline-beige
          color $witt-black-text

        &:focus
          background-color $helline-beige
          border-color $helline-dark-blue
          color $witt-black-text

        &:active
          background-color $helline-beige
          border-color $helline-dark-blue
          color $witt-black-text

      &:disabled, .btn-primary-softdisabled
        background-color $helline-grey-lighter !important
        border-color $helline-grey-lighter !important
        color $witt-neutral-border !important

        &:hover
          color $witt-neutral-border !important

    &__wishlist-add
      padding 12px 15px
      position relative

      &:not(.active)
        &[data-text-add]
          &:hover
            &:after
              top 60px

        &[data-text-remove]
          &:hover
            &:after
              top 60px

  .color-selection
    ul
      li
        &.selected
          border 1px solid $helline-grey-dark

  .pswp
    &__top-bar
      background-image url(/img/app/rwd/helline/logo-padding.png)
      background-size auto 30px

  .witt-price
    &__reduction
      background-color $helline-error

    &__value
      color $helline-dark-blue

      &--discounted
        color $helline-error

  .pageheader
    &__metanav
      &__list-item
        &--contact
          background-image url(/img/app/rwd/helline/help-phone.gif)
          background-size contain
          background-position center center

  .info
    border-color $helline-green
    color $helline-green

  .to-top
    &__inner
      background-color $helline-lighter-blue
      color $helline-dark-blue
      font-weight 700
      position relative
      font-size 10px
      line-height 11px
      padding 31px 6px 0 6px

      &:before
        display block
        content ''
        position absolute
        left 30px
        top 13px
        width 16px
        height 20px
        background-image url(/img/app/rwd/helline/chevron-right.svg)
        background-repeat no-repeat
        background-position center right
        transform rotate(-90deg)

  .installment
    &__rates
      .summary-data__block
        border-bottom none

  .general-slider

    &__product
      color $helline-dark-blue

      &__name
        a
          color $helline-dark-blue

      &__name
        color $helline-dark-blue

      &__price
        &--highlight
          color $helline-error

      &__eco-tax
        color $helline-light-blue



      &__add-to-basket
        width 35px
        height 35px
        border-radius 0
        background-color $helline-dark-blue
        border 1px solid $helline-dark-blue

        &:hover
          background-color $helline-beige
          border-color $helline-beige
          background-image unset

        &:focus
          background-color $helline-beige
          border-color $helline-dark-blue
          background-image unset

  .dropdown
    border-radius 0

    &--opened
      border 1px solid $helline-dark-blue

      @media up_to_M
        border none

    &__header
      border-radius 0

      @media up_to_M
        padding-top 8px
        padding-bottom 8px

    &__content
      border-radius 0
      margin-top -1px
      border 1px solid $helline-grey-lighter

      @media up_to_M
        margin-top 0
        border none

  .wishlist

    &__headline

      h1
        color $helline-dark-blue
        font-weight 700

  .sub-navigation
    &__list__flyout
      top 50px

      &--categoryName
        background-color $helline-dark-blue

  .navigation
    @media L_and_up
      border-bottom none
      height 50px // related to the top value of .navigation__list__flyout
      margin-top 16px

    &__itemUnivers
      padding 15.8px 20px

    &__list--left
      @media L_and_up
        column-gap 1px

    &__list--right
      @media L_and_up
        right 0
        padding-right calc((100% - 1170px)/ 2)

      .sub-navigation__list__flyout
        @media L_and_up
          left 0
          margin-left calc(-100vw + 157.3px)
        @media XL_and_up
          left calc(-100vw + 157.3px)
          margin-left calc((100vw - 1200px)/ 2)

    &__list--left,  &__list--right,  &__list--rest
      font-size 14px

    &__row
      margin-right 0

    &__list
      &__item

        &-text
          @media L_and_up
            padding-bottom 16px
            &.is-active
              border none
              padding-bottom 16px

        &--mainCategory
          @media screen and (max-width: $large)
            &:after
              border-bottom none

          > span, > a
            color $helline-white

            @media up_to_M
              color $helline-dark-blue

            &.current
              background-color $helline-white
              color $helline-dark-blue

              &:after
                display none

          a, span
            @media up_to_L
              color $helline-dark-blue
              
          &:hover
            > span, > a
              color $helline-dark-blue

              &:after
                display none

        &--mobileMetanav
          border-bottom 1px solid $helline-grey-light2

          a, span
            @media up_to_M
              color $helline-dark-blue

    .mobile-compte-icon
      &--logged-in
        @media screen and (max-width: $large)
          &:before
            top 21px

  .mobile-navigation
    &__open
      height unset

      &--burger
        @media screen and (max-width: $large)
          background-color #FFFFFF

      &--text
        display none

  .filter-list
    &__header
      border-bottom 1px solid $helline-grey-lighter
    &__close
      color $helline-dark-blue

    .dropdown
      @media up_to_M
        &__info
          &--icon
            color $helline-grey

    .dropdown__title,
    .dropdown__title-opened
      color $helline-dark-blue
      &--visible
        font-weight 700

    &__buttons
      &--cancel
        color $helline-dark-blue

  .sorting
    &__layer-header
      border-bottom 1px solid $helline-grey-lighter
      color $helline-dark-blue

    .dropdown
      &__content
        border none


  .filter--checkbox

    .checkmark:after,
    .checkmark--checked:after,
      background-image url(/img/app/rwd/helline/check.svg)
      background-size 80% auto

    input
      &:checked ~ .checkmark
        border-color $helline-dark-blue

    .checkmark
      border 1px solid $helline-grey-light

  .filter

    &__label
      color $helline-dark-blue

    &__selected-filter

      &--all
        color $helline-dark-blue

      &__entry
        color $helline-grey

        i.icon
          background-color $helline-grey !important

        &:hover
          color $helline-dark-blue

          i.icon
            background-color $helline-dark-blue !important

    &__buttons
      align-items center
      .btn-link__secondary
        color $helline-dark-blue !important
        font-weight 700
        margin-right 10px


  .filter--color
    .color-tile
      @media screen and (min-width: $medium)
        &.selected, &:hover
          border 1px solid $helline-grey-dark
          color $helline-grey-dark

      &__name
        color $helline-dark-blue

  @media up_to_M
    .bread-crumb, .maincontainer, .linkbox__container, .seo_teaser
      padding-left 12px
      padding-right 12px

    .mobile-navigation__open--text
      color $witt-white

    .navigation__list

      &__flyout .backLink
        color $helline-font

    .pagecontent > .category
      padding-left 15px
      padding-right 15px

  @media M_and_up
    .bread-crumb, .pageheader, .maincontainer, .linkbox__container, .footer__navigation, .seo_teaser
      padding-left 15px
      padding-right 15px

  @media L_and_up
    .row
      position relative
    .category_sub-navigation
      background-color $witt-white

    .navigation
      background-color $helline-dark-blue
      color $witt-white

      &__list__item--mainCategory
        > span, > a
          padding 16px 16px 0
          color $helline-white
          font-size 14px
          line-height 18.2px
        > a:hover
          color: var(--custom-navigation-hover-color, $helline-dark-blue) !important

        &:hover
          > span, > a
            border-radius unset
            background-color white
            color $helline-dark-blue
            opacity 80%

        &.is-active
          > span, > a
            background-color #FFFFFF
            color $helline-dark-blue

  @media XL_and_up
    .bread-crumb, .pageheader, .sub-navigation__list__flyout > .row, .maincontainer, .linkbox__container, .seo_teaser, #info
      padding-left calc((100% - 1170px)/2)
      padding-right calc((100% - 1170px)/2)

    .navigation
      &__list--left
        padding-left calc(((100% - 1170px)/2) + 8px)
        left 0
        .sub-navigation__list__flyout
          left 15px

      &__list--right
        padding-right calc(((100% - 1170px)/2) - 8px)

    .footer__navigation
      padding-left calc(((100% - 1170px)/2) + 15px)
      padding-right calc(((100% - 1170px)/2) + 15px)

  .additional-box
    &__header
      font-family $helline-font-primary
      color $helline-dark-blue
      font-size 32px
      line-height 34px
      margin-bottom 24px

      @media screen and (max-width: $medium)
        font-size 20px
        line-height 24px
        margin-bottom 16px
        font-weight bold

    &__content
      margin-bottom 56px
      @media screen and (max-width: $medium)
        margin-bottom 24px


  .btn-primary-checkout
    background-color #5049CA
    border-color #5049CA

    &:hover
      background-color #fff
      border 1px solid #5049CA

      .btn-primary-checkout__label
        color #5049CA

      i
        background-color #5049CA !important

  .login__identification-button
    justify-content space-between

  .btn-logout-helline
    background-color unset
    border none
    color $helline-secondary-text
    font-size 16px
    padding 0
    justify-content flex-start

    i.account__navigation__icon
      background-color $helline-secondary-text !important
      padding-right 33px
      mask-position left

    &:hover, &:active, &:focus, &:focus-visible
      color $helline-secondary-text
      text-decoration underline
      box-shadow none !important

      i.account__navigation__icon
        background-color $helline-secondary-text !important

  .btn-address-modification-checkout
    border 2px solid $secondary-navigation-pill-background