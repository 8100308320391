@require '../includes/*'

#helline
    .header-container
        background-color $helline-dark-blue

        @media up_to_M
            padding-left 15px
            padding-right 15px

    .header
        &__search
            width 100%

            @media up_to_M
                margin-top 32px

            @media M_to_L
                width calc(100% - 370px)
                margin-top 6px
                margin-top 11px
                margin-bottom 14px

            @media L_and_up
                width calc(100% - 670px)
                max-width 320px

            @media XL_and_up
                max-width 375px

            &--text
                border none
                border-radius 0px
                height 47px

                &::placeholder
                    color $helline-secondary-text
                &::-webkit-input-placeholder
                    color $helline-secondary-text
                &::-moz-placeholder
                    color $helline-secondary-text
                &:-ms-input-placeholder
                    color $helline-secondary-text
                &:-moz-placeholder
                    color $helline-secondary-text

    .pageheader
        background-color $helline-dark-blue
        padding-top 0px

        @media up_to_M
            padding-top 20px
            height 134px
            margin-top 0px

            &__container
                height 47px

        &.condensed
            @media up_to_M
                height auto
                padding 0 15px

        @media M_and_up
            &__metanav
                margin-bottom 0
                &__list-item span.link
                    color $witt-white

        .shoplogo
            background-image url(/img/app/rwd/helline/logo.png)
            background-repeat no-repeat
            height 25px
            width 96px

            @media L_and_up
                height 60px
                width 120px

            &--general
                @media up_to_S
                    flex 1 0 auto
                @media screen and (max-width: 330px)
                    margin-left 38px
                @media L_and_up
                    margin 0 10px 0 0

        .header-icon-group
            @media up_to_L
                column-gap 0

            .miniBasketCount, .wishlistCount, .logged-in
                background #5049CA

            .plus-disc
                display none

            &__icon

                .header-icon
                    @media up_to_L
                        width 40px

                &:hover
                    background none
                    border-radius unset

                    .icon
                        background-color #FFFFFF

                    .header-icon__label
                        color #FFFFFF
                        text-decoration underline

            .header-icon__label
                @media up_to_L
                    display none

        &__container
            background-color $helline-dark-blue

        .pageheader__metanav__list-item,
        .header-icon__label
            color $witt-white

        .mini-basket-layer
            color $helline-dark-blue
            .color--red,
            &__price--total
                color $helline-dark-blue

            &__price
                background-color $helline-beige

        &.condensed
            .shoplogo--confirmation
                margin 10px 0px

        // PREMIUM
        &.pageheader--premium
            .header-icon-group__icon
                .wishlistCount, .miniBasketCount
                    background-color $helline-premium
                    color white
                    line-height 18px
                    width 18px
                    height 18px
            .logged-in
                background transparent url(/img/app/rwd/helline/premium.svg) center center no-repeat
                background-size 18px 18px
                width 18px
                height 18px
                position absolute
                right -8px
                top 4px
                &:after
                    display none

    .checkout-header
        &__step__text
            color $witt-white
            align-items center
        &--active
            color $witt-red
        @media up_to_M
            padding-left 15px
            &__steps
                margin-top 10px
            &.pageheader
                height 80px
                margin-bottom 10px
            .shoplogo
                width 75px!important
                height 40px

        @media M_and_up
            &--big
                height 135px

        .shoplogo--checkout
            @media up_to_M
                margin 0 5px !important
            @media M_to_L
                margin-top 30px
            @media L_and_up
                .shoplogo
                    margin 15px 30px 0
