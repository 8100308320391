@require 'includes/*'

.header-icon-with-number
  display flex
  position relative

.icon
  &__hide
    display none !important
  &__show
    display block

  &__measuring-tape
    mask url(../img/app/rwd/icons/measuring_tape.svg) no-repeat center
    -webkit-mask url(../img/app/rwd/icons/measuring_tape.svg) no-repeat center

  &__header_loggedin_badge
    transform scale(0.5) !important
    background-color $witt-success !important
    border-radius 50%
    position absolute
    top 4px
    bottom 0
    right -12px
    background-image url(/img/app/rwd/check-white.svg)
    background-size 70% auto
    background-position center center
    background-repeat no-repeat
    @media L_and_up
      top 4px
      right 24px

  &__color--inverted
    background-color #fff !important

  // START -- replace this block after merging the CSS branch and may use the icon there and delete this one
  &__attention--tbd
    mask: url(../img/app/rwd/icons/attention1.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/attention1.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color black
  &__branch-offices--tbd
    mask: url(../img/app/rwd/icons/branch_offices1.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/branch_offices1.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color black
  &__clock-tbd
    mask: url(../img/app/rwd/icons/clock1.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/clock1.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color black
  &__color--error--tbd
    background-color #e90037
  // END --

  &__logout
    background-image url(../img/app/rwd/icons/logout.svg)
    width 19px
    height 19px
    background-repeat no-repeat

  &__search
    background-image url(../img/app/rwd/icons/glass.svg)
    background-position center
    background-repeat no-repeat
    background-size contain
    width 28px
    height 28px

  &__check-circle
      background-image url(../img/app/rwd/icons/check-circle.svg)
      background-position center
      background-repeat no-repeat
      background-size contain
      width 18px
      height 18px
  &__question
    background-image url(../img/app/rwd/icon-question.svg)
    background-position center
    background-repeat no-repeat
    background-size contain
    width 23px
    height 23px

  &__header-wishlist
    mask url(../img/app/rwd/icons/heart.svg) no-repeat center
    -webkit-mask url(../img/app/rwd/icons/heart.svg) no-repeat center

  &__size
    &--XS, &--S, &--M, &--L, &--XL
      background-position center center
      background-repeat no-repeat
      vertical-align middle
      display inline-block
      background-color $witt-black
    &--XS //16px
      transform scale(0.67)
      width 24px
      height 24px
    &--S
      transform scale(1)
      width 24px
      height 24px
    &--M
      transform scale(1.5)
      width 33px
      height 33px
    &--L
      transform scale(2)
      width 48px
      height 48px
    &--XL
      transform scale(2.67)
      width 64px
      height 64px

  &__color
    &--primary
      background-color $witt-primary !important
    &--neutral
      background-color $witt-black !important
    &--inverted
      background-color $witt-white !important
    &--secondary
      background-color $witt-secondary-text !important
    &--success
      background-color $witt-success !important
    &--warning
      background-color $witt-warning !important
    &--error
      background-color $witt-error !important

  /* new Witt arrow icons*/
  &__arrow-right, &__arrow-right-slider
    mask url(../img/app/rwd/icons/arrow-right.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/arrow-right.svg) no-repeat center;
  &__arrow-left, &__arrow-left-slider
    -webkit-mask url(../img/app/rwd/icons/arrow-left.svg) no-repeat center;
    mask url(../img/app/rwd/icons/arrow-left.svg) no-repeat center;
  &__arrow-down
    mask url(../img/app/rwd/icons/arrow-down.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/arrow-down.svg) no-repeat center;
  &__arrow-up
    mask url(../img/app/rwd/icons/arrow-up.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/arrow-up.svg) no-repeat center;


  /* other new witt icons */
  &__attention
    mask url(../img/app/rwd/icons/attention.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/attention.svg) no-repeat center;
  &__burgermenu
    mask url(../img/app/rwd/icons/burgermenu.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/burgermenu.svg) no-repeat center;
  &__check
    mask url(../img/app/rwd/icons/check.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/check.svg) no-repeat center;
  &__no-check
    mask url(../img/app/rwd/icons/no-check.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/no-check.svg) no-repeat center;
  &__clock
    mask url(../img/app/rwd/icons/clock.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/clock.svg) no-repeat center;
  &__copy
    mask url(../img/app/rwd/icons/copy.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/copy.svg) no-repeat center;
  &__filter
    mask url(../img/app/rwd/icons/filter.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/filter.svg) no-repeat center;
  &__filtersort
    mask url(../img/app/rwd/icons/filtersort.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/filtersort.svg) no-repeat center;
  &__heart-empty
    mask url(../img/app/rwd/icons/heart-empty.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/heart-empty.svg) no-repeat center;
  &__letter
    mask url(../img/app/rwd/icons/letter.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/letter.svg) no-repeat center;
  &__logout
    mask url(../img/app/rwd/icons/logout.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/logout.svg) no-repeat center;
  &__pencil
    mask url(../img/app/rwd/icons/pencil.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/pencil.svg) no-repeat center;
  &__search
    mask url(../img/app/rwd/icons/search.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/search.svg) no-repeat center;
  &__settings
    mask url(../img/app/rwd/icons/settings.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/settings.svg) no-repeat center;
  &__share
    mask url(../img/app/rwd/icons/share.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/share.svg) no-repeat center;
  &__voucher
    mask url(../img/app/rwd/icons/voucher.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/voucher.svg) no-repeat center;

  &__add-icon
    mask url(../img/app/rwd/icons/add-icon.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/add-icon.svg) no-repeat center;
  &__basket, &__header-basket
    mask url(../img/app/rwd/icons/basket.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/basket.svg) no-repeat center;
  &__burger
    mask url(../img/app/rwd/icons/burger.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/burger.svg) no-repeat center;
  &__checkout-payment-lock
    mask url(../img/app/rwd/icons/checkout-payment-lock.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/checkout-payment-lock.svg) no-repeat center;
  &__close-x
    mask url(../img/app/rwd/icons/close-x.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/close-x.svg) no-repeat center;
  &__customer
    mask url(../img/app/rwd/icons/customer.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/customer.svg) no-repeat center;
  &__zoom
    mask url(../img/app/rwd/icons/zoom.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/zoom.svg) no-repeat center;
  &__green-check
    mask url(../img/app/rwd/icons/green-check.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/green-check.svg) no-repeat center;
  &__home
    mask url(../img/app/rwd/icons/home.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/home.svg) no-repeat center;
  &__icon-grid-3x3
    mask url(../img/app/rwd/icons/icon-grid-3x3.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/icon-grid-3x3.svg) no-repeat center;
  &__icon-grid-big
    mask url(../img/app/rwd/icons/icon-grid-big.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/icon-grid-big.svg) no-repeat center;
  &__icon-grid-big-mobile
    mask url(../img/app/rwd/icons/icon-grid-big-mobile.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/icon-grid-big-mobile.svg) no-repeat center;
  &__icon-grid-small
    mask url(../img/app/rwd/icons/icon-grid-small.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/icon-grid-small.svg) no-repeat center;
  &__icon-grid-small-mobile
    mask url(../img/app/rwd/icons/icon-grid-small-mobile.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/icon-grid-small-mobile.svg) no-repeat center;
  &__info
    mask url(../img/app/rwd/icons/info.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/info.svg) no-repeat center;
  &__truck
    mask url(../img/app/rwd/icons/truck.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/truck.svg) no-repeat center;
  &__pencil
    mask url(../img/app/rwd/icons/pencil-icon.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/pencil-icon.svg) no-repeat center;
  &__trash
    mask url(../img/app/rwd/icons/trash-icon.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/trash-icon.svg) no-repeat center;
  &__catalog
    mask url(../img/app/rwd/icons/catalog-icon.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/catalog-icon.svg) no-repeat center;
  &__printer
    mask url(../img/app/rwd/icons/printer-icon.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/printer-icon.svg) no-repeat center;
  &__search
    mask url(../img/app/rwd/icons/search.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/search.svg) no-repeat center;

  &__faq
    mask url(../img/app/rwd/icons/faq.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/faq.svg) no-repeat center;
  &__delivery-service
    mask url(../img/app/rwd/icons/delivery-service.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/delivery-service.svg) no-repeat center;
  &__return_shipment
    mask url(../img/app/rwd/icons/return_shipment.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/return_shipment.svg) no-repeat center;

  &__breadcrumb-arrow
    mask url(../img/app/rwd/icons/breadcrumb-arrow.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icons/breadcrumb-arrow.svg) no-repeat center;

  &__colissimo
    inline-block()
    vertical-align middle
    margin-left 8px
    margin-top -3px
    width 16px
    height 16px
    background-image url(../img/app/rwd/icon-shipping-colissimo.svg)
    background-size contain
    background-repeat no-repeat

  &__mondial-relay
    inline-block()
    vertical-align middle
    margin-left 8px
    margin-top -3px
    width 16px
    height 16px
    background-image url(../img/app/rwd/icon-shipping-relay.svg)
    background-size contain
    background-repeat no-repeat

  &__la-poste
    inline-block()
    vertical-align middle
    margin-left 8px
    width 16px
    height 16px
    background-image url(../img/app/rwd/icon-shipping-domicile.svg)
    background-size contain
    background-repeat no-repeat

  &__wishlist
    background-image url(../img/app/rwd/icons/wishlist.svg)
    background-color unset !important
    &:hover
      background-image: url(../img/app/rwd/icon-add-wishlist-active.svg)

  &__wishlist-full
    background-image url(../img/app/rwd/icons/wishlist-full.svg)
    background-color unset !important

  /* multicolor or png old icons */
  &__icon_compte
    background-image url(../img/app/rwd/icon_compte.png)
    background-color unset !important
  &__icon_compte_moda
    background-image url(../img/app/rwd/icon_compte_moda.png)
    background-color unset !important
  &__basket-freeItem
    background-image url(../img/app/rwd/basket-freeItem.jpg)
    background-color unset !important
  &__advantage-happiness
    background-image url(../img/app/rwd/advantage-happiness.png)
    background-color unset !important
  &__icon-3xcb
    background-image url(../img/app/rwd/icon-3xcb.png)
    background-color unset !important
  &__envelope
    background-image url(../img/app/rwd/icon-envelope.png)
    background-color unset !important
  &__icon-logout
    background-image url(../img/app/rwd/icon-logout.png)
    background-color unset !important
  &__icon-paiement-co-badging
    background-image url(../img/app/rwd/icon-paiement-co-badging.png)
    background-color unset !important
  &__icon-paiement-mastercard
    background-image url(../img/app/rwd/icon-paiement-mastercard.png)
    background-color unset !important
  &__icon-paiement-visa
    background-image url(../img/app/rwd/icon-paiement-visa.png)
    background-color unset !important
  &__icon-phone
    background-image url(../img/app/rwd/icon-phone.png)
    background-color unset !important
  &__icon-shipping-colissimo
    background-image url(../img/app/rwd/icon-shipping-colissimo.png)
    background-color unset !important
  &__icon-shipping-domicile
    background-image url(../img/app/rwd/icon-shipping-domicile.png)
    background-color unset !important
  &__icon-shipping-express
    background-image url(../img/app/rwd/icon-shipping-express.png)
    background-color unset !important
  &__icon-shipping-express-mv
    background-image url(../img/app/rwd/icon-shipping-express-mv.png)
    background-color unset !important
  &__icon-shipping-relay
    background-image url(../img/app/rwd/icon-shipping-relay.png)
    background-color unset !important
  &__icon-panier_moda
    background-image url(../img/app/rwd/icon_panier_moda.png)
    background-color unset !important
  &__icon_search
    background-image url(../img/app/rwd/icon_search.png)
    background-color unset !important
  &__line-item-delete
    background-image url(../img/app/rwd/line-item-delete.png)
    background-color unset !important
  &__line-item-edit
    background-image url(../img/app/rwd/line-item-edit.png)
    background-color unset !important
  &__line-item-edit-done
    background-image url(../img/app/rwd/line-item-edit-done.png)
    background-color unset !important
  &__logo
    background-image url(../img/app/rwd/logo.png)
    background-color unset !important
  &__logo-mv
    background-image url(../img/app/rwd/logo-mv.png)
    background-color unset !important
  &__logo-purewear
    background-image url(../img/app/rwd/logo-purewear.png)
    background-color unset !important
  &__more-colors-arrow
    background-image url(../img/app/rwd/more-colors-arrow.png)
    background-color unset !important
  &__navigation-close
    background-image url(../img/app/rwd/navigation-close.png)
    background-color unset !important
  &__nopic
    background-image url(../img/app/rwd/nopic.jpg)
    background-color unset !important
  &__payment-cb
    background-image url(../img/app/rwd/payment-cb.png)
    background-color unset !important
  &__payment-cofidis-dfpay
    background-image url(../img/app/rwd/payment-cofidis-dfpay.png)
    background-color unset !important
  &__print
    background-image url(../img/app/rwd/print.png)
    background-color unset !important
  &__sans-supplement
    background-image url(../img/app/rwd/sans-supplement.png)
    background-color unset !important
  &__slider-next
    background-image url(../img/app/rwd/slider-next.png)
    background-color unset !important
  &__slider-prev
    background-image url(../img/app/rwd/slider-prev.png)
    background-color unset !important
  &__sprite
    background-image url(../img/app/rwd/sprite.png)
    background-color unset !important
  &__trusted_shop
    background-image url(../img/app/rwd/trusted_shop.png)
    background-color unset !important
  &__checkout-advantage-payment
    background-image url(../img/app/rwd/checkout-advantage-payment.svg)
    background-color unset !important
  &__checkout-advantage-period
    background-image url(../img/app/rwd/checkout-advantage-period.svg)
    background-color unset !important
  &__checkout-advantage-returns
    background-image url(../img/app/rwd/checkout-advantage-returns.svg)
    background-color unset !important
  &__icon-add-wishlist
    background-image url(../img/app/rwd/icon-add-wishlist.svg)
    background-color unset !important
  &__icon-add-wishlist-active
    background-image url(../img/app/rwd/icons/wishlist-full.svg)
    background-color unset !important

  &__payment-3xcb
    background-image url(../img/app/rwd/icons/payment-icons/payment-3xcb.svg)
    background-color transparent
  &__payment-4_fr
    background-image url(../img/app/rwd/icons/payment-icons/payment-4_fr.svg)
    background-color transparent
  &__payment-4_helline
    background-image url(../img/app/rwd/icons/payment-icons/payment-4_helline.svg)
    background-color transparent
  &__payment-4etoiles-old
    background-image url(../img/app/rwd/icons/payment-icons/payment-4etoiles-old.svg)
    background-color transparent
  &__payment-bleu-cb
    background-image url(../img/app/rwd/icons/payment-icons/payment-bleu-cb.svg)
    background-color transparent
  &__payment-cvv
    background-image url(../img/app/rwd/icons/payment-icons/payment-cvv.svg)
    background-color transparent
  &__payment-maestro
    background-image url(../img/app/rwd/icons/payment-icons/payment-maestro.svg)
    background-color transparent
  &__payment-master
    background-image url(../img/app/rwd/icons/payment-icons/payment-master.svg)
    background-color transparent
  &__payment-paypal
    background-image url(../img/app/rwd/icons/payment-icons/payment-paypal.svg)
    background-color transparent
  &__payment-visa
    background-image url(../img/app/rwd/icons/payment-icons/payment-visa.svg)
    background-color transparent
  &__slider-arrow-left
    mask url(../img/app/rwd/helline/slider-arrow-left.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/slider-arrow-left.svg) no-repeat center;
  &__slider-arrow-right
    mask url(../img/app/rwd/helline/slider-arrow-right.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/slider-arrow-right.svg) no-repeat center;

  /* helline Premium */
  &__premium-monitorLogin
    mask url(../img/app/rwd/helline/premium/monitorLogin.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/premium/monitorLogin.svg) no-repeat center;
    width 62px
    height 62px
  &__premium-monitorShoppingBag
    mask url(../img/app/rwd/helline/premium/monitor-shopping-bag.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/premium/monitor-shopping-bag.svg) no-repeat center;
    width 62px
    height 62px
  &__premium-crown
    mask url(../img/app/rwd/helline/premium/premium-crown.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/premium/premium-crown.svg) no-repeat center;
  &__premium-crown-big
    background-image url(../img/app/rwd/helline/premium/premium-crown-big.svg)
    background-color transparent
    height 100px
    width 200px
    background-size contain
    background-repeat no-repeat
  &__premium-monitorH
    background-image url(../img/app/rwd/helline/premium/monitorH.svg)
    background-color unset !important
    width 62px
    height 62px
  &__premium-monitorCelebration
    background-image url(../img/app/rwd/helline/premium/monitorCelebration.svg)
    background-color unset !important
    width 150px
    height 87px
  &__premium-badge
    background-image url(../img/app/rwd/helline/premium/premium-badge.svg)
    background-color unset !important
  &__premium-h
    background-image url(../img/app/rwd/helline/premium/premium-h.svg)
    background-color unset !important

  &__premium-logo
    background-image url(../img/app/rwd/helline/premium/premium-logo.svg)
    background-color transparent
    height 24px
    width 188px
    background-size contain
    background-repeat no-repeat
    display inline-block
    vertical-align middle
  &__premium-logo-stacked
    background-image url(../img/app/rwd/helline/premium/premium-logo-stacked.svg)
    background-color transparent
    height 25px
    width 62px
    background-size contain
    background-repeat no-repeat
    display inline-block
    vertical-align middle
  &__premium-flag
    background-image url(../img/app/rwd/helline/premium/premium-flag.svg)
    background-color transparent !important
    height 24px
    width 82px
    background-size contain
    display inline-block
    vertical-align middle

  /* old icons */
  &__icon-logout-svg
    mask url(../img/app/rwd/icon-logout-gray.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icon-logout-gray.svg) no-repeat center;
  &__sort
    mask url(../img/app/rwd/sort.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/sort.svg) no-repeat center;

  &--block
    display block
    position relative

  &--inline-block
    display inline-block
    position relative
    vertical-align top

  &--center
    margin auto


  &__helline-basket //delete __basket after splitting from witt
    mask url(../img/app/rwd/helline/basket.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/basket.svg) no-repeat center;
  &__helline-account //remove after splitting witt
    mask url(../img/app/rwd/helline/account.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/account.svg) no-repeat center;
  &__helline-icon-wishlist //delete after splitting witt and helline
    mask url(../img/app/rwd/helline/icon-wishlist.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/icon-wishlist.svg) no-repeat center;
  &__helline-order_commande2 //delete after splitting witt and helline
    mask url(../img/app/rwd/helline/order_commande2.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/helline/order_commande2.svg) no-repeat center;
  &__helline-arrow-right, &__helline-arrow-left, &__helline-arrow //delete after splitting witt and helline
    mask url(../img/app/rwd/helline/arrow-right.svg) no-repeat center
    -webkit-mask url(../img/app/rwd/helline/arrow-right.svg) no-repeat center;
    display none


i.addtocart
  &--icon
    width 35px
    height 35px
    background-image url(../img/app/rwd/helline/add-to-basket.svg)
    display inline-block
    background-repeat no-repeat
    background-position center center

  &--small
    sprite($icon_addtocart_small)
    inline-block()
    vertical-align middle
    margin-right 10px

.add-to-basket-button__icon
  margin-right 10px