@require 'includes/*'

.homepage
  position relative
  
  .teaser
    img
      width 100%
      height auto
      
  .main-teaser
    margin-bottom 15px

    .slick-list
      @media screen and (min-width: $medium)
        margin-bottom -25px

  .slider
    width 100%
    
  .slick-next
    right 0
  
  .slick-prev
    left 0


  .slick-dots
    text-align center
    padding 0 0 5px 0
    
    @media screen and (max-width: $medium)
      display none !important
    
    li
      display inline-block
      background-color $witt-neutral-border
      margin-left 10px
      padding 6px
      opacity 0.8
      border-radius 50%
      
      &.slick-active
        background-color $witt-black
  
  .recommendations
    width calc(100% - 45px)
    max-width 2000px
    margin 0 auto
    transition opacity 0.2s

    .product__additional-box
      border none
      border-top  1px solid $witt-default-border
      border-bottom  1px solid $witt-default-border
    
    .product__additional-box__header
      font-size 28px
      text-align center
      margin-bottom 30px
      margin-top 30px
      font-weight 700
      background-color transparent
      border-bottom none
      padding 0
      color $witt-neutral-border
    
    .product__carousel__name
      color $witt-black-text
      line-height 20px
    .product__carousel__price
      color $witt-black-text

  #info
    color $witt-black-text
    padding 6px
    bottom 0
    h1
      color $witt-black-text
      padding 0 0 0 0
      font-weight bold
      font-size 14px
      line-height 18px
    h2
      margin-top 10px
      margin-bottom 0
      padding 0
      color $witt-black-text
      font-weight bold
      font-size 12px
      line-height 16px
    p
      color $witt-neutral-border
      a
        color $witt-black-text