@require 'includes/*'

.modal-right-panel
  .modal-body
    overflow-y auto
    padding 40px
    padding-top 16px

    @media up_to_M
      padding 16px 0

    table
      width fit-content

    > :first-child
      @media up_to_M
        overflow-x hidden
        padding-left 16px
        padding-right 16px

  .modalOverlayRightPanelInfo
    @media up_to_M
      overflow-x hidden
      padding-left 16px
      padding-right 16px

  .modal-header
    16px 40px

.modal-title
  i.icon
    display none

.modal-background
  background-color rgba(0, 0, 0, 0.5)
  z-index 0
  position absolute
  width 100%
  height 100%

.modal-js-hide-background
  display none

#modalOverlay
  .modal-header
    border none !important

  .modal
    .select-dropdown
      max-height 200px !important

.rightpanel-link
  reset-button-style()

#modalOverlayRight
  display none !important

#modalOverlayRight.modal-right
  display block !important

  .modal
    &-title
      &:not(.modal-title--full)
        overflow hidden
        text-overflow ellipsis
        white-space initial
        display -webkit-box
        -webkit-line-clamp 2
        -webkit-box-orient vertical

    &-dialog
      margin 0
      position absolute
      z-index 5

      @media up_to_M
        width 100%
        bottom 0
        max-width unset !important

      @media M_and_up
        height 100%
        right 0
        bottom 0
        top 0
        transform translate(0,0)

    &-header
      @media M_and_up
        padding-left 40px
        padding-right 40px

    .modal-header
      border none !important

    .select-dropdown
      max-height 200px !important

    &-content
      height 100%
      border-top-right-radius 0
      border-bottom-right-radius 0
      @media up_to_M
        height 90vh
        border none

  .modal-header-shadow
    .modal-header
      box-shadow -4px 0px 8px 0px rgba(18, 16, 55, 0.16)

  .modal-size-XL
    animation fromRight-XL 0.5s ease-out
    right 0
    bottom 0
    max-width 75%
    min-width 964px
    @media M_to_L
      min-width 740px
    @media M_and_up
      width 75%
    @media up_to_M
      min-width unset

  .modal-size-L
    animation fromRight-L 0.5s ease-out
    right 0
    max-width 50%
    @media M_and_up
      min-width 740px
      width 50%

  .modal-size-M
    animation fromRight-M 0.5s ease-out
    right 0
    max-width 25%
    @media M_and_up
      min-width 550px
      width 25%

  //show modal
  @media L_and_up
    @keyframes fromRight-XL
      0%
        opacity 1
        right -964px
        bottom 0
      70%
        right  0
        bottom 0

  @media M_and_up
    @keyframes fromRight-L
      0%
        opacity 1
        right -740px
        bottom 0
      70%
        right  0
        bottom 0

    @keyframes fromRight-M
      0%
        opacity 1
        right -550px
        bottom 0
      70%
        right  0
        bottom 0

  @media up_to_M
    @keyframes fromRight-L
      0%
        right  0
        bottom -740px
      70%
        right  0
        bottom  0
    @keyframes fromRight-M
      0%
        right  0
        bottom -550px
      70%
        right  0
        bottom  0

  .modal
    &-hide-dialog
      animation moveOut 0.5s ease-out
      right -740px

      @media up_to_M
        right  0
        bottom 100vh

  //   Remove modal
  @media M_and_up
    @keyframes moveOut
      0%
        right 0
        opacity 1 !important

      50%
        opacity 1 !important

      100%
        right -740px
        opacity 0

  @media up_to_M
    @keyframes moveOut
      0%
          right 0
          top 0
      50%
        right 0
        top 100vh
      100%
        right  0
        display none

.modal.fade .modal-dialog.modal-hide-dialog
  transform unset !important