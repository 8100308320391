@require 'includes/*'

.registration
  padding-top 30px
  padding-bottom 45px

  @media screen and (max-width: $medium)
    padding-top 0
    padding-bottom 20px

  h1
    margin-top 20px
    margin-bottom 20px
    font-size 34px
    text-align center

    &__text
      margin-left 7px
      width 100%
      font-size 13px

    &__info
      display: block
      margin-left: auto
      margin-right: 0

  &__stay-connected-text-container
    margin-top 8px
    background-color #f4f2f3
    padding 10px
    font-size 13px

    &.inactive
      display none

  &__info
    text-align center
    font-size 14px

  &__box
    padding 30px
    background-color $witt-white
    margin 30px 0 40px 0
    box-shadow 0 10px 25px rgba(0, 0, 0, 0.2)

    @media screen and (max-width: $medium)
      padding 10px

  &__headline
    font-size 20px
    line-height 20px
    font-weight bold
    margin-bottom 40px

    @media screen and (max-width: $medium)
      margin-bottom 30px

  &__subheadline
    font-weight 700
    font-size 17px
    margin-top 20px
    margin-bottom 24px

  &__hide-line
    display none

  &__inner
    padding 0 50px

    @media screen and (max-width: $large)
      padding 0

    &--right
      text-align right

      .btn
        margin-left 20px

        @media screen and (max-width: $medium)
          margin-left 0

    .btn
      @media screen and (max-width: $medium)
        width 100%
        margin-bottom 11px

  &__password-strength
    margin -15px 0 20px 0
    padding 10px
    background-color $color-info-background-neutral

    &__headline
      font-weight 700

    &.allPositive
      background-color $color-info-background-success !important

    &.notAllPositive
      background-color $color-info-background-warning !important

    ul
      margin 0 0 0 0
      padding 0

      li
        color $witt-black
        list-style initial
        display flex
        align-items center
        font-size 14px
        margin-bottom 4px

        &:last-child
          margin-bottom 0

        &:before
          content ''
          display inline-block
          width 16px
          height 12px
          background-position center
          background-repeat no-repeat
          margin-top 2px
          margin-right 7px
          vertical-align top
          background-image url(../img/app/rwd/password-neutral-dot.svg)

        &.positive
          color $witt-success
          list-style none

          &:before
            background-image url(../img/app/rwd/icons/check-circle-green.svg)
            height 16px
            min-width 16px

        &.negative
          color $witt-error
          list-style none

          &:before
            background-image url(../img/app/rwd/helline/check-no-circle.svg)
            height 16px
            min-width 16px

    &__result
      margin-top 16px
      font-size 14px
      span.password-fail
        color $witt-error
        font-weight bold
        margin-left 5px

      span.password-success
        color $witt-success
        font-weight bold
        margin-left 5px
        display none

  .radio-container
    margin 10px 0

    p
      font-size 14px

  .btn
    padding-left 30px
    padding-right 30px

  .info
    font-size 12px
    line-height 16px
    margin-top 50px

    @media screen and (max-width: $medium)
      margin-top 20px

  &.modify
    label

      .modal
        label
          color rgba(0, 0, 0, 0.6) !important

  .material
    .error
      width 100%
      margin-bottom 10px
      position relative
  .message
    .error
      font-size 12px
      margin-bottom 10px
    a
      text-decoration underline
      color red

    &--margin
      margin-bottom 25px

  .btn__continue
    .icon
      margin-right 20px
      margin-top 8px

  &__subscribe
    padding 10px
    background-color $witt-secondary
    margin-bottom 32px

    .material
      margin-bottom 0

      label
        color $witt-neutral-border
      
      .checkbox  
        input[type=checkbox]
          margin-right 10px

  .buttons
    .btn
      margin-bottom 10px

  .registration
    &--button
      margin-top 20px

#modalOverlayAddessValidation
  .modal-dialog
    width 650px
    max-width calc(100% - 15px)