@require 'includes/*'

.bread-crumb
  color $witt-neutral-border
  margin-top 16px
  margin-bottom 24px

  a
    font-size 14px
    line-height 16px
    color $witt-neutral-border

    &.active
      color $witt-neutral-border

  ul, ol
    display flex
    align-items center
    list-style-type none
    padding 0
    margin 0
    li
      font-size 14px
      line-height 16px
      display flex
      align-items center
      color $witt-neutral-border
      margin-right 5px
      &:last-child
        color $witt-black-text

  &__arrow
    margin-right 5px