@require 'includes/*'

//account__addresses is also used in shipping, but looks slightly different.
.account
  &__addresses
    &__headline
      margin-bottom 10px

      h3
        font-weight bold

    &__edit-button
      margin-bottom 30px

    &__new-form
      padding 30px
      display none
      margin-bottom 30px

    &__new-form-visible
      padding 30px
      margin-bottom 30px

    &__box
      padding 20px 20px 80px 20px
      margin 0 0 30px 0
      min-height 210px
      position relative

      &--active
        width 100% !important
        max-width 100% !important
        flex-basis 100% !important

      &__selection
        position absolute
        top 10px
        right 10px

        input
          &[type=radio]
            opacity 0
            width 0
            height 0
            margin 0
            padding 0

            & + label
              width 18px
              height 18px
              border 1px solid $witt-neutral-border
              display block
              margin-top -15px
              cursor pointer

            &:checked
              & + label
                background-color $witt-primary


    &__box-button
      position absolute
      bottom 20px
      left 20px
      width calc(100% - 40px)

    &__edit-form
      display none
      margin-bottom -60px

    &__error
      margin-bottom 20px

      .error
        a
          color inherit
          text-decoration underline