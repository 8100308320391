@require 'includes/*'

.mini-basket-layer
  &-container
    display none
    position absolute
    top 42px
    right 0
    padding-top 20px
    &--visible
      display block

    @media screen and (max-width: $medium)
      display none


  position relative
  z-index 100
  width 250px
  background-color $witt-white
  box-shadow 0 0 20px rgba(0, 0, 0, 0.2)
  padding-bottom 1px

  &:before
  &:after
    content ''
    width 0
    height 0
    position absolute
    right 15px
    border-left 12px solid transparent
    border-right 12px solid transparent
    top -10px

  &:after
    border-bottom 10px solid $witt-white

  &__articles
    max-height 340px
    overflow-y auto

  &__article
    margin 20px
    height 128px
    border-bottom 1px solid $witt-secondary-text

  &__image, &__details
    display inline-block
    vertical-align top

  &__image
    width 65px
    margin-right 10px

  &__details
    width calc(100% - 75px)

  img
    border 1px solid $witt-secondary-text
    width 100%

  &__content-small
    font-weight bold
    padding-top 5px

  &__price
    padding 10px 20px 10px 20px
    background-color $witt-secondary
    &--total
      color $moda-red

  &__old-price
    margin-right 5px

  &__totaltext
    padding-right 10px

  &__btn
    margin 10px 20px 20px 20px

  .ecotax
    font-weight 400