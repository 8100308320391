@require '../includes/*'

.filter--color
  .color-tile
    display inline-block
    width 36px
    height 36px
    border 1px solid transparent
    border-radius 50%
    margin 4px 4px 4px 0
    padding 5px
    transition all .1s ease-in-out

    &.selected
      border 1px solid $witt-black
      color $witt-black-text

      .color-tile__item
        transform scale(1.167)

    &__name
      font-size 14px
      line-height 15px
      color $witt-black-text

    &__container
      display flex
      align-items center
      padding-right 25px
      cursor pointer

      &:hover
        @media screen and (min-width: $medium)
          .color-tile
            border 1px solid $witt-black

            &__item
              transform scale(1.167)

      &.disabled
        display none

    &__item
      display inline-block
      border 1px solid $witt-secondary-text
      border-radius 50%
      width 24px
      height 24px
      cursor pointer
      transition all .1s ease-in-out

      &.disabled
        cursor default