@require 'includes/*'

#helline
  .add-to-basket-button
    font-size 16px
    line-height 32px
    font-weight bold
    padding 8px 12px
    border-radius 0px
    color $helline-white
    background-color $helline-dark-blue
    border 1px solid $helline-dark-blue
    cursor pointer
    width 100%
    display flex
    align-items center
    justify-content center

    &:hover, &:focus, &:active
      color $helline-dark-blue !important
      background-color $helline-beige !important
      border-color $helline-beige !important

      .add-to-basket-button__icon
        background-color $helline-dark-blue !important

    &:focus, &:active
      border-color $helline-dark-blue

    &__icon
      margin-right 10px