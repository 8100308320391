@require 'includes/*'
@require 'products-column-switch.styl'

.wishlist
  .bread-crumb
    margin-bottom 0

  .article
    width 100%
    vertical-align top
    margin-bottom 20px
    padding-right 0
    position relative
    z-index 0

    &:hover
      .article__container
        border 1px solid $witt-secondary-hover

    &__grid
      &__column
        flex 0 0 25%
        max-width 25%
        -webkit-box-flex 0
        position relative
        width 100%
        min-height 1px

        @media up_to_L
          flex 0 0 50%
          max-width 50%

        @media up_to_M
          flex 0 0 100%
          max-width 100%

      &__subnavigation
        flex 0 0 25%
        max-width 25%
        -webkit-box-flex 0
        position relative
        width 100%
        min-height 1px

        @media up_to_L
          display none

      &--double
        .article__grid__column
          flex 0 0 16.666666%
          max-width 16.666666%

          @media up_to_L
            flex 0 0 33.333333%
            max-width 33.333333%

          @media up_to_M
            flex 0 0 50%
            max-width 50%

        .article__grid__subnavigation
          flex 0 0 33.333333%
          max-width 33.333333%

        .article__picture
          @media up_to_M
            width 100% !important
            height 100% !important

            @supports not (aspect-ratio: 300/399)
              width 100% !important
              height 100% !important
              position relative
              padding-bottom 133.33%
              padding-top 0
              height 0
              overflow hidden

          img
            @media up_to_M
              @supports not (aspect-ratio: 300/399)
                position absolute
                top 50%
                left 50%
                transform translateX(-50%) translateY(-50%)

        .article__picture-slider
          @media up_to_M
            @supports not (aspect-ratio: 300/399)
              position unset

        .article__info
          @media up_to_M
            width 100%

        .article__name
          @media M_and_up
            width calc(100% - 45px)

        .article__wishlist
          right 5px
          top 5px
          width 30px
          height 30px

        .article__stickers
          left 8px

        .article__colors-container
          order 4
          position relative
          bottom unset
          @media up_to_M
            margin-left 2px
          @media L_and_up
            width calc(100% - 45px)

        .article__color
          &:nth-child(n+4)
            display none

        .article__colors__count
          .three-color-display
            display inline

          .six-color-display
            display none

        .article__button
          top auto
          bottom 0

          @media up_to_M
            top auto
            bottom 0

    &__container
      border 1px solid transparent
      transition all 0.2s

    &__picture
      position relative
      cursor pointer
      aspect-ratio 300/399
      height 100%
      width 100%
      overflow hidden
      display flex
      align-items center
      justify-content center

      @media up_to_M
        aspect-ratio unset

      img
        display inline-block
        max-width 100%
        max-height 100%
        position relative

      &:hover
        .article__picture-slider__next, .article__picture-slider__prev
          display: flex;
          justify-content center
          align-items center
          transition: all .2s;

    &__picture-slider
      position relative
      width 100%
      height 100%
      display flex
      align-items center
      justify-content center

      &:hover
        .article__picture-slider__prev, .article__picture-slider__next
          display flex


      &__prev
        width 40px
        height 40px
        border none
        position absolute
        left 0
        padding 0
        z-index 2
        background-color rgba(255, 255, 255, 0.7)
        cursor pointer
        transition: all .2s;
        display none

        @media up_to_M
          display none

      &__next
        width 40px
        height 40px
        border none
        position absolute
        right 0
        padding 0
        z-index 2
        background-color rgba(255, 255, 255, 0.7)
        cursor pointer
        transition: all .2s;
        display none

        @media up_to_M
          display none

    &__stickers
      display flex
      position absolute
      top 8px
      left 8px

    &__delete
      position absolute
      top 8px
      right 8px
      width 35px
      height 35px
      border 1px solid $witt-secondary
      background-color rgba(255,255,255, 0.9)
      border-radius 50%
      cursor pointer
      display flex
      align-items center
      justify-content center

    &__info
      padding 8px
      display flex
      justify-content space-between
      flex-wrap wrap
      position relative
      font-size 14px
      line-height 19px
      color $witt-black-text

      @media up_to_M
        flex-direction column
        justify-content flex-start
        width 100%

      &__right
        width 36px
        flex-shrink 0
        margin-left 4px
        position absolute
        @media up_to_M
          right -1px
          bottom 0
        @media M_and_up
          top 8px
          right 8px

        &__add-to-basket
          width 34px
          height 34px
          border-radius 50%
          background-color $witt-black
          border 2px solid $witt-black
          background-image url(../img/app/rwd/icons/basket.svg)
          background-position center center
          background-repeat no-repeat
          cursor pointer

          &:hover
            background-color $witt-tertiary
            background-image url(../img/app/rwd/icons/basket.svg)
            border-color $witt-tertiary

          &:focus
            background-color $witt-tertiary
            background-image url(../img/app/rwd/icons/basket.svg)
            border-color $witt-black

      &__left
        text-align left
        width 100%
        @media up_to_M
          display flex
          flex-direction column

    &__colors-container
      display flex
      margin 4px 0
      width calc(100% - 45px)

      @media up_to_M
        order 4
        margin-top 0

    &__colors
      display flex

      &__count
        vertical-align top
        margin-top -1px
        display inline-block
        font-weight 700

        .three-color-display
          display none

          @media up_to_M
            display inline

        .six-color-display
          @media up_to_M
            display none

        a
          color $witt-black-text
          cursor default
          text-decoration none

    &__color
      display inline-block
      vertical-align top
      margin-right 8px
      &:focus
        outline none

      @media up_to_M
        &:nth-child(n+4)
          display none

      &.active
        span
          &:after
            display block
            content ''
            border 1px solid $witt-black
            border-radius 50%
            width 16px
            height 16px
            margin-left -3px
            margin-top -3px

      span
        display inline-block
        width 12px
        height 12px
        border-radius 50%
        border 1px solid $witt-secondary-text

    &__name
      width 100%
      font-weight 700
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
      margin-bottom 4px
      @media M_and_up
        width calc(100% - 45px)
      @media up_to_M
        margin-bottom 0

    &__link
      width 100%
      text-overflow ellipsis
      white-space nowrap
      overflow hidden

    &__brand
      height 19px
      width 100%
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
      margin-bottom 4px

    &__action
      margin-top 10px

    &__prices
      display flex
      align-items center
      justify-content flex-start
      flex-wrap wrap
      width 100%
      margin-bottom 4px
      @media up_to_M
        margin-bottom 0

      .witt-price__teaser
        margin-right 0
        strike
          font-size 14px

      .witt-price__value
        &--discounted
          font-size 14px
          margin-left 5px
          sup
            font-size 14px

    &__oldprice
      text-decoration line-through
      margin-right 10px
      line-height 1em

    &__price
      line-height 1em

      &__nowrap
        display inline-block
        white-space nowrap

      &__amount
        font-weight 700
        margin 0 2px 0 0
        display inline-block
        white-space nowrap

        &--with-reduction
          color $witt-brand-color
          margin-left 5px

          &--currency
            color $witt-brand-color

      &_message
        padding-right 5px

        &:empty
          padding-right 0

      &__currency
        font-weight 700

    &__button
      position absolute
      top 8px
      right 8px

      @media up_to_M
        top auto
        bottom 0

  &__headline
    text-align center
    display flex
    justify-content space-between
    align-items center
    margin 10px 0 16px 0
    @media up_to_M
      margin 8px 0


    h1
      margin-bottom 0
      font-size 35px
      line-height 38px
      font-weight 700
      color $witt-black-text
      display inline-block
      vertical-align baseline

      @media up_to_M
        font-size 24px
        line-height 38px


  &__column-switch
    display flex

    .products-column-switch
      flex-grow 1
      text-align right
      margin-right 0
      margin-left 0

  .article__grid
    @media L_and_up
      column-gap 30px
    @media up_to_L
      column-gap 15px

    &__column
      flex 0 0 25%
      max-width calc(25% - 23px)
      -webkit-box-flex 0
      position relative
      width 100%
      min-height 1px

      @media S_to_L
        flex 0 0 50%
        max-width calc(50% - 7.5px)

      @media up_to_S
        flex 0 0 100%
        max-width 100%

    &--double

      .article__grid__column
        flex 0 0 16.666666%
        max-width calc(16.666666% - 25px)

        @media S_to_L
          flex 0 0 33.333333%
          max-width calc(33.333333% - 10px)

        @media up_to_S
          flex 0 0 50%
          max-width calc(50% - 7.5px)

        .article__info
          @media L_and_up
            padding 8px 4px
          @media up_to_S
            padding 8px 4px

          &__right
            @media up_to_M
              right -1px
            @media L_and_up
              right 4px

        .wishlist-article
          padding-right 15px

          &__picture
            @media up_to_M
              width 100% !important
              height 100% !important
              position relative
              padding-bottom 133.33%
              padding-top 0
              height 0
              overflow hidden

              img
                @media up_to_M
                  @supports not (aspect-ratio: 300/399)
                    position absolute
                    top 50%
                    left 50%
                    transform translateX(-50%) translateY(-50%)

      .wishlist-article__stickers
        left 5px

  &__empty
    text-align center
    margin 30px 0 60px 0

    &__heart
      width 35px
      height 35px
      background-image url(../img/app/rwd/icon-wishlist.svg)
      background-repeat no-repeat
      background-position center center
      margin 0 auto 20px auto
      background-size 100% auto

    &__headline
      font-weight 700
      font-size 18px
      margin-bottom 20px

    &__cta
      background-color $witt-secondary
      padding 25px
      display flex
      flex-direction column
      align-items center

      @media up_to_M
        margin 0 30px

      &__text
        font-size 14px
        font-weight 700

      &__button
        margin-top 10px

  &__cta
    &__login
      background-color $witt-secondary
      padding 10px
      margin-bottom 16px
      display flex
      justify-content center
      align-items center

      @media up_to_M
        flex-wrap wrap
        text-align center
        margin-bottom 20px
        margin-left -15px
        margin-right -15px

      &__text
        font-size 14px

        @media up_to_M
          margin-right 0
          margin-bottom 10px

      &__link
        text-decoration underline
        text-transform lowercase
        font-size 14px
        margin-left 4px
        font-weight bold

  &__description
    color $witt-black-text

  .main-pictures
    &__zoom
      display none

  .product
    &__summary
      text-align left
      padding-left 0

    &__title
      font-size 16px
      line-height 20px
      text-align left

    &__summary__reference-reviews
      display none

    &__reference
      display none

    &__link-description
      display none

    &__selection__sizes-link
      display none

    &__reassurance
      display none

    &__wishlist-add
      display none !important

  .witt-price
    margin-top 10px
    margin-bottom 10px

    &__top
      justify-content flex-start

    &__eco-tax
      text-align left

  .addToBasketLayer
    .btn-primary
      padding 8px 12px
      font-size 14px
      text-align center

      i
        display none


.wishlist-article
  margin-bottom 50px
  font-size 16px
  padding-right 15px
  width 100%
  vertical-align top

  @media L_and_up
    padding-right 30px

  &__picture
    height 414px
    margin-bottom 15px
    position relative

    @media up_to_M
      height 218px

    &__link
      display inline-block
      width 100%
      height 100%

      > img
        display inline-block
        max-width 100%
        max-height 100%
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

  &__delete
    position absolute
    top 5px
    right 10px
    width 35px
    height 35px
    border 1px solid $witt-secondary
    background-color rgba(255,255,255, 0.9)
    border-radius 50%
    background-image url(../img/app/rwd/wishlist-delete.svg)
    background-repeat no-repeat
    background-position center center
    background-size 55% auto
    cursor pointer

  &__title
    text-align center

  &__brand
    text-align center
    font-size 14px

  &__price
    display flex
    align-items flex-end
    justify-content center
    width 100%
    font-size 14px
    margin-top 5px
    flex-wrap wrap

    .witt-price
      &__eco-tax
        margin-top 0
        margin-left 5px
        font-size 12px
        line-height 15px
        color $witt-secondary-text

  &__color
    text-align center
    font-size 14px
    margin-top 7px

  &__stickers
    display flex
    position absolute
    top 10px
    left 10px

  &__action
    margin-top 10px
    text-align center

    .btn
      margin-bottom 15px
      text-transform uppercase

  .witt-price
    &__teaser
      font-size 14px
      margin-right 0

    &__value
      font-size 16px

      sup
        font-size 16px
