@require 'includes/*'

#sorting
  @media up_to_M
    flex-basis 66%

  &.selected
    .dropdown
      &__icon
        display none
      &__title-opened
        &:after
          content ''
          display inline-block
          width 18px
          height 18px
          vertical-align top
          background-image url(../img/app/rwd/check.svg)
          background-position center center
          background-size 70% auto
          background-color $witt-secondary
          background-repeat no-repeat
          border-radius 50%
          margin-left 4px

  @media M_and_up
    flex-basis calc(20% - 25px)

  @media M_to_L
    flex-basis calc(20% - 10px)

  .dropdown__content
    @media up_to_M
      position fixed
      top 0
      left 0
      width 100%
      height 100%
      z-index 1010
      padding-top 0

.sorting
  width 110px
  position relative
  border none

  &.dropdown
    &--opened
      box-shadow none

  .dropdown
    &__header
      border 1px solid $witt-default-border
      border-radius 8px
      padding 8px
      width 100%
      color $witt-black-text
      height 42px

    &__title
      text-transform none
      font-size 14px
      font-weight 700
      display none

      &-opened
        display block


    &__title-wrapper
      width auto

      &:before
        content ''
        height 19px
        width 27px
        margin-right 6px
        display inline-block
        vertical-align top
        background-image url(../img/app/rwd/sort.svg)
        background-position center center
        background-size 100% auto

    &__icon
      margin-left 10px
      transition all linear 0.2s

      &--opened
        transform rotate(180deg) scale(0.67)
        background-image url(../img/app/rwd/icons/arrow-down.svg)

      @media up_to_M
        display none

    &__content
      @media M_and_up
        border none
        margin 0 0 0 -80px
        width auto
        left unset
        padding 0px
        right 0px

  &__layer-header
    position relative
    height 50px
    color $witt-black-text
    font-size 16px
    line-height 38px
    border-bottom 1px solid $witt-default-border
    padding 13px 40px 13px 12px
    font-weight 700

    @media up_to_M
      height unset
      padding 13px 40px 13px 12px

    @media M_and_up
      display none

  &__close-icon
    position absolute
    top 24px
    right 18px
    cursor pointer
    content ''
    sprite($icon_close_small)

  &__list, &__item
    list-style-type none

  &__list
    padding 0
    margin 0
    @media up_to_M
      margin-top 10px

  &__item
    padding 3px 0 2px 15px
    color $witt-black-text
    font-size 14px
    @media M_and_up
      font-size 13px
      padding 10px 14px

    &:hover,
    &--active
      background-color $witt-secondary
      text-decoration none
    &--active
      font-weight bold

    @media up_to_M
      padding-top 10px
      padding-bottom 10px

    &--active
      @media up_to_M
        color $witt-white
        background-color $witt-black

