@require '../includes/*'
  
#helline
  .addToBasketLayer,.modifyBasketLayer,.gift-popup
    color $helline-dark-blue

    .witt-price
      margin 0 !important

    .product
      &__selection
        &__buttons
          &--modify
            .btn
              background-color $helline-dark-blue
              border-color $helline-dark-blue
              color $helline-white
              font-size 16px
              line-height 32px
              text-align center
              font-weight 400
              padding 12px 15px
              height unset

              &:hover
                background-color $helline-beige
                border-color $helline-beige
                color $witt-black-text

              &:focus
                background-color $helline-beige
                border-color $helline-dark-blue
                color $witt-black-text

              &:active
                background-color $helline-beige
                border-color $helline-dark-blue
                color $witt-black-text

  .main-picture
      border-radius 0px

    .product
      &__title
        font-size 16px
        line-height 19px
        @media screen and (min-width: $medium)
          font-size 20px
          line-height 24px

      &__link-description
        a
          &:hover, &:active
            color $helline-dark-blue

    .main-pictures
      .slick-prev
        background-image url(../img/app/rwd/helline/slider-arrow-left.svg) !important

      .slick-next
        background-image url(../img/app/rwd/helline/slider-arrow-right.svg) !important

    .color-selection

      &__list

        .owl-prev
          background-image url(../img/app/rwd/helline/slider-arrow-left.svg) !important

          &.disabled
            background-image url(../img/app/rwd/helline/slider-arrow-left.svg) !important

        .owl-next
          background-image url(../img/app/rwd/helline/slider-arrow-right.svg) !important

          &.disabled
            background-image url(../img/app/rwd/helline/slider-arrow-right.svg) !important
