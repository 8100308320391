@require 'includes/*'

.go-back-button
  &--back
    display flex
    min-width 40px
    height 40px
    background-color #f3f3f3
    transition all 0.1s
    align-items center
    border-radius 50%

  &--icon
    margin-left 4px

  &--text
    display none

  &--label
    @media M_and_up
      padding 10px
      font-size 14px
      font-weight bold
      border-radius 50% !important

    @media up_to_M
      display none

      &:hover
        background-color $witt-black
        background-image url(../img/app/rwd/icons/arrow-left-white.svg)
