@require 'includes/*'

.account
  &__orders
    border 1px solid $witt-default-border
    padding 26px 40px
    @media screen and (max-width: $medium)
      padding 26px 20px

    &__detail
      background-color $witt-lightest-grey
      .account__orders_list
        li:last-child
          .account__orders__table
            td
              border-bottom none

    &__heading
      margin-bottom 12px

    &_list
      list-style-type none
      margin 0
      padding 0
      width 99%

    &_buttons
      &-row
        display flex
        justify-content center
        border-bottom 1px solid $witt-secondary-text
        padding 32px 0

        &:last-child
          border none
          padding-bottom 0

        .btn
          width initial

        .btn-ghost
          padding 0 10px

        @media screen and (max-width: $medium)
          flex-direction column
          align-items center
          padding 16px 0

    @media screen and (max-width: $medium)
      width 100%

    &__article
      th
        display none!important

        @media screen and (max-width: $medium)
          display table-cell!important

      &--first
        th
          display table-cell!important
    &__package
      color $witt-white
      font-weight bold
      padding 5px
      background-color $witt-secondary-text
      min-height 30px
      width 100%

      .tracking-link
        color $witt-white

    li
      @media screen and (max-width: $medium)
        margin-bottom 20px

      &.account__orders__detail
        border 3px solid $witt-default-border

    &__table
      width 100%
      border-spacing 0
      border-collapse separate
      color $witt-neutral-border

      a
        color $witt-neutral-border

      th
        border-bottom 1px solid $witt-default-border
        padding 0 0 14px 0
        font-weight inherit

        @media screen and (max-width: $medium)
          border none
          padding 0

      td
        border-bottom 1px solid $witt-default-border
        padding 14px 0
        width 50%
        white-space nowrap
        overflow hidden
        text-overflow ellipsis

        @media screen and (max-width: $medium)
          border none
          padding 0 0 14px 0

          &.parcels
            @media screen and (min-width: $medium)
              width 30%
          &.detail
            @media screen and (min-width: $medium)
              width 70%

        &.price
          font-weight bold

      .article
        text-align left

        @media screen and (max-width: $medium)
          text-align center

        &__image
          img
            border 1px solid $witt-secondary-text
            margin-left 5px
            max-width 100%

            @media screen and (max-width: $medium)
              margin-top 10px

      &--detail
        th
          display table-cell

          &:nth-child(2)
            @media screen and (max-width: $medium)
              border-right none

        td
          height 100px

          @media screen and (max-width: $medium)
            height 0
            min-height 40px
            padding 5px 10px
            vertical-align middle

          &:nth-child(2)
            @media screen and (max-width: $medium)
              border-right none

  &__orders_parcel
    padding 16px
    box-shadow 2px 0 46px -23px rgba(0,0,0,0.75)
    margin-bottom 14px

  &__orders_parcel_detail
    border-bottom 1px solid $witt-default-border
    &_cell
      display inline-flex
      flex-direction column
      word-break break-word
      padding 0 10px 10px 0

      @media screen and (max-width: $medium)
        padding-right 0

      &:last-of-type
        padding-right 0

      .label
        color $witt-secondary-text

  &__orders_parcel_item
    border-bottom 1px solid $witt-default-border
    padding 12px 0 16px 0

    &:last-child
      border none
      padding 0

    .parcel_item_image
      margin 4px 12px 0 0
      height 90px
      overflow hidden
      border 1px solid $witt-default-border

    .parcel_item_detail
      flex 0 1 50%
      line-height 18px
    .parcel_item_detail_heading
      font-weight bold

    .parcel_item_detail_ref
      color $witt-secondary-text
      margin-left 4px

    .parcel_item_detail_attrs
      line-height 16px
      margin-top 6px
    .parcel_item_detail_label
      color $witt-secondary-text
      margin-right 10px

    .parcel_item_price
      flex 1 1 auto
      text-align right
    .parcel_item_price_total
      font-weight bold

      &--tax
        font-weight 700
        color $witt-black-text
        margin 0

  &__order_parcel_button
    display flex
    justify-content center
    margin-top 16px

  &_order__addresses
    margin-top 30px

  &__orders_address
    padding 12px 0
    margin 0 12px
    border-bottom 1px solid $witt-default-border
    //.address_cell

    .address_image
      min-width: 70px

    .address_type
      flex 0 1 50%
      color $witt-black-text

      dt
        font-weight normal

      p
        margin-bottom 12px

    .address_type_heading
      color $witt-black-text
      font-weight bold
      margin-bottom 4px

    .address_type_total_price
      font-weight bold
      color $witt-black-text

    .address_detail_heading
      color $witt-black-text
      margin-bottom 8px

  &__orders_address:first-child
    border-top 1px solid $witt-default-border

  &__orders_return_help
    margin-bottom 12px

    .help_return_box
      padding 16px
      margin 0
      background-color #e7e5d9
      display flex
      justify-content space-between

      .help_return_text
        display flex
        .help_return_icon
          width 20px
          height 20px
          margin-right 8px
      .help_return_link
        a, a:hover, a:focus, a:visited
          text-transform uppercase
          font-weight bold
          text-decoration underline


  &__orders_help
    margin-top 32px

    .help_box
      padding 16px 0
      margin 0
      background-color #e7e5d9

    .help_icon
      display flex
      padding 10px 0 10px 18px
      justify-content center
      svg
        width 40px
        height 46px
        fill $helline-dark-blue

        use
          transform scale(2)

    .help_text
      @media screen and (max-width: $medium)
        text-align center

      .help_phone
        display flex
        align-items center
        margin-top 10px

        @media screen and (max-width: $medium)
          justify-content center

        .phone_icon
          width 24px
          height 24px
          margin-right 4px
          background url("/img/app/rwd/icon-phone.png") center no-repeat
          background-size 100%
        .phone_label
          margin-right 10px
          .phone_label_label
            line-height 14px
        .phone_number
          font-weight bold

