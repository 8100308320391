@require 'includes/*'

::-webkit-scrollbar
  height: 2px
  -moz-appearance:none;

::-webkit-scrollbar-track
  background-color #f1f1f1
  border-radius 5px

::-webkit-scrollbar-thumb
  background-color #888
  border-radius 5px

.category__sub-navigation__pills,
.category__sub-navigation__bubbles

  .owl-nav
    position absolute
    top 0
    width 100%

  &__slider
    white-space nowrap


.category__sub-navigation
  &__pills
    margin-bottom  16px
    display block
    padding 0 30px

    @media up_to_M
      padding 0

    &.nopad-center .owl-stage-outer
      display flex
      justify-content center

      &owl-stage
        width auto

  &__pill
    display inline-block
    border-radius 100px
    padding 8px 16px
    background-color $witt-secondary
    color $witt-black-text !important

    &:hover,
    &.active
      text-decoration none
      background-color $witt-black
      color $witt-inverted-text !important

      i
        margin 0 -5px 0 5px

    &.active
      padding 5px 16px

  &__bubbles__container
    min-height 128px
    padding-bottom 16px
    z-index 2
    position relative

    .owl-stage-outer
      width 100%
      margin-left 30px

  &__slider
    @media up_to_L // second element for bubbles on small displays
      display flex
      width 100%
      height auto
      overflow-x scroll
      padding-bottom 10px
      scrollbar-height thin
      scrollbar-width thin
      scrollbar-color #888 #f1f1f1
      height -moz-available
      height fill-available
      max-width: min-content;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

    @media L_and_up
      display none

    .category__sub-navigation__bubble
      @media up_to_M
        display flex
        flex-direction column
        align-items center
        text-align center

    :not(:hover)::-webkit-scrollbar
      width 0

  &__bubbles
    text-align center
    display none
    justify-content center
    width calc(100% - 60px)

    .owl-loaded
      display flex !important

    .owl-stage
      padding-left 0 !important
      padding-right 0 !important

    @media up_to_L
      display none !important

    .owl-nav
      position absolute
      top 50%
      margin-top -15px
      width calc(100% + 60px)

  &__bubble
    display block
    float left
    width 105px
    padding 0px 15px
    outline none !important

    @media L_and_up
      width 115px

    &:hover
      text-decoration none

    &.active &__frame
      border 1px solid $witt-black

    &__frame
      display inline-block
      padding 2px
      border-radius 50%
      border 1px solid transparent
      transition all 0.3s

      &:hover
        @media L_and_up
          border 1px solid $witt-black

    &__image
      width 64px !important
      height 64px
      margin auto
      border-radius 50%
      border 1px solid $witt-secondary
      object-fit contain

      @media L_and_up
        width 78px !important
        height 78px

    &__label
      font-size 14px
      line-height 19px
      color $witt-black-text
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      text-align center
        
      @supports (-webkit-line-clamp: 2)
        overflow hidden
        text-overflow ellipsis
        white-space initial
        display -webkit-box
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        min-width fit-content

  // owl arrows prev and next
  &__pills
    .owl-prev
      margin-left -30px
    .owl-next
      margin-right -30px

  &__pills, &__bubbles
    .owl-prev
      position absolute
      left 0
      display block
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/slider-arrow-left.svg) !important
      background-repeat no-repeat !important
      background-position center center !important
      background-size 24px auto !important

      span
        display none

      &.disabled
        background-image unset !important
        mask url(../img/app/rwd/slider-arrow-left.svg) no-repeat center
        -webkit-mask url(../img/app/rwd/slider-arrow-left.svg) no-repeat center
        background-color $witt-secondary !important


    .owl-next
      position absolute
      right 0
      display block
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/slider-arrow-right.svg) !important
      background-repeat no-repeat !important
      background-size 24px auto !important

      span
        display none

      &.disabled
        background-image unset !important
        mask url(../img/app/rwd/slider-arrow-right.svg) no-repeat center
        -webkit-mask url(../img/app/rwd/slider-arrow-right.svg) no-repeat center
        background-color $witt-secondary !important

//      center menu bubbles if nav is disabled
.category__sub-navigation__bubbles:has(.owl-nav.disabled) .owl-stage-outer
  display flex
  justify-content center
