@require 'includes/*'

.contactform
  @media up_to_S
    &__buttons
      display flex
      flex-direction column
      align-items center

      :first-child
        margin-bottom 16px

    &__button
      width inherit