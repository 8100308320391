@require 'includes/*'

.header-icon-group
  padding 0
  list-style-type none

  &__icon
    display inline-block
    position relative
    cursor pointer

    @media S_and_up
      padding 6px 0

    @media up_to_L
      &.d-md-none,
        margin 0

    &:hover
      border-radius 4px
      background-color #EFF0F0

      .header-icon__label
        color $witt-black

      .icon
        &.icon__color--secondary
          background-color $witt-black !important

    &--direct-order
      @media up_to_L
        display none

      &.header-icon-group__icon
        padding 12px 16px 0 16px
        margin 0 16px
        min-height 61px

        &:after
          content ""
          position absolute
          bottom 0
          right -16px
          height 100%
          border-right 1px solid $witt-default-border

        .header-icon
          @media L_and_up
            flex-direction row
            flex-wrap nowrap
            width 190px
            column-gap 16px

          i
            flex 0 0 24px

          .header-icon__label
            flex 0 0 150px
            margin-top 0
            text-align left

    .header-icon
      display block
      display flex
      flex-wrap wrap
      justify-content center
      align-items center
      flex-direction column
      width 60px

      @media up_to_S
        width 45px

      @media L_and_up
        width 98px

        &--small
          width 50px

        &--medium
          width 70px

        &--wide
          width 120px

      &__label
        color #6C7275
        text-align center
        font-size 16px
        line-height 20.8px

        @media up_to_L
          font-size 12px
          line-height 15px

        @media up_to_S
          font-size 11px
          line-height 13px

  .miniBasketCount, .wishlistCount
    @media S_and_up
      margin-top 2px

  .miniBasketCount, .wishlistCount, .plus-disc, .logged-in
    position absolute
    right -8px
    bottom 3px
    width 16px
    height 16px
    border-radius 50%
    text-align center
    font-size 11px
    line-height 16px
    background: radial-gradient(68.17% 377.69% at 50.36% 50.36%, #E4003C 0%, #E31C5F 35%, #D70466 70.5%, #BE2766 100%)
    font-weight 600
    color white

  .plus-disc
    &:after
      content ''
      display block
      position absolute
      top 4px
      left 4px
      width 8px
      height 8px
      mask url(/img/app/rwd/icons/plus.svg) center no-repeat
      background-color white
      background-size 8px 8px
      background-position center center

  .logged-in
    &:after
      content ''
      display block
      width 12px
      height 12px
      position absolute
      right 1px
      top 2px
      background transparent url(/img/app/rwd/check-white.svg) center center  no-repeat
      background-size 12px 12px
