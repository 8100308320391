@require 'includes/*'

#mv
  .checkout-header
    .shoplogo
      @media up_to_M
        width 70px!important


.checkout-header
  .shoplogo
    @media up_to_M
      width 90px!important

    &--checkout
      float left
      margin 20px 10px 0
      @media up_to_L
        margin 20px 0 0

  &__steps
    float left
    margin-left 20px
    margin-top 30px
    margin-bottom 10px
    width calc(100% - 210px)

    @media up_to_M
      width calc(100% - 100px)
      margin-top 35px
      margin-left 10px

  &__step
    display inline-flex
    align-items center
    vertical-align top
    width 20%

    &__circle
      width 40px
      height 40px
      border-radius 50%
      background-color $witt-secondary
      color $witt-neutral-border
      font-size 13px
      line-height 13px
      font-weight bold
      text-align center
      inline-block()
      vertical-align middle
      display flex
      align-items center
      justify-content center

      @media up_to_M
        width 30px
        height 30px
        font-size 10px
        line-height 10px

      &--active
        background-color $witt-black
        color $witt-white

      &--visited
        background-color $witt-neutral-border
        color $witt-white

    &__text
      color $witt-neutral-border
      font-size 13px
      line-height 13px
      inline-block()
      vertical-align middle
      margin-left 10px

      @media screen and (max-width: $large)
        display none

      &--active
        color $witt-black-text

  &__disabledstep
    width 20%
    display inline-block
    vertical-align top
    cursor default
