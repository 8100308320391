@require 'includes/*'

//account__addresses is also used in shipping, but looks slightly different.
.account
  &__hideOnMobile
    @media screen and (max-width: $medium)
      display none
  &__back-button
    margin-bottom 20px
    min-height 35px
    @media screen and (max-width: $medium)
      margin-bottom 0
      margin-top 16px

  &__breadcrumb
    @media screen and (max-width: $medium)
      display none

  &__topheadline
    text-align center
    color $witt-black-text
    margin-bottom 0
    margin-top 16px
    font-size 25px
    line-height 25px

    @media screen and (min-width: $medium)
      display none

  &__divider
    display none

  &__welcome
    border 1px solid $witt-secondary-text
    padding 20px

  &__header
    margin-bottom 10px

  &__headline
    color $witt-primary
    font-weight bold
    margin-bottom 25px

    &--small
      font-weight bold
      color $witt-black-text
      margin-bottom 5px

      @media screen and (max-width: $medium)
        margin-top 15px

      span
        font-weight normal

    &--red
      font-weight bold
      color $witt-primary
      margin-bottom 15px

    &--margin
      margin 20px 0

  &__left-column
    max-width 320px

    @media screen and (max-width: $medium)
      max-width 100%

    &__back-button
      margin-top 10px
      @media screen and (max-width: $medium)
        display none

    &__inner
      box-shadow 0 8px 20px rgba(0, 0, 0, 0.2)
      position relative
      z-index 1

      @media screen and (max-width: $medium)
        box-shadow none

      &__subheadline
        @media screen and (max-width: $medium)
          display none

  &__info
    &__submit-button
      margin-bottom 20px

  &__customer-info
    margin-bottom 16px
    margin-top 8px
    text-align center
    @media screen and (min-width: $medium)
      padding 16px
      margin 0
      text-align unset

    &__salutation-container-name
      font-style italic

    &__no
      color $witt-secondary-text
      margin-bottom 10px
      @media screen and (max-width: $medium)
        color $witt-black-text
        font-weight 600

    &__salutation-container
      font-weight 700
      @media screen and (min-width: $medium)
        display flex
        flex-wrap wrap
      @media screen and (max-width: $medium)
        text-transform uppercase
        margin-top 16px
        font-weight 300

      &__salutation
        @media screen and (min-width: $medium)
          margin-right 5px

      span
        @media screen and (min-width: $medium)
          font-style italic

    &__premium
      margin-top 16px

  &__content
    vertical-align top
    color $witt-black-text
    margin-bottom 40px

    &__bordered
      background-color $witt-white
      border 1px solid $witt-default-border
      padding 35px 40px

      @media screen and (max-width: $medium)
        padding 10px


    &__headline
      margin-bottom 20px

    &__seperator
      border-bottom 1px solid $witt-default-border
      margin-top 10px
      margin-bottom 30px

  &__contact-info-container
    &--mobile
      display none
      margin-bottom 30px

      @media screen and (max-width: $medium)
        display block

    &--desktop
      position relative

      @media screen and (max-width: $medium)
        display none

  &__password
    &__password-strength
      margin-top 4px
      font-size 14px
      .registration__password-strength
        margin 0 0 20px 0
    &-hint
      color $witt-secondary-text
      margin-bottom 30px
    &-message
      .error
        margin-bottom 20px

    &__success
      color $witt-success
      font-weight bold
      margin-bottom 10px

  &__tab
    &__headline
      padding 12px 0
      margin-bottom 5px
      position relative

      a
        display block
        position absolute
        top 0
        left 0
        width 100%
        height 100%

    &__content
      margin-top 20px

      &.active
        display block

  &__customer
    span
      display inline-block
      margin-left 20px

  &__buttons
    padding 20px 0

  &__required
    color $witt-error
    font-weight bold

    @media screen and (max-width: $medium)
      text-align center
      margin-bottom 30px

  &__success
    color $witt-success
    font-weight bold
    margin-top 10px
    margin-bottom 10px

    @media screen and (max-width: $medium)
      text-align center
      margin-bottom 30px

  &__bordered
    border-top 1px solid $witt-secondary-text
    border-bottom 1px solid $witt-secondary-text
    margin 10px 0

  &__additional-text
    margin-top 10px

  &__list
    list-style-type none
    padding 10px 0 20px 40px
    margin 0

    @media screen and (max-width: $medium)
      list-style-type disc
      padding-left 20px

  &__orders
    font-size 14px
    border 1px solid $witt-light-grey
    padding 26px 40px
    border-radius 5px

    @media screen and (max-width: $large)
      padding 16px

    &_address
      display grid

      .address_cell
        line-height 18.2px

        &--receiver-name
          font-weight 800
          text-transform uppercase
          font-size 16px

        &--colissimo
          display flex
          align-items flex-start
          font-weight 400

          &-icon
            background transparent url("/img/app/rwd/icon-shipping-colissimo.png") center 0 no-repeat
            background-size 30px 25px
            width 30px
            height 18px

    &_detail
      background-color $witt-white
      .account__orders_list
        li:last-child
          .account__orders__table
            td
              border-bottom none

    &_heading
      font-weight 700
      margin-bottom 12px

    &_list
      list-style-type none
      margin 0
      padding 0
      width 99%

    .account__orders_parcel
      padding 16px
      box-shadow 2px 0 46px -23px rgba(0,0,0,0.75)
      margin-bottom 14px

    .account__orders_parcel_detail
      border-bottom 1px solid $witt-light-grey
      &_cell
        display inline-flex
        flex-direction column
        word-break break-word
        padding 0 10px 10px 0

        @media screen and (max-width: $medium)
          padding-right 0

        &:last-of-type
          padding-right 0

        .label
          color $witt-light-grey

    .account__orders_parcel_item
      border-bottom 1px solid $witt-light-grey
      padding 12px 0 16px 0

      .parcel_item_image
        margin 4px 12px 0 0
        height 90px
        overflow hidden
        border 1px solid $witt-light-grey

        &__atb
          cursor pointer

      .parcel_item_detail
        flex 0 1 50%
        line-height 18px
      .parcel_item_detail_heading
        font-weight bold

      .parcel_item_detail_ref
        color $witt-light-grey
        margin-left 4px
        font-size 12px

      .parcel_item_detail_attrs
        font-size 12px
        line-height 16px
        margin-top 6px
      .parcel_item_detail_label
        color $witt-light-grey
        margin-right 10px

      .parcel_item_price
        flex 1 1 auto
        text-align right
      .parcel_item_price_total
        font-weight bold

        &--tax
          font-weight 700
          font-size 12px
          color $witt-mid-grey
          margin 0

    .account__orders_parcel_item--premium
      padding-top 16px

      .parcel_item_image
        width 82px
        background-size 62px
        background-position center
        margin-top 0

      .parcel_item_detail
        display flex
        flex-direction column
        justify-content space-between

      .parcel_item_price_total
        color $helline-premium

    .account__order_parcel_button
      display flex
      justify-content center
      margin-top 16px

    .account_order__addresses
      margin-top 10px

      .account__orders_address
        padding 12px 0
        border-bottom 1px solid $witt-light-grey

        .address_image
          min-width: 70px

        .address_detail-wrapper
          padding 16px
          background-color $helline-grey-light2
          border-radius 10px
          flex 1 1 auto

        .address_detail
          font-weight 400

          &_heading
            font-size 12px
            color $witt-mid-grey
            margin-bottom 8px

        .address_type
          color $witt-mid-grey

          &_heading
            color $witt-black
            font-weight 800
            font-size 18px
            line-height 20.8px
            margin-bottom 8px
            display flex
            flex-wrap wrap
            column-gap 8px

          &_prices
            margin 8px 0 0 16px

          &_price
            display flex
            justify-content space-between
            color #66657B //replace after styleguideTicket merge

            &--title
              margin-bottom 4px
              font-size 16px

            &--total
              font-weight bold
              color $witt-black

            .address_type_price
              font-weight bold

    &_buttons
      &-row
        display flex
        justify-content center
        border-bottom 1px solid $witt-light-grey
        padding 32px 0

        &:last-child
          border none
          padding-bottom 0

        .btn
          width initial

        .btn-ghost
          padding 0 10px
          font-size 14px

        &--print
          padding-top 16px
          @media screen and (min-width: $medium)
            justify-content flex-start

        @media screen and (max-width: $medium)
          flex-direction column
          align-items center
          padding 16px

    @media screen and (max-width: $medium)
      width 100%

    &__article
      th
        display none!important

        @media screen and (max-width: $medium)
          display table-cell!important

      &--first
        th
          display table-cell!important
    &__package
      color $witt-white
      font-weight bold
      padding 5px
      background-color $witt-light-grey
      min-height 30px
      width 100%

      .tracking-link
        color $witt-white

    li
      @media screen and (max-width: $medium)
        margin-bottom 20px

      &.account__orders__detail
        border 3px solid $witt-light-grey

    &__table
      width 100%
      border-spacing 0
      border-collapse separate
      color $witt-darked-grey
      font-size 12px

      a
        color $witt-darked-grey

      th
        border-bottom 1px solid $witt-light-grey
        padding 0 0 14px 0
        font-weight inherit
        font-size 12px

        @media screen and (max-width: $medium)
          border none
          padding 0

      td
        border-bottom 1px solid $witt-light-grey
        padding 14px 0
        width 50%
        white-space nowrap
        overflow hidden
        text-overflow ellipsis

        @media screen and (max-width: $medium)
          border none
          padding 0 0 14px 0

          &.parcels
            @media screen and (min-width: $medium)
              width 30%
          &.detail
            @media screen and (min-width: $medium)
              width 70%

        &.price
          font-weight bold

      .article
        text-align left

        @media screen and (max-width: $medium)
          text-align center

        &__image
          img
            border 1px solid $witt-light-grey
            margin-left 5px
            max-width 100%

            @media screen and (max-width: $medium)
              margin-top 10px

      &--detail
        th
          display table-cell

          &:nth-child(2)
            @media screen and (max-width: $medium)
              border-right none

        td
          height 100px

          @media screen and (max-width: $medium)
            height 0
            min-height 40px
            padding 5px 10px
            vertical-align middle

          &:nth-child(2)
            @media screen and (max-width: $medium)
              border-right none

  &__info-text
    margin-top 20px
    color $witt-secondary-text


  &__info-tooltip
    display inline-block
    margin-left 10px

  &__premium-welcome
    font-weight bold
    text-align center
    margin 50px 0

  &__premium-dates
    padding 0 40px
    margin-bottom 20px

    &__text
      inline-block()
      width 60%
      margin-bottom 10px

      @media screen and (max-width: $medium)
        width 100%
        margin-bottom 0

    &__date
      inline-block()
      width 39%
      margin-bottom 10px

      @media screen and (max-width: $medium)
        width 100%

  &__premium-subscribe
    padding 0 90px
    margin-bottom 20px

    @media screen and (max-width: $medium)
      padding 0

    &--centered
      text-align center

    &__headline
      font-weight bold

    span
      font-weight bold

  &__premium-advantages
    padding 30px
    margin 20px 0
    background-color $witt-secondary

    &__headline
      font-weight bold
      margin-bottom 15px

    &__list
      list-style-type none
      margin 0
      padding 0

      li
        margin-bottom 10px

        &:before
          content '✔'
          display inline-block
          margin-right 10px

  &__premium-button
    text-align center
    margin 10px 0

  &__premium-button-text
    text-align center
    margin-bottom 30px

  &__catalogue__main-catalogue
    img
      max-width 100%

  &__catalog__text
    padding 120px 0 0 0

    @media screen and (max-width: $medium)
      padding 20px 0 0 0

  &__catalog__button
    margin-top 20px

  &__catalog__more
    font-weight bold
    margin-top 40px
    margin-bottom 40px

    img
      max-width 100%
      margin-top 20px

  &__catalog__success-text
    margin 20px 0

  &__overview-button
    text-align center
    margin 20px 0

  &__premium-teaser
    background-color $witt-secondary
    display flex
    align-items center
    margin 20px 0

    @media screen and (max-width: $medium)
      flex-wrap wrap

    &__picture
      @media screen and (max-width: $medium)
        width 100%

      img
        width 100%

    &__text
      padding 20px 60px

      @media screen and (max-width: $medium)
        padding 20px

      a
        text-decoration underline

  &__download-print
    a
      color $witt-neutral-border
      background-size contain
      background-position left center
      background-repeat no-repeat
      padding-left 32px
      display inline-block
      margin 0 12px

      .account__orders_detail &
        margin-left 0

      @media screen and (max-width: $large)
        margin 10px 0

    &__download
      background-image url(../img/app/rwd/helline/Download.svg)
    &__print
      background-image url(../img/app/rwd/helline/print.svg)

  &__card
    box-shadow 0 8px 20px rgba(0, 0, 0, 0.2)
    background-color white

  &__entry
    &__orders
      margin-top 20px
      margin-bottom 30px

      &_buttons
        margin-top 20px
        display flex
        justify-content center

    &__headline
      margin-bottom 20px
      display flex

      &__count
        display inline-block
        color $witt-black-text
        margin-left 7px

    &__teaser
      margin-bottom 40px

    &__wishlist
      margin-bottom 30px
      height 100%
      @media screen and (max-width: $medium)
        display none

      &__article
        padding 0 7px
        outline 0

        .custom-dropdown
          .opener
            padding 2px 0

            .content
              padding 12px 18px 12px 50px
              text-align left
              left 0

          ul
            li
              padding 12px 18px 12px 50px
              text-align left

  .margin-top
    margin-top 30px

  .margin-bottom
    margin-bottom 30px

  .mobile-centered
    @media screen and (max-width: $medium)
      text-align center

  .material
    &.birthday
      margin-top 1rem

      &--select-field
        margin-top 0

  &__returns
    &__headline
      margin-bottom 10px

  &__legal
    margin 20px 0
    color $witt-secondary-text