@require 'includes/*'

.header-container
  background-color $witt-white
  transition top 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)
  &--invisible
    top 0 !important
    @media M_and_up
      top -225px !important
  &--sticky
    position -webkit-sticky
    position sticky
    top 0
    height 100%
    margin-left -15px
    margin-right -15px
    z-index 1000
    padding 0 15px
    background-color $witt-white
    @media M_and_up
      .navigation
        border-bottom 1px solid $witt-default-border

.pageheader
  width 100%

  @media up_to_M
    height 105px
    margin-top 20px
    transition height 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)

  &.condensed //confirmation page
    @media up_to_M
      height auto
      padding 0
      margin 0

    .shoplogo--confirmation
      margin 10px 0

  &__small
    @media up_to_M
      height 61px

  &__metanav
    margin-bottom 23px
    padding-top 13px
    display block
    text-align right

    @media up_to_M
      display none

    &__list
      list-style-type none
      margin 0
      padding 0

      &-item
        inline-block()
        vertical-align middle
        margin-left 15px

        &first-child
          margin-left 0

        &--contact
          sprite ($metanav_contact)
          margin-left 5px

  &__container
    margin-top 20px
    display flex
    align-items center
    justify-content space-between

    @media up_to_M
      margin-top 10px
      width 100%
      height 55px
      position absolute
      top -14px
      z-index 100
      background-color $witt-white

  .header-icon
    &-group
      display flex
      align-items flex-end
      justify-content space-between
      column-gap 16px
      margin-bottom 0px

      @media up_to_S
        column-gap 8px

      @media up_to_XS
        column-gap 4px

    &__search
      display none
      &--visible
        inline-block()
        margin-top 2px
        margin-right 17px
        @media up_to_S
          margin-right 0



  .logo
    float left
    width 108px
    height 54px
    margin-top 20px
    background-image url(/img/app/rwd/logo.png)
    background-repeat no-repeat
    background-position center center
    background-size contain
    margin-right 10px
    margin-left 10px

    a
      display block
      width 100%
      height 100%

    @media up_to_L
      width 85px
      margin-top -2px
      margin-left 48px

    @media M_to_L
      margin-top 4px


#searchfield
  border-radius 8px

  &:focus::placeholder
    color transparent