@require 'includes/*'

.custom-dropdown
  width 100%
  font-size 16px
  text-align center
  position relative
  margin-bottom 15px
  color $witt-black-text

  &__icon
    position absolute
    right 16px
    top 8px

  &__singleVariation, .singleVariation
    cursor default

  &__opener
    cursor pointer

  &__opener, &__singleVariation, .singleVariation
    width 100%
    border none
    background-color $witt-secondary
    padding 12px 0

    i
      transition transform 0.3s ease
      transform rotate(0deg)
    
    &.active
      background-color $witt-default-border
      
      i
        transform rotate(180deg)
        
    .content-color
      position absolute
      left 15px
      top calc(50% - 12px)
      
      span
        display inline-block
        border 1px solid $witt-default-border
        border-radius 50%
        height 24px
        width 24px

  &__error
    border 1px solid $witt-error

  ul
    display none
    position absolute
    z-index 99
    width 100%
    margin 0
    padding 0
    list-style-type none
    background-color $witt-secondary
    
    li
      width 100%
      background-color $witt-secondary
      padding 12px 0
      cursor pointer
      position relative

      &:hover
        background-color $witt-default-border

.custom-dropdown--new
  text-align left //remove this after removing the old styling

  &.custom-dropdown--error //remove the old class custom-dropdown__error in jade and coffeescript
    .custom-dropdown__opener
      border-color $witt-error

  &.custom-dropdown--xs
    .custom-dropdown
      &__opener
        padding 10px 8px 10px 16px
      &__icon
        top 8px

  &.custom-dropdown--single
    .custom-dropdown
      &__opener
        text-align center
        pointer-events none

  .custom-dropdown
    &__icon
      top 16px
    &__opener
      border 1px solid $witt-default-border
      border-radius 8px
      font-size 16px
      background-color #FFFFFF //remove this after removing the old styling
      padding 18px 16px
      text-align left
      -webkit-appearance none

      &:hover, &:focus
        border-color $witt-neutral-border

        .custom-dropdown__opener-text
          &.placeholder
            color $witt-black-text

      &.active
        border-color $witt-neutral-border
        border-bottom-color $witt-default-border
        border-bottom-right-radius 0
        border-bottom-left-radius 0

      &-text
        &.placeholder
          color $witt-secondary-text

    &__options
      background-color $witt-white
      border 1px solid $witt-neutral-border
      border-bottom-left-radius 8px
      border-bottom-right-radius 8px
      border-top none
      max-height 250px
      overflow auto

    &__option
      padding 0 16px 0 0
      background-color unset
      max-height 55px
      display flex
      justify-content space-between
      align-items center

      &:not(:first-child)
        border-top 1px solid $witt-default-border

      &:last-child
        border-bottom-left-radius 8px
        border-bottom-right-radius 8px

      &.disabled
        color $witt-text-disabled
        pointer-events none

      &:hover, &:focus, &.selected
        background-color $witt-secondary

      &-label
        padding 18px 16px

      span:not(.custom-dropdown__option-label)
        flex 0 1 88px
        text-align right

    &--xs
      padding 0
