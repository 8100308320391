@require 'includes/*'

.category__sub-navigation
  color $witt-black-text
  height 100%
  position relative

  &--sub
    font-size 12px

  &__container
    position absolute
    height calc(100% - 50px)
    width 100%
    overflow-x hidden
    overflow-y auto
    padding-right 30px

  &__headline
    display block
    font-weight 700
    font-size 13px
    line-height 22px
    padding 10px 15px
    border-bottom 1px solid $witt-default-border

  ul
    list-style-type none
    margin 0
    padding 0
    font-size 13px

    li
      position relative

  &__item
    display inline-block
    flex-grow 1
    padding 10px 15px 10px 15px
    border-bottom 1px solid $witt-default-border
    width 100%

    &:hover,
    &:active
      text-decoration none
      font-weight bold
      background-color $witt-secondary

    &--current
      font-weight 700

    &--sub
      padding 3px 10px 3px 30px
      border none

      &+ .category__sub-navigation__item__count
        top 4px

    &--sub-sub
      padding 3px 10px 3px 50px
      border none

      &+ .category__sub-navigation__item__count
        top 4px

    &__count
      display inline-block
      color $witt-secondary-text
      font-size 12px
      position absolute
      right 2px
      top 10px

  @media M_and_up
    margin-bottom 45px



.category__checkbox-sub-navigation
  &__headline
    display none

  ul
    list-style-type none
    margin 0
    padding 0
    font-size 13px
  &__item
    padding 3px 0 2px 0
    color $witt-black-text
    display block

    &--current
      font-weight bold
    &:hover,
    &:active
      background-color $witt-secondary
      text-decoration none
    &:active
      font-weight bold

    .checkmark
      top 2px
  &__hits
    color $witt-neutral-border
