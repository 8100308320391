@require 'includes/*'

//structure is similar to category page, thus it also borrows from .category

.channel
  color $witt-black-text
  
  aside
    margin-top 0px

  .dropdown
    @media up_to_M
      margin-top 15px
      margin-bottom 15px

    @media M_and_up
      border none
      padding-left 0
      padding-right 0

      &__header
        display none
      &__content
        display block
        position relative
        border none
        padding 0

  .category__sub-navigation
    &__headline
      display block
      font-weight 700
      font-size 13px
      line-height 22px
      padding 10px 15px
      border-bottom 1px solid $witt-default-border

    &__item
      padding 10px 15px
      @media M_and_up
        border-bottom 1px solid $witt-default-border

    @media M_and_up
      margin-bottom 45px


  img
    max-width 100%
    margin 0 auto
    display block
    
  .background-image
    background-position center center
    background-repeat no-repeat
    background-size 100% auto
    padding-top 37.5%
    position relative
    
  .background-image-a
    position absolute
    top 0px
    left 0px
    width 100%
    height 100%
    
  .background-image-overlap
    position absolute
    
    @media screen and (max-width: $medium)
      left 10px!important
      top 10px!important
    
  .block_titre_rayon
    text-align center
    height 29px
    border-radius 5px 5px 0 0
    background-color $witt-secondary
    width 190px
    display block
    color $witt-black-text
    text-decoration none
    padding-top 6px
    font-size 15px
    
  .block_image_titre
    margin-bottom 26px
    
  h2
    @media screen and (max-width: $medium)
      font-size 16px!important
      line-height 20px!important

  .product
    &__additional-box
      border none
      &__header
        border none