
#mv
  @font-face {
    font-family: 'open sans'
    src: url('/fonts/OpenSans-Bold-webfont.woff') format('woff')
    font-weight: 700
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'open sans'
    src: url('/fonts/OpenSans-Italic-webfont.woff') format('woff')
    font-weight: normal
    font-style: italic
    font-display: swap
  }

  @font-face {
    font-family: 'open sans'
    src: url('/fonts/OpenSans-Regular-webfont.woff') format('woff')
    font-weight: 400
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'open sans'
    src: url('/fonts/OpenSans-Light-webfont.woff') format('woff')
    font-weight: 300
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'open sans'
    src: url('/fonts/OpenSans-Semibold-webfont.woff') format('woff')
    font-weight: 500
    font-style: normal
    font-display: swap
  }

#helline

  @font-face {
    font-family: 'Mulish'
    src: url('/fonts/mulish/Mulish-Regular.woff') format('woff')
    font-weight: 400
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'Mulish'
    src: url('/fonts/mulish/Mulish-Bold.woff') format('woff')
    font-weight: 700
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'MillerBanner'
    src: url('/fonts/millerbanner/millerbanner-regular.woff') format('woff')
    font-weight: 400
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'MillerBanner'
    src: url('/fonts/millerbanner/millerbanner-bold.woff') format('woff')
    font-weight: 700
    font-style: normal
    font-display: swap
  }

  @font-face {
    font-family: 'VivaBeautiful'
    src: url('/fonts/vivabeautiful/vivabeautifulpro-regular.woff') format('woff')
    font-weight: 400
    font-style: normal
    font-display: swap
  }