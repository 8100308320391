@require '../includes/*'

#helline
  .picture-sticker

    &--hidden
      display none

    &--webonly, &--new
      background-color #E8E7F8
      color $helline-dark-blue

    &--reduction
      background-color $helline-error
      color $witt-white

      &--label
        display none