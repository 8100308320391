@require 'includes/*'

.fragment-icon-external-link{
  background-image: url(../img/app/rwd/external-link.svg);
  width 30px
  height 30px
  margin-left 4px
}

.fragment-link-content
  display flex
  justify-content center
  color blue
  text-decoration underline

.contentDummy
  background-image url('../img/app/rwd/background/stripes.png')
  text-align center
  vertical-align middle
  font-size 18px
  text-overflow ellipsis
  overflow hidden
  padding 10px
  max-width 100%
  color $witt-black
  position relative
  .complete
    absolute left top
    width 100%
    height 100%