@require 'includes/*'

#helline
  color $helline-dark-blue
  font-family $helline-font-primary
  font-weight: 400

  .icon

    &__color
      &--primary
        background-color $helline-dark-blue !important
      &--neutral
        background-color $helline-black !important
      &--inverted
        background-color $helline-white !important
      &--secondary
        background-color $helline-secondary !important
      &--secondary-text
        background-color $helline-secondary-text !important
      &--premium
        background-color $helline-premium !important

    &__header_loggedin_badge
      position absolute
      top unset
      bottom -12px
      left 6px
      right 0
      background-image url(/img/app/rwd/check-white.svg)
      background-size 65% auto
      background-position center center
      background-repeat no-repeat

    /* helline icons multi color/ png */
    &__helline-logo-png
      background-image  url(../img/app/rwd/helline/logo.png)
      background-color unset !important
    &__helline-logo-padding
      background-image  url(../img/app/rwd/helline/logo-padding.png)
      background-color unset !important
    &__helline-payment-4etoiles
      background-image url(../img/app/rwd/helline/payment-4etoiles.png)
      background-color unset !important
    &__helline-payment-4etoiles_30px
      background-image url(../img/app/rwd/helline/payment-4etoiles_30px.png)
      background-color unset !important
    &__helline-help-phone
      background-image url(../img/app/rwd/helline/help-phone.gif)
      background-color unset !important
      background-size: contain
      background-repeat: no-repeat
      width: 50px
      height: 50px
    &__helline-payment-cb
      background-image url(../img/app/rwd/helline/payment-cb.svg)
      background-color unset !important
      width 30px
      height 30px
    &__helline-payment-cb-2
      background-image url(../img/app/rwd/helline/payment-cb-2.svg)
      background-color unset !important
      width 30px
      height 30px
    &__helline-payment-maestro
      background-image url(../img/app/rwd/helline/payment-maestro.svg)
      background-color unset !important
      width 30px
      height 30px
    &__helline-payment-mastercard
      background-image url(../img/app/rwd/helline/payment-mastercard.svg)
      background-color unset !important
      width 30px
      height 30px
    &__helline-payment-visa
      background-image url(../img/app/rwd/helline/payment-visa.svg)
      background-color unset !important
      width 30px
      height 30px
    &__helline-spinner
      background-image url(../img/app/rwd/helline/spinner.gif)
      background-color unset !important
      background-size: contain
      background-repeat: no-repeat
      width: 50px
      height: 50px



    &__helline-icon-add-wishlist
      background-image url(../img/app/rwd/helline/icon-add-wishlist.svg)
      background-color unset !important
    &__helline-icon-add-wishlist-full
      background-image url(../img/app/rwd/helline/icon-add-wishlist-dark-active.svg)
      background-color unset !important


    /* helline icons */
    &__helline-checkout-advantage-payment
      mask url(../img/app/rwd/helline/checkout-advantage-payment.svg) no-repeat center
      -webkit-mask url(../img/app/rwd/helline/checkout-advantage-payment.svg) no-repeat center
    &__helline-checkout-advantage-period
      mask url(../img/app/rwd/helline/checkout-advantage-period.svg) no-repeat center
      -webkit-mask url(../img/app/rwd/helline/checkout-advantage-period.svg) no-repeat center
    &__helline-checkout-advantage-returns
      mask url(../img/app/rwd/helline/checkout-advantage-returns.svg) no-repeat center
      -webkit-mask url(../img/app/rwd/helline/checkout-advantage-returns.svg) no-repeat center
    &__helline-account //remove after splitting witt
      mask url(../img/app/rwd/helline/account.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/account.svg) no-repeat center;
    &__helline-add-to-basket
      mask url(../img/app/rwd/helline/add-to-basket.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/add-to-basket.svg) no-repeat center;
    &__helline-arrow-left, &__arrow-left
      mask url(../img/app/rwd/helline/arrow-left.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/arrow-left.svg) no-repeat center;
      display block
      margin-right 10px
    &__helline-arrow-right, &__arrow-right
      mask url(../img/app/rwd/helline/arrow-right.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/arrow-right.svg) no-repeat center;
      display block
      margin-left 10px
    &__arrow-right-slider
      mask url(../img/app/rwd/icons/arrow-right.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/icons/arrow-right.svg) no-repeat center;
    &__arrow-left-slider
      mask url(../img/app/rwd/icons/arrow-left.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/icons/arrow-left.svg) no-repeat center;
    &__basket, &__helline-basket //delete __basket after splitting from witt
      mask url(../img/app/rwd/helline/basket.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/basket.svg) no-repeat center;
    &__helline-basket-check
      mask url(../img/app/rwd/helline/basket-check.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/basket-check.svg) no-repeat center;
    &__helline-basket-percent
      mask url(../img/app/rwd/helline/basket-percent.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/basket-percent.svg) no-repeat center;
    &__helline-burger
      mask url(../img/app/rwd/helline/burger.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/burger.svg) no-repeat center;
    &__helline-check
      mask url(../img/app/rwd/helline/check.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/check.svg) no-repeat center;
    &__helline-check-bold
      mask url(../img/app/rwd/helline/check-bold.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/check-bold.svg) no-repeat center;
    &__helline-check-circle
      mask url(../img/app/rwd/helline/check-circle.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/check-circle.svg) no-repeat center;
    &__helline-chevron-left
      mask url(../img/app/rwd/helline/chevron-left.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/chevron-left.svg) no-repeat center;
    &__helline-chevron-right
      mask url(../img/app/rwd/helline/chevron-right.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/chevron-right.svg) no-repeat center;
    &__helline-close-x
      mask url(../img/app/rwd/helline/close-x.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/close-x.svg) no-repeat center;
    &__helline-Contact
      mask url(../img/app/rwd/helline/Contact.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/Contact.svg) no-repeat center;
    &__helline-direct-order
      mask url(../img/app/rwd/helline/direct-order.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/direct-order.svg) no-repeat center;
    &__helline-Download
      mask url(../img/app/rwd/helline/Download.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/Download.svg) no-repeat center;
    &__helline-empty-basket
      mask url(../img/app/rwd/helline/empty-basket.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/empty-basket.svg) no-repeat center;
    &__helline-favorites
      mask url(../img/app/rwd/helline/favorites.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/favorites.svg) no-repeat center;
    &__helline-form-error
      mask url(../img/app/rwd/helline/form-error.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/form-error.svg) no-repeat center;
    &__helline-form-success
      mask url(../img/app/rwd/helline/form-success.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/form-success.svg) no-repeat center;
    &__helline-h-bg
      mask url(../img/app/rwd/helline/h-bg.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/h-bg.svg) no-repeat center;
      background-color #ffece5
    &__helline-icon-grid-3x3
      mask url(../img/app/rwd/helline/icon-grid-3x3.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-grid-3x3.svg) no-repeat center;
    &__helline-icon-grid-big
      mask url(../img/app/rwd/helline/icon-grid-big.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-grid-big.svg) no-repeat center;
    &__helline-icon-grid-big-mobile
      mask url(../img/app/rwd/helline/icon-grid-big-mobile.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-grid-big-mobile.svg) no-repeat center;
    &__helline-icon-grid-small
      mask url(../img/app/rwd/helline/icon-grid-small.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-grid-small.svg) no-repeat center;
    &__helline-icon-grid-small-mobile
      mask url(../img/app/rwd/helline/icon-grid-small-mobile.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-grid-small-mobile.svg) no-repeat center;
    &__helline-icon-like
      mask url(../img/app/rwd/helline/icon-like.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-like.svg) no-repeat center;
    &__helline-icon-shop
      mask url(../img/app/rwd/helline/icon-shop.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-shop.svg) no-repeat center;
    &__wishlist, &__helline-icon-wishlist //delete after splitting witt and helline
      mask url(../img/app/rwd/helline/icon-wishlist.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-wishlist.svg) no-repeat center;
    &__helline-icon-wishlist-remove
      mask url(../img/app/rwd/helline/icon-wishlist-remove.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/icon-wishlist-remove.svg) no-repeat center;
    &__helline-order
      mask url(../img/app/rwd/helline/order.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/order.svg) no-repeat center;
    &__helline-order_commande
      mask url(../img/app/rwd/helline/order_commande.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/order_commande.svg) no-repeat center;
    &__order_commande, &__helline-order_commande2 //delete after splitting witt and helline
      mask url(../img/app/rwd/helline/order_commande2.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/order_commande2.svg) no-repeat center;
    &__helline-order_commande-mobile-nav
      mask url(../img/app/rwd/helline/order_commande-mobile-nav.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/order_commande-mobile-nav.svg) no-repeat center;
    &__helline-print
      mask url(../img/app/rwd/helline/print.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/print.svg) no-repeat center;
    &__helline-search
      mask url(../img/app/rwd/helline/search.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/search.svg) no-repeat center;
    &__helline-slider-arrow-left
      mask url(../img/app/rwd/helline/slider-arrow-left.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/slider-arrow-left.svg) no-repeat center;
    &__helline-slider-arrow-right
      mask url(../img/app/rwd/helline/slider-arrow-right.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/slider-arrow-right.svg) no-repeat center;
    &__helline-wishlist-delete, &__trash
      mask url(../img/app/rwd/helline/wishlist-delete.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/helline/wishlist-delete.svg) no-repeat center;

    &__pencil
      mask url(../img/app/rwd/icon-line-item-edit.svg) no-repeat center;
      -webkit-mask url(../img/app/rwd/icon-line-item-edit.svg) no-repeat center;
