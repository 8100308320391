@require 'includes/*'

.header
  &__search
    inline-block()
    vertical-align top
    z-index 50
    width 100%

    @media L_and_up
      width calc(100% - 560px)
      max-width 375px
    @media up_to_M
      width 100%
      position absolute
      left 0
      top 20px
      margin 40px 0 0 0
      transition margin-top 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)
    @media M_to_L
      width calc(100% - 380px)

      &--hidden
        margin-top -15px

    &-field
      width 100%
      inline-block()
      vertical-align top
      position relative

      .easy-autocomplete-container
        margin-top 0

    &--text
      font-size 16px
      line-height 20.8px
      border 1px solid $witt-default-border
      display block
      width 100%
      padding 4px 16px 4px 40px
      color $witt-black
      height 50px
      background $witt-white url(/img/app/rwd/icons/search.svg) 16px center no-repeat
      background-size 16px auto

      &::placeholder
        color $witt-black
      &::-webkit-input-placeholder
        color $witt-black
      &::-moz-placeholder
        color $witt-black
      &:-ms-input-placeholder
        color $witt-black
      &:-moz-placeholder
        color $witt-black

      &:hover, &:active
        border-color $witt-black

      &:focus
        border-color $witt-black
        outline none
        box-shadow none

      @media L_to_XL
        font-size 12px
        line-height inherit
        padding 4px 8px 4px 32px
        background-position 8px center

    .easy-autocomplete
      width 100% !important

      &-container
        z-index 10000
        position absolute
        width 100%

        ul
          background none repeat scroll 0 0 $witt-white
          display none
          margin-top 0
          padding-bottom 0
          padding-left 0
          position relative
          top -1px
          li
            &:last-child
              border-radius 0 0 2px 2px
              border-width 0 1px 1px
            &.selected
              background none repeat scroll 0 0 $witt-secondary
              cursor pointer
              div
                font-weight normal
            div
              display block
              font-weight normal
              word-break break-all
            b
              font-weight bold
          .eac-category
            font-style italic

    .easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category
      background inherit
      border-color $witt-default-border
      border-image none
      border-style solid
      border-width 0 1px
      display block
      font-size 14px
      font-weight normal
      padding 4px 12px
