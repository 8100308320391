@require 'includes/*'

#service
  &__contact
    h3
      color $witt-primary
      font-size 16px
      margin-top 30px
    &--teaser
      h2
        color $witt-primary
        margin-bottom 8px
        margin-top 16px
    &--actions
      margin-top 8px
      margin-bottom 16px
  #serviceTopics
    padding 0px
    width 100%
    overflow hidden
    
    li
      list-style-type none
      margin 0px
      line-height 18px
      text-align left
      padding 5px 0
    
    .topic
      width 100%
      padding 20px 15px 12px 15px
      display inline-block
      border-bottom 1px solid $witt-secondary-text
      transition all linear 0.15s
      font-size 1.2em
      color $witt-black-text
      
      div
        
        .headline
          color $witt-black-text
          cursor pointer
          font-size 18px
          font-weight 800
          transition all linear 0.15s
          
          h2
            color $witt-black-text
            font-size 18px
            font-weight 400

        .content
          margin-right 40px
          display none

          &:first-child
            display block
      
  a
    text-decoration underline
    
  h3
    margin-top 20px
    color $witt-black-text
    
  h4
    margin-top 20px
    color $witt-black-text
    font-weight bold
    font-size 14px
    
  .content
    p
      font-size 13px
      color $witt-black-text
      
  img
    max-width 100% !important
    width auto !important
    height auto !important
    
  .collapse
    border 1px solid $witt-secondary-text
    width 24px
    height 24px
    border-radius 50%
    position relative
    inline-block()
    vertical-align middle
    margin-left 34px
    cursor pointer
    float right

    &:before
      display block
      content ''
      width 10px
      height 2px
      background-color $witt-secondary-text
      position absolute
      left 6px
      top 10px

    &:after
      display none
      
    &.plus
      &:after
        display block
        content ''
        width 10px
        height 2px
        background-color $witt-secondary-text
        position absolute
        left 6px
        top 10px
        transform rotate(90deg)
        
  #themeSelector
    width 100%


  .MR-Widget
    border none !important
    max-width 100%

  .MRW-Search
    @media screen and (max-width: $medium)
      padding 25px 10px 25px 70px

  #MRW-Map
    width 70%

    @media screen and (max-width: $medium)
      width 100% !important

  .MRW-RList
    font-size 12px
    width 28% !important

    @media screen and (max-width: $large)
      margin-top 20px

    @media screen and (max-width: $medium)
      width 100% !important

    .PR-List-Item
      margin-bottom 30px

    .PR-Name
      color $witt-primary
      font-weight bold
            
#codePromo
  #codePromoHeader
    margin-bottom 20px
    h1
      color $witt-primary
      font-weight bold
      font-size 32px
      line-height 38px
      margin-top 20px
    h2
      color $witt-black-text
      font-size 20px
      line-height 24px
      margin 0
    ul
      margin-bottom 20px
      li
        font-size 14px
        line-height 18px
        list-style none
  #codePromoBox
    margin-bottom 30px
    .codePromo:first-child
      border-top solid $witt-secondary-text 1px
    .codePromo
      border-bottom solid $witt-secondary-text 1px
      min-height 120px
      .codePromoInfo
        width 750px
        padding-top 15px
        padding-bottom 15px
        padding-left 0
        padding-right 0
        float left
        h3
          color $witt-primary
          font-size 24px
          line-height 28px
          letter-spacing 1px
    .buttonBox
      float right
      width 180px
      margin-top 20px
      .buttonText
        text-align center
      .button
        &:hover
          text-decoration none
        cursor pointer
        background $witt-primary
        text-align center
        color $witt-white
        padding 12px
        font-size 24px
        margin 10px 20px
        box-shadow 2px 2px 4px $moda-border-2
        display block
  #infoBox
    background-color $moda-border
    padding-top 10px
    padding-bottom 10px
    padding-left 10px
    padding-right 10px
    margin-bottom 20px
    h2
      font-size 16px
      line-height 20px
      text-transform uppercase
      color $witt-primary
      margin 0
    ol
      counter-reset li
      list-style none
      li
        font-size 15px
        line-height 19px
        list-style none
        span
          position relative
          display block
          padding 0.4em 0.4em 0.4em 1.5em
          margin 0.5em 0
          background $moda-border
          color $witt-black-text
          text-decoration none
          border-radius 0.3em
          transition all 0.3s ease-out
        span:before
          box-sizing initial
          content counter(li)
          counter-increment li
          border-radius 2em
          background $witt-primary
          height 2em
          width 2em
          line-height 2em
          border 0.3em solid $moda-border
          text-align center
          font-weight bold
          position absolute
          color $witt-white
          left -1.3em
          top 50%
          margin-top -1.3em
          transition all 0.3s ease-out
        span:hover:before
          transform: rotate(360deg)
  #legalBox
    h2
      font-size 13px
      line-height 17px
      font-weight bold
      color $witt-black-text
      margin 0
    p
      font-size 12px
      line-height 16px


