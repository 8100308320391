@require 'includes/*'

.login
  padding-top 24px
  padding-bottom 45px
  overflow: unset

  @media screen and (max-width: $medium)
    padding-top 0
    
  h1
    margin-top 40px
    font-size 34px
    text-align center
    
  h2
    font-size 16px
    text-align center
    
  &__box
    padding 30px
    background-color $witt-white
    margin 40px 0
    box-shadow 0 10px 25px rgba(0, 0, 0, 0.2)
    
    @media screen and (max-width: $medium)
      padding 10px
      
    &__text
      margin-bottom 10px
      
  &__back
    min-width 40px
    height 40px
    position absolute
    justify-content unset
    margin-left -50px

  &__intro
    text-align center
    margin-bottom 40px
    font-size 14px
    line-height 1.2em
      
  &__button
    margin-top 24px

    &__text
      margin-left 7px
      width 100%

    &__info
      display: block
      margin-left: auto
      margin-right: 0

    button
      @media screen and (max-width: $medium)
        margin-top 10px

  &__stay-connected-text-container
    margin-top 8px
    margin-down 25px
    background-color #E5ECFF
    padding 8px 16px

    &.inactive
        display none
    
    @media screen and (max-width: $medium)
      margin-top 10px
      
  &__captcha
    margin 20px 0
    
    p
      margin-bottom 5px

  &__headline
    font-size 20px
    line-height 20px
    font-weight bold
    margin-bottom 40px

    @media screen and (max-width: $medium)
      margin-bottom 30px

  &__text
    font-size 14px
    line-height 17px
    margin-bottom 30px

    &--info
      color red
      
  &__hint
    margin-top 20px
    margin-bottom 40px
    font-size 14px
    line-height 1.2em


  &__link
    font-size 12px

    &--no-padding
      padding-bottom 0
      
    &--right
      text-align right

    .rightpanel-link
      text-decoration underline
      text-align left

  &__new-account
    margin-top 10px
      
  &__tooltip
    position absolute
    top 0
    right 0
    margin-top -22px
    z-index 2

  .icon
    margin-left 10px
    margin-top 9px

  .error
    position relative
    
  &__error-messages
    &--margin-bottom
      margin-top 4px
      margin-bottom 10px

  .remark
    color #349f2c
    
  &__supplementary-text
    font-size 13px
    color $witt-grey
    margin-bottom 40px
    
  &__create-account
    padding-top 10px
    
    &__hint
      font-size 18px
      line-height 1.2em
      font-weight 700
      margin-top 30px
      margin-bottom 10px
      
  .unknownEmailError
    border-color $witt-error !important
    color $witt-error !important

  &__identification-button
    justify-content center

#layerPasswordForgotten

  h1
    font-size 20px
    color $witt-error
    position absolute
    top 0
    margin-top -35px

  input
    width calc(100% - 160px)

  .btn
    float right
    margin-top 0

  .success
    margin-top 10px

#inputLoginMailOrWelcomeNumber.login-error-border
  border-color #d30028 !important

#eac-container-inputLoginMailOrWelcomeNumber
  cursor pointer

.js-hide
  display none

.js-show
  display block

