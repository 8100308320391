@require 'includes/*'

.logo
  /* helline icons multi color/ png */
  &__helline-logo-png
    background-image  url(../img/app/rwd/helline/logo.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center

  &__helline-logo-padding
    background-image  url(../img/app/rwd/helline/logo-padding.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-4etoiles
    background-image url(../img/app/rwd/helline/payment-4etoiles.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-4etoiles_30px
    background-image url(../img/app/rwd/helline/payment-4etoiles_30px.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-help-phone
    background-image url(../img/app/rwd/helline/help-phone.gif)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-cb
    background-image url(../img/app/rwd/helline/payment-cb.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-cb-2
    background-image url(../img/app/rwd/helline/payment-cb-2.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-maestro
    background-image url(../img/app/rwd/helline/payment-maestro.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-mastercard
    background-image url(../img/app/rwd/helline/payment-mastercard.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-payment-visa
    background-image url(../img/app/rwd/helline/payment-visa.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-spinner
    background-image url(../img/app/rwd/helline/spinner.gif)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__helline-icon-add-wishlist
    background-image url(../img/app/rwd/helline/icon-add-wishlist.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center


  /* multicolor or png old icons */
  &__icon_compte
    background-image url(../img/app/rwd/icon_compte.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon_compte_moda
    background-image url(../img/app/rwd/icon_compte_moda.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__basket-freeItem
    background-image url(../img/app/rwd/basket-freeItem.jpg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__advantage-happiness
    background-image url(../img/app/rwd/advantage-happiness.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-3xcb
    background-image url(../img/app/rwd/icon-3xcb.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__envelope
    background-image url(../img/app/rwd/icon-envelope.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-logout
    background-image url(../img/app/rwd/icon-logout.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-paiement-co-badging
    background-image url(../img/app/rwd/icon-paiement-co-badging.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-paiement-mastercard
    background-image url(../img/app/rwd/icon-paiement-mastercard.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-paiement-visa
    background-image url(../img/app/rwd/icon-paiement-visa.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-phone
    background-image url(../img/app/rwd/icon-phone.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-shipping-colissimo
    background-image url(../img/app/rwd/icon-shipping-colissimo.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-shipping-domicile
    background-image url(../img/app/rwd/icon-shipping-domicile.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-shipping-express
    background-image url(../img/app/rwd/icon-shipping-express.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-shipping-express-mv
    background-image url(../img/app/rwd/icon-shipping-express-mv.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-shipping-relay
    background-image url(../img/app/rwd/icon-shipping-relay.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-panier_moda
    background-image url(../img/app/rwd/icon_panier_moda.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon_search
    background-image url(../img/app/rwd/icon_search.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__line-item-delete
    background-image url(../img/app/rwd/line-item-delete.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__line-item-edit
    background-image url(../img/app/rwd/line-item-edit.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__line-item-edit-done
    background-image url(../img/app/rwd/line-item-edit-done.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__logo
    background-image url(../img/app/rwd/logo.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__logo-mv
    background-image url(../img/app/rwd/logo-mv.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__logo-purewear
    background-image url(../img/app/rwd/logo-purewear.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__more-colors-arrow
    background-image url(../img/app/rwd/more-colors-arrow.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__navigation-close
    background-image url(../img/app/rwd/navigation-close.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__nopic
    background-image url(../img/app/rwd/nopic.jpg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__payment-cb
    background-image url(../img/app/rwd/payment-cb.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__payment-cofidis-dfpay
    background-image url(../img/app/rwd/payment-cofidis-dfpay.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__print
    background-image url(../img/app/rwd/print.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__sans-supplement
    background-image url(../img/app/rwd/sans-supplement.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__slider-next
    background-image url(../img/app/rwd/slider-next.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__slider-prev
    background-image url(../img/app/rwd/slider-prev.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__sprite
    background-image url(../img/app/rwd/sprite.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__trusted_shop
    background-image url(../img/app/rwd/trusted_shop.png)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__checkout-advantage-payment
    background-image url(../img/app/rwd/checkout-advantage-payment.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__checkout-advantage-period
    background-image url(../img/app/rwd/checkout-advantage-period.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__checkout-advantage-returns
    background-image url(../img/app/rwd/checkout-advantage-returns.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-add-wishlist
    background-image url(../img/app/rwd/icon-add-wishlist.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center
  &__icon-add-wishlist-active
    background-image url(../img/app/rwd/icons/wishlist-full.svg)
    background-color unset !important
    background-size: contain
    background-repeat: no-repeat
    width: 50px
    height: 50px
    background-position: center


  /* old icons */

  &__icon-logout-svg
    mask url(../img/app/rwd/icon-logout.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/icon-logout.svg) no-repeat center;
  &__sort
    mask url(../img/app/rwd/sort.svg) no-repeat center;
    -webkit-mask url(../img/app/rwd/sort.svg) no-repeat center;

  &--block
    display block
    position relative

  &--inline-block
    display inline-block
    position relative
    vertical-align top

  &--center
    margin auto

i.addtocart
  &--big
    sprite($icon_addtocart)
    inline-block()
    vertical-align middle
    margin-right 12px
    margin-top -4px

  &--small
    sprite($icon_addtocart_small)
    inline-block()
    vertical-align middle
    margin-right 10px
