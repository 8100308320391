@require 'includes/*'

.sub-navigation__list__flyout
  position absolute
  top 37px
  left 0
  width calc(1170px - 30px)
  z-index 1000
  background-color $witt-white
  display none
  box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.27)
  padding 12px 20px 30px 20px
  margin-top 0

  @media up_to_L
    width 100% !important
    top 80px
    left 300px
    background-color $witt-white
    box-shadow none
    padding 0 0 30px 0
    display none !important

    &.selected
      display block !important
      min-height calc(100vh - 90px)
      margin-top -80px

  &--back
    display flex
    align-items center
    padding-left 10px
    cursor pointer
    @media L_and_up
      display none

  &__inner
    @media up_to_L
      width 300px

  .backLink
    display none

    @media up_to_L
      padding 13px 15px 13px 0
      font-size 16px
      line-height 24px
      display block
      position relative
      cursor pointer

  ul
    list-style-type none
    padding 0
    margin 0

  &--categoryName
    display none

    @media up_to_L
      display block
      margin-bottom 0
      background-color $witt-primary
      color $witt-white
      padding 17px 20px
      font-size 13px
      line-height 16px
      font-weight 400
      position relative
      text-align left

  &--childCategory
    margin-bottom 16px
    text-align left

    @media up_to_L
      position relative
      border-bottom 1px solid $witt-secondary
      margin 0 20px

    a, span
      display inline
      height auto
      padding 0
      color $witt-black-text
      font-weight 700

      @media up_to_L
        display block
        color $witt-neutral-border
        padding 17px 0
        //padding 17px 20px
        //font-size 13px
        //line-height 16px

        &:after
          display none

      &:hover
        text-decoration none

      &.hasChildren
        .arrow
          @media up_to_L
            transition transform 1s
            transform rotate(0deg)
      &.active.hasChildren
        .arrow
          @media up_to_L
            transition transform 1s
            transform rotate(180deg)

    ul
      @media up_to_L
        display none

  &--subCategory
    a, span
      font-weight 400

    @media L_and_up
      margin-bottom 5px

    @media up_to_L
      background-color $witt-white

      a, span
        padding 12px 20px
        color $witt-black-text
        font-size 14px
        line-height 22px
        background-color $witt-white


  .teaserColumns
    color $witt-primary
    min-height 360px

    p
      font-weight bold
      font-size 14px
      line-height 22px
      margin 0

    ul
      margin 0 0 8px 0

      li
        font-weight 400

    a
      color $witt-primary
      padding 0
      font-weight 400

    .promo
      position absolute
      top 3px
      right 10px
