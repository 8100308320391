@require 'includes/*'

//account__addresses is also used in shipping, but looks slightly different.
.account
  &__newsletter
    &__hint
      padding 10px 10px 10px 0
      margin-bottom 10px

    &__mobilephone
      display none
