@require 'includes/*'

.navigation
  background-color $witt-white
  height 37px // related to the top value of .navigation__list__flyout
  width 100%
  overflow hidden
  color $witt-black-text
  margin-top 32px
  border-bottom 1px solid $witt-secondary

  @media up_to_L
    width 300px
    height 100%
    box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.27)
    position fixed
    z-index 9999
    left -320px
    top 0
    transition left 0.4s ease-in
    overflow hidden
    overflow-y auto
    margin-top 0px
    
    &:after
      display block
      content ''
      width 300px
      height 40px
      background linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8561799719887955) 53%, rgba(255,255,255,0) 100%)
      position fixed
      bottom 0px
      z-index 99999

  &__borderBottom
    @media up_to_L
      margin 0 15px
      border-bottom solid 1px $witt-secondary
      

  &__row
    display flex
    flex-wrap wrap
    margin-right -15px
    margin-left -15px

  &__icons-mobile
    @media L_and_up
      display none

  &.active
    left 0

  &__inner
    @media up_to_L
      width 300px //width 100%
      margin-top 80px
      transition all 0.3s ease-in
      position relative
      z-index 5
      background-color white
      //padding-top 90px

      &.selected
        margin-left -300px

  &__itemUnivers
    @media up_to_L
      padding 15px 20px

  &__list
    margin 0
    padding 0
    position relative

    z-index 3
    @media L_and_up
      position absolute

    &__navicon
      width 27px
      margin-right 10px

    &--right
      position relative
      text-align right
      z-index 3
      right 15px

      @media L_and_up
        position absolute

      .sub-navigation__list__flyout
        right 0

        @media L_and_up
          left calc(-100vw + 195px)
        @media XL_and_up
          left calc(-1140px + 150px)

      @media up_to_L
        text-align left
        position unset
        font-weight bold

        .navigation__list__item--mainCategory
          &:before
            content ''
            display block
            width calc(100% - 40px)
            margin-left 20px
            border-top 1px solid $witt-secondary

          a
            font-weight 700
          > a:hover
            color: var(--custom-navigation-hover-color) !important

      li
        text-align left

    &--left
      text-align left

      @media L_and_up
        display flex
        column-gap 16px

      @media up_to_L
        border-top 2px solid #eff0f0

    &--rest
      @media up_to_L
        padding 0 20px

        .navigation__list__item--mobileMetanav
          padding 16px 0

    &__close
      display none

      @media up_to_L
        width inherit
        transition left 0.4s ease-in
        position fixed
        left 0
        display none
        justify-content flex-end
        padding 30px 20px
        z-index 3

        i
          cursor pointer
          
        &.active
          display flex

    &__item
      text-align center
      inline-block()

      &-text
        @media L_and_up
          padding-bottom 8px
          &.is-active
            padding-bottom 6px
            border 2px solid
            border-image-source radial-gradient(71.31% 395.12% at 91.25% 32.93%, #E4003C 0%, #E31C5F 35%, #D70466 70.5%, #BE2766 100%)
            border-image-slice 1
            border-top 0
            border-left 0
            border-right 0


      @media L_and_up
        border-bottom none

      &--front
        display flex
        align-items center

        a
          padding 15px 0

      &--border
        border-bottom 2px solid $witt-secondary

      &--mainCategory
        z-index 999
        list-style-type none

        @media up_to_L
          width 100%
        
        &:hover
          text-decoration none
          > span, > a
            text-decoration none
            color $witt-darked-grey

            @media L_and_up
              color $witt-black
              background #EFF0F0
              border-radius 4px

        &:last-child
          @media up_to_L
            &:after
              border-bottom none

        a:not([href]):not([tabindex])
          color $witt-black
        a, span
          color $witt-black

          &.navigation__itemUnivers
            @media L_and_up
              font-size 16px
              line-height 20.8px
              display block
              padding 8px 16px 0

          @media up_to_L
            font-size 14px
            line-height 16px
            display flex
            justify-content space-between
            align-items center

            .arrow
              position absolute
              top 0px
              right 0px
              margin 15px 0

        a:hover
          color: var(--custom-navigation-hover-color, $witt-black) !important

      &--mobileMetanav
        display none

        @media up_to_L
          display flex
          align-items center
          justify-content space-between
          width 100%
          background-color $witt-white
          background-repeat no-repeat
          padding 0 20px

          a
            display block
            color $witt-neutral-border
            position relative

            &:hover
              text-decoration none

    .mobile-compte-icon
      &--logged-in
        @media up_to_L
          &:before
            content ''
            width 15px
            height 15px
            border-radius 50%
            display block
            background-color $witt-secondary
            position absolute
            top 12px
            left -20px
            background-image url(../img/app/rwd/check.svg)
            background-position center center
            background-repeat no-repeat
            background-size 11px auto

  &__close
    display none

    @media screen and (max-width: $large)
      position absolute
      right 20px
      bottom 15px
      padding-left 18px
      background-image url(../img/app/rwd/navigation-close.png)
      background-repeat no-repeat
      color $witt-darked-grey
      font-size 13px
      cursor pointer

.darkOverlay
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.7)
  z-index 999
  display none

  &.active
    display block


.flyout
  &__header
    height 36px
    font-weight 700
    margin-top -5px
    margin-bottom 10px
    font-style bold

    &--teaser
      width 48%

.js-link
  cursor pointer