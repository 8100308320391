@require 'includes/*'

//account__addresses is also used in shipping, but looks slightly different.
.shipping
  .account__addresses__box
    margin 0 0 30px 0
    position relative
    padding 20px !important
    min-height 130px !important

  .account__addresses__box-button
    position absolute
    bottom auto !important
    top 50%
    right 15px
    left auto !important
    transform translateY(-50%)
    width auto !important

  .account__addresses__edit-form
    margin-left 26px
    margin-bottom: 0 !important

  .account__addresses__box__selection
    top 30px