@require 'includes/*'

.gift-popup
  &__product
    &__summary
      padding 24px 24px
      width 52%
      display flex
      flex-direction column

      @media screen and (max-width: $medium)
        width 100%
        margin-top -56px
        padding 16px 8px
        margin-bottom 0
        background-color white

      &__mobile-image
        display none

        @media screen and (max-width: $medium)
          min-width 70px
          max-width 100px
          margin-right 16px
          display inline-block
          vertical-align top

      &__inner
        max-height calc(100vh - 200px)
        margin 0 -10px
        padding 0 10px

        &-scrollcontianer
          @media screen and (max-width: $medium)
            overflow-y auto
            max-height calc(100vh - 200px)

          @media screen and (min-width: $medium)
            display flex
            flex-direction column
            justify-content space-between
            height 100%

      &__direct-infos
        @media screen and (max-width: $medium)
          display flex
          flex-direction row
          margin-bottom 10px

      &__top-information
        @media screen and (max-width: $medium)
          display flex
          flex-direction column
          justify-content space-between
          width calc(100% - 100px)

    &__selection
      margin-top 20px

      @media screen and (max-width: $medium)
        padding 0 5px

  &__hidden
    height 0 !important
    overflow hidden
    transition: height 0.3s ease;

  &__rotate
    transform rotate(180deg)

  &__description__toggle
    display flex
    align-items center
    cursor pointer

    &--text
      font-weight bold
      text-decoration underline
      font-size 16px

    &--icon
      background-image url(../img/app/rwd/arrow-down.svg)
      width 24px
      height 24px

  &__description--text
    height fit-content
    font-size 16px

  &__cta-text
    font-size 14px
    font-weight 700

    &--big
      font-size 24px
      line-height 38.4px

      @media M_and_up
        font-size 32px

  .product__summary
    margin-top 0

    .product__title
      margin-top 0

  .product__summary__inner
    scroll-behavior smooth
    padding-top 24px
    @media up_to_M
      max-height calc(100vh - 200px)
      overflow-y auto
      // bit of a hack to prevent the box shadow of the dropdown
      // from being cut off on the left and right
      margin 0 -10px
      padding 0 10px

  &__gift-code-container
    margin 12px 16px
    text-align center

  &__main-image--mobile
      @media M_and_up
        display none

      .productImage
        max-height none

  &__no-gift-button
    padding-top 18px
    text-align center
    text-decoration underline
    cursor pointer

    @media M_and_up
      margin 12px 16px

  .color-selection
    height unset

    &__list
      opacity 0
      height auto

  .product__selection__add-to-basket
    display flex !important
    align-items center
    justify-content center

  .product__selection__buttons
    display block

.gift-popup #main-images
    margin-top 0