@require 'includes/*'

button
  &.btn, &.btn--x-small, &.btn--medium
    -webkit-appearance none !important
    border none
    background none
    color inherit

.btn, button.btn
  display inline-flex
  justify-content center
  align-items center
  vertical-align middle
  padding-left 24px
  padding-right 24px
  cursor pointer
  white-space normal
  border-radius 40px
  background-repeat no-repeat
  width fit-content
  height 41px
  font-size 16px
  transition color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out

  &:disabled
    cursor default
    text-decoration none

  &__icon
    width 22px
    height 22px
    background-position center right
    background-size 20px auto
    background-repeat no-repeat
    vertical-align middle
    display inline-block

    &--reverse
      flex-direction row-reverse

  &__with-icon
    display inline-flex
    justify-content space-between
    align-items center

  &__with-icon--hellineonly
    justify-content center

  &:hover
    & .icon__arrow-right, .icon__arrow-down, .icon__arrow-up, .icon__arrow-left
      background-color $witt-black

  &-link
    &__primary
      color $witt-black-text !important //collides with bootstrap
      text-decoration underline !important

      &:hover
        color $witt-primary

      &:active, &:focus
        text-decoration none !important

    &__secondary
      color $witt-secondary-text !important
      text-decoration underline !important

      &:hover
        color $witt-black-text

      &:active, &:focus
        text-decoration none !important

  &-primary
    background-color $witt-primary
    border 2px solid $witt-primary
    color $witt-inverted-text
    font-weight bold
    i
      background-color $witt-inverted-text !important

    &:not([disabled])
      &:hover, &:focus, &:active
        & .icon__arrow-right, .icon__arrow-down, .icon__arrow-up, .icon__arrow-left, .icon__size--XS
          background-color $witt-primary
        i
          background-color  $witt-primary !important

    &:hover, &-hover //the &-hover is for the styleguide, as hover can not be simulated.
      background-color $witt-white
      border 2px solid $witt-primary
      color $witt-primary-text
      text-decoration none
      box-shadow none !important

    &:not([disabled]):active, &-active, &:focus-visible
      background-color $witt-white
      border 2px solid $witt-primary
      color $witt-primary-text
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset

    &:active:not(:focus-visible):not(:disabled):not(.disabled):active
      background-color $witt-white
      border 2px solid $witt-primary
      color $witt-primary-text
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset


    &:focus:not(:focus-visible)
      background-color $witt-white
      color $witt-primary-text
      outline none !important
      border 2px solid $witt-primary
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

    &:focus, &-focus
      background-color $witt-white
      border 2px solid $witt-primary
      color $witt-primary-text
      outline none !important
      box-shadow 0 0 0 2.5px $witt-white, 0 0 0 4px $witt-info !important

    &:disabled, &-softdisabled, &-disabled
      background-color $witt-secondary
      border-color $witt-secondary
      color $witt-secondary-hover
      cursor unset
      &:hover
        background-color $witt-secondary !important
        border-color $witt-secondary !important
        color $witt-secondary-hover !important

    &--circle-with-icon
      border-radius 50%
      width 36px
      height 36px
      background-color $witt-primary
      border 2px solid $witt-primary
      color $witt-inverted-text
      display inline-flex
      align-items center
      justify-content center
      cursor pointer
      vertical-align middle
      -webkit-appearance none

      i
        background-color $witt-white

      &:hover, &-hover //the &-hover is for the styleguide, as hover can not be simulated.
        background-color $witt-white
        text-decoration none

        i
          background-color $witt-primary !important

      &:active:not(:focus-visible):not(:disabled):not(.disabled):active
        background-color $witt-white
        box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset

        i
          background-color $witt-primary !important

      &:focus:not(:focus-visible)
        background-color $witt-white
        outline none
        box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

        i
          background-color $witt-primary

      &:focus, &-focus
        background-color $witt-white
        outline none
        box-shadow 0 0 0 2.5px $witt-white, 0 0 0 4px $witt-info !important

        i
          background-color $witt-primary !important

  &-secondary
    background-color $witt-secondary
    border-color $witt-secondary
    color $witt-black-text

    &:hover, &-hover
      background-color $witt-secondary-hover
      border-color $witt-secondary-hover
      color $witt-black-text
      text-decoration none

    &:active, &-active
      background-color $witt-secondary-hover !important
      border-color $witt-secondary-hover !important
      color $witt-black-text !important
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

    &:focus:not(:focus-visible)
      background-color $witt-secondary-hover
      border-color $witt-secondary-hover
      color $witt-black-text
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

    &:focus, &-focus
      background-color $witt-secondary-hover
      border-color $witt-secondary-hover
      color $witt-black-text
      outline none
      box-shadow: 0 0 0 2.5px #fff,0 0 0 4px $witt-info !important;

    &:disabled, &-softdisabled, &-disabled
      background-color $witt-secondary
      border-color $witt-secondary
      color $witt-secondary-text
      cursor unset
      &:hover
        background-color $witt-secondary
        border-color $witt-secondary
        color $witt-secondary-text

    &--circle-with-icon
      border-radius 50%
      width 36px
      height 36px
      background-color $witt-secondary
      color $witt-black-text
      display inline-flex
      align-items center
      justify-content center
      cursor pointer
      vertical-align middle
      -webkit-appearance none
      border none

      i
        background-color $witt-black-text

      &:hover, &-hover //the &-hover is for the styleguide, as hover can not be simulated.
        background-color $witt-secondary-hover
        color $witt-black-text
        text-decoration none

        & i
          background-color $witt-black-text

      &:active:not(:focus):not(:focus-visible):not(:disabled):not(.disabled):active, &-active
        background-color $witt-secondary-hover !important
        color $witt-black-text !important
        box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

      &:focus:not(:focus-visible)
        background-color $witt-secondary-hover
        outline none
        box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

        i
          background-color $witt-black

      &:focus, &-focus
        background-color $witt-secondary-hover
        color $witt-black-text
        outline none
        box-shadow 0 0 0 2.5px $witt-white, 0 0 0 4px $witt-info !important

        i
          background-color $witt-black

  &-witt-tertiary-helline-secondary //can be deleted after splitt
    background-color $witt-black
    color $witt-inverted-text !important
    border 2px solid $witt-black
    display inline-flex
    justify-content center

    &:hover, &-hover
      background-color $witt-white
      color $witt-black-text !important
      border 2px solid $witt-black
      text-decoration none

    &:active, &-active
      background-color $witt-white
      border 2px solid $witt-black
      color $witt-black-text
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset

    &:focus, &-focus
      outline none
      background-color $witt-white
      border-color $witt-primary
      color $witt-black-text
      border 2px solid $witt-black
      box-shadow 0 0 0 2px $witt-white, 0 0 0 4px $witt-info !important;

    &:disabled, &-softdisabled, &-disabled
      background-color $witt-secondary-hover
      border-color $witt-secondary-hover
      color $witt-secondary-text


  &-tertiary
    background-color $witt-black
    color $witt-inverted-text
    border 2px solid $witt-black
    display inline-flex
    justify-content center
    width fit-content
    & i
      background-color $witt-white

    &:hover, &-hover
      background-color $witt-white
      color $witt-black-text
      border 2px solid $witt-black
      text-decoration none
      & i
        background-color $witt-black

    &:active, &-active
      background-color $witt-white
      border 2px solid $witt-black
      color $witt-black-text
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset
      & i
        background-color $witt-black

    &:focus:not(:focus-visible)
      background-color $witt-white
      border-color $witt-black
      box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

      & i
        background-color $witt-black

    &:focus, &-focus
      background-color $witt-white
      color $witt-black-text
      border-color $witt-black
      outline none
      box-shadow 0 0 0 2.5px $witt-white, 0 0 0 4px $witt-info !important;
      & i
        background-color $witt-black

    &:disabled, &-softdisabled, &-disabled
      background-color $witt-secondary
      border-color $witt-secondary
      color $witt-secondary-text
      &:hover
        background-color $witt-secondary
        border-color $witt-secondary
        color $witt-secondary-text

    &--circle-with-icon
      border-radius 50%
      width 36px
      height 36px
      background-color $witt-black
      border 2px solid $witt-black
      color $witt-white
      display inline-flex
      align-items center
      justify-content center
      cursor pointer
      vertical-align middle
      -webkit-appearance none

      & i
        background-color $witt-white

      &:hover, &-hover //the &-hover is for the styleguide, as hover can not be simulated.
        background-color $witt-white
        border 2px solid $witt-black
        color $witt-black
        text-decoration none

        & i
          background-color $witt-black

      &:active:not(:focus):not(:focus-visible):not(:disabled):not(.disabled):active, &-active
        background-color $witt-secondary
        box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset

        & i
          background-color $witt-black

      &:focus:not(:focus-visible)
        background-color $witt-white
        outline none
        box-shadow 0 4px 4px 0 rgba(19, 1, 3, 0.24) inset !important

        & i
          background-color $witt-black

      &:focus, &-focus
        background-color $witt-white
        color $witt-black-text
        outline none
        box-shadow 0 0 0 2.5px $witt-white, 0 0 0 4px $witt-info !important

        i
          background-color $witt-black

  &--x-small
    font-size 14px
    line-height 18.2px
    padding 8px 16px
    height 34px
    display inline-flex
    justify-content center
    align-items center
    cursor pointer
    white-space normal
    border-radius 40px
    width fit-content
    transition color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out

  &--small //default if none is set (cause same as .btn)
    font-size 16px
    line-height 20.8px
    padding 10px 24px
    height 41px

  &--medium
    font-size 16px
    line-height 20.8px
    padding 8px 24px
    height 53px
    display inline-flex
    justify-content center
    align-items center
    cursor pointer
    white-space normal
    border-radius 40px
    width fit-content
    transition color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out

  &-full-width
    width 100%

  &-addwishlist // needed for all whishlist icon stylings to get animation (PDP, slider Overview)
    background-color transparent
    border-color transparent
    color $witt-black
    text-transform none
    font-size 16px
    cursor pointer

    &:hover
      .icon__wishlist

        animation: zoomInOut 0.4s ease-in-out;

    &:active, &.active:focus, &.active

      .icon__wishlist
        background-image url(../img/app/rwd/icons/wishlist-full.svg)
        background-color unset !important


    @keyframes zoomInOut
      0%
        transform: scale(1);

      50%
          transform: scale(1.2); /* Verkleinern um 20% nach 0.2s */

      100%
          transform: scale(1); /* Zurück zur Normalgröße nach 0.4s */

  &-address-modification-checkout
    border 2px solid $witt-primary