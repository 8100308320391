@require 'includes/*'

.basket
  width 100%

  &__top
    display flex
    align-items center
    margin-top 40px
    margin-bottom 10px

    @media screen and (max-width: $medium)
      flex-wrap wrap

  &__advantages
    display flex
    padding 15px

    @media screen and (max-width: $medium)
      margin-top 20px
      flex-wrap wrap

    &__entry
      padding-right 25px
      padding-left 25px
      border-right 1px solid $witt-secondary-text
      flex-grow 1
      display flex

      @media screen and (max-width: $medium)
        border none
        padding-left 0
        padding-right 0
        margin-bottom 5px
        width 100%

      &:first-child
        padding-left 0

      &:last-child
        padding-right 0
        border-right none

        @media screen and (max-width: $medium)
          margin-bottom 0

      &__icon
        width 20px
        height 20px
        background-size contain
        margin-right 10px

        @media screen and (max-width: $medium)
          width 15px
          height 15px

  &__headline
    font-size 28px
    line-height 38px
    color $witt-black-text
    margin-left 16px

  &__empty
    border-top 1px solid $witt-secondary
    border-bottom 1px solid $witt-secondary
    padding 40px 0
    text-align center

    &__icon
      display none

    &__headline
      font-size 26px
      font-weight 700
      line-height 36px

    &__cta
      padding 30px

      &__text
        font-size 18px
        line-height 22px
        font-weight 700
        margin-bottom 20px

      &__button
        display flex
        justify-content center

  .line-items

    &__head
      border-bottom 1px solid $witt-black

    &__headline
      font-size 20px
      font-weight bold
      color $witt-black-text
      padding-bottom 8px
      padding-left 10px

      span
        margin-left 5px

      &--no-border
        border none

      &--smaller
        font-size 14px
        line-height 22px
        display block
        border none
        padding-left 0
        margin 20px 0

      span
        font-size 16px
        line-height 16px
        font-weight normal
        color $witt-black

    &__legend
      color $witt-black
      font-size 12px

  .line-item
    position relative
    border-bottom 1px solid $witt-default-border
    padding 30px 30px 30px 0

    &:after
      content ''
      display block
      width 1px
      height 1px
      clear both

    @media screen and (max-width: $medium)
      padding 20px 10px
      display flex
      flex-wrap wrap

    &:first-child
      border-top 1px solid $witt-default-border

    &__picture
      margin-right 20px
      float left
      width 93px
      min-height 100px

      img
        width 93px
        height auto
        display block
        border 1px solid $witt-default-border

        @media screen and (max-width: $medium)
          width 100%

    &--wishlist-icon
      min-width 24px

    &__top
      float left
      width calc(100% - 93px - 20px)
      display flex
      justify-content space-between

    &__headline
      font-size 16px
      line-height 21px

    &__ref
      font-size 14px
      color #6c7275

    &__bottom
      float left
      width calc(100% - 93px - 20px)
      display flex
      justify-content space-between

      @media screen and (max-width: $medium)
        width 100%

    &__attributes
      font-size 12px
      line-height 1em

      &__attribute
        margin-bottom 2px
        height 18px
        display flex
        align-items center

        &--quantity
          display flex
          align-items center

        &-selected
          font-weight bold
          color $witt-black

      &__label
        width 60px

      &__value
        color $witt-black
        font-weight bold

      &__availibility
        &--green
          color $availability-green
        &--orange
          color $availability-orange
        &--red
          color $availability-red

      .gift__attributes
        margin-top 8px

    &__prices
      text-align right

    &__container
      position relative

      &__pricebox_desktop
        position absolute
        width 100%
        bottom 0

    &__row
      margin-top 10px
      width 100%
      height 95px

      @media screen and (max-width: $large)
        height 70px

      &--second
        width 100%
        height 30px

      &--labels
        display none
        text-align center

        .line-item__column--four
          text-align right
          padding-right 15px

    &__quantity
      font-weight 500

    &__modify, &__delete
      margin-right 24px

      @media screen and (max-width: $small)
        margin-right 15px
      @media screen and (max-width: $supersmall)
        grid-column 1

      a
        color $witt-neutral-border

        &:hover
          text-decoration underline

    &__link
      display flex
      align-items center

    &__delete
      margin-right 24px

      @media screen and (max-width: $small)
        margin-right 15px

      @media screen and (max-width: $supersmall)
        grid-column 2

      a
        color $witt-neutral-border

        &:hover
          text-decoration underline

    &__wishlist

      a
        color $witt-neutral-border

        &:hover
          text-decoration underline

      @media screen and (max-width: $supersmall)
        grid-column-start: 1;
        grid-column-end: 3;


    &__price
      &--reduction
        margin-bottom: 2px

    &__price-reduction
      display inline-block
      padding 4px
      border-radius 4px
      background-color $witt-brand-color
      margin-left 5px
      vertical-align middle
      font-size 14px
      line-height 14px
      color $witt-white
      flex-grow 0

      &--percent
        background-color  $witt-white
        color $witt-brand-color

      &--promotion
        background-color $witt-brand-color
        color $witt-white

    &__old-price
      font-size 14px
      line-height 14px
      font-weight 700
      color $witt-neutral-border
      text-decoration line-through
      display inline-block
      vertical-align middle

    &__unit-price
      font-size 12px
      color $witt-neutral-border
      font-weight 700

    &__price
      font-size 16px
      line-height 16px
      font-weight 700
      color $witt-brand-color
      text-transform uppercase
      text-align right

      @media screen and (max-width: $medium)
        font-size 14px
        line-height 14px

    &__eco-tax
      font-weight 700
      line-height 1.2em
      padding-top 2px

      @media screen and (max-width: $medium)
        padding-left 20px

    &__price-reduction
      display inline-block
      color $witt-white
      padding 4px
      font-weight 700
      background-color $witt-brand-color
      font-size 12px
      line-height 12px

      &--percent
        background-color $witt-brand-color

      &--promotion
        background-color $witt-brand-color

      @media screen and (max-width: $medium)
        align-self flex-start

    &__avalibility
      &--mobile
        display none
        width 91px
        text-align center

        @media screen and (max-width: $large)
          display block

    &__duplicate-line-item
      display inline-block
      margin-left 4px
      vertical-align middle
      cursor pointer
      height 16px

      img
        height 100%

    &__duplicate-link
      width 16px
      height 16px

    &__tax
      font-weight 700
      color $witt-black-text

    &__action
      display flex
      padding-top 8px
      width calc(100% - 113px)
      margin-left 113px

      @media screen and (max-width: $small)
        width 100%
        margin-left 0

      @media screen and (max-width: $supersmall)
        display grid

  &__promo-code
    display flex
    justify-content center
    align-items center
    padding 20px
    background-color $witt-secondary
    margin 20px 0
    flex-wrap wrap

    @media screen and (max-width: $medium)
      justify-content flex-start

    &__icon
      width 45px
      height 45px
      margin-right 15px
      background-image url(/img/app/rwd/icons/basket.svg)
      background-size contain
      background-position center center
      background-repeat no-repeat
      flex-shrink 0
      align-self center

    &__text
      font-size 14px
      margin-right 20px
      color $witt-black-text

    &__input
      width 240px

      form
        width 100%
        display flex

        .forms__input--rounded
          width 130px

      @media screen and (max-width: $medium)
        margin-top 10px

    &__feedback
      width 100%
      display flex
      justify-content space-between
      padding 0 10px

    &__info
      display flex
      align-items center
      flex-grow 1
      flex-wrap wrap

      &__icon
        margin-right 10px
        width 24px
        height 24px
        background-image url(/img/app/rwd/check-circle.svg)
        background-size contain
        background-position center center
        background-repeat no-repeat
        flex-shrink 0
        align-self flex-start
        margin-top 3px

        @media screen and (max-width: $medium)
          flex-basis 24px

      &__text
        font-size 22px
        line-height 1.2em
        color $witt-black-text

        @media screen and (max-width: $medium)
          flex-basis calc(100% - 38px)

        span
          color $witt-primary
          font-weight 700

      &__description
        font-size 16px
        margin-top 5px
        font-weight 700
        color $witt-black-text
        flex-basis 100%

        @media screen and (max-width: $medium)
          margin-left 34px

      &__description
        width 100%

    &__delete
      width 18px
      height 18px
      flex-shrink 0

      a
        background-image url(/img/app/rwd/icon-line-item-delete.svg)
        background-size contain
        background-repeat no-repeat
        display block
        width 100%
        height 100%

  &__teaser
    @media screen and (max-width: $medium)
      margin-bottom 20px


  .direct-order
    background-color $witt-secondary
    padding 10px 10px 20px 10px
    margin 10px 0 20px 0
    position relative

    @media screen and (max-width: $medium)
      background-color $witt-secondary

    &__headline
      font-size 17px
      line-height 21px
      padding-right 25px
      font-weight bold
      color $witt-neutral-border
      margin-bottom 30px
      display flex
      align-items center

      &__inner
        inline-block()
        width calc(100% - 27px)
        vertical-align top

    &__icon
      display inline-block
      margin-right 10px

    &__row
      margin-top 16px

    &__model
      @media screen and (min-width: $medium)
        max-width 34%

    &__color
      @media screen and (min-width: $medium)
        max-width 20%

    &__size
      @media screen and (min-width: $medium)
        max-width 38%
        flex 0 0 28%

    &__quantity
      @media screen and (min-width: $medium)
        max-width 13%

        input
          min-width 50px

    &__reference

      label
        inline-block()
        font-size 14px
        line-height 14px
        color $witt-black
        margin-bottom 0
        margin-right 10px

        &.bold
          font-weight bold

          @media screen and (max-width: $medium)
            margin-top 0

      .message
        margin-top 10px

      &__input
        width 100%
        inline-block

        form
          display flex
          align-items center

        @media screen and (max-width: $medium)
          width 100%

        input
          width 125px
          min-width 95px
          height 48px
          padding-left 16px

          &::-webkit-input-placeholder
            font-style italic
            color $witt-black-text

          &::-moz-placeholder
            font-style italic
            color $witt-black-text

          &:-ms-input-placeholder
            font-style italic
            color $witt-black-text

          &:-moz-placeholder
            font-style italic
            color $witt-black-text

      &__text
        inline-block()
        margin-top 5px
        font-size 14px
        color $witt-neutral-border

        @media screen and (max-width: $medium)
          display none

    &__product

      &__name
        font-size 17px

        @media screen and (max-width: $medium)
          padding-top 30px
          font-size 14px

      &__data
        margin-top 20px
        width 100%

        @media screen and (max-width: $medium)
          display none

        &__row
          justify-content space-between
          margin 0

      &__details
        inline-block()
        vertical-align top
        width calc(100% - 115px)

      &__picture
        margin-right 20px
        inline-block()
        width 93px
        height 125px
        background-color $witt-white

        img
          max-width 100%
          max-height 100%
          width auto
          height auto
          margin 0 auto

      &__ref
        color $witt-neutral-border
        font-size 12px

      &__availibility
        margin-top 5px
        font-size 12px

      &__order
        height 125px
        margin-top 20px
        text-align center
        float right

        @media screen and (max-width: $large)
          margin-top 25px
          height auto
          float none

        &-inner-bottom
          display table-cell
          height 125px
          vertical-align bottom

          @media screen and (max-width: $large)
            display block
            text-align center
            height auto

      &__price
        font-size 21px
        line-height 21px
        font-weight bold
        color $witt-black
        margin-bottom 20px

        .ecotax
          font-size 12px
          font-weight 400
          color $witt-neutral-border
          margin-bottom 5px

    .icon__direct-order
      sprite($icon_direct_order)
      inline-block()
      vertical-align top
      margin-right 11px

    .line-item
      &__delete
        position absolute
        top 10px
        right 8px
        margin-right -3px

      &__attributes
        &__attribute
          &-selected
            padding-top 8px

    .material
      label
        font-size 12px !important
        padding-left 0

      .select-dropdown:not(input)
        height 135px !important

      input.select-dropdown
        height 56px !important

      .dropdown-content
        top 50px !important

        li
          span
            font-size 12px !important

    &__model, &__size, &__color, &__quantity
      .material
        .dropdown-content
          min-width 50px !important

          span
            padding-left 17px

  .recommendations
    margin-bottom 30px

  .summary

    &__table
      width 100%
      border none
      margin 0 0 25px 0
      padding 0

      tr

        td
          padding 0 5px 20px 0
          vertical-align bottom

          &.right
            text-align right
            padding-right 0
            white-space nowrap

        td.summary__table-cell-premium
          padding-right 0

    &__total

      td
        color $witt-primary
        font-size 24px
        line-height 24px
        padding-top 5px
        padding-bottom 0

        @media screen and (max-width: $large)
          font-size 16px

        &.right
          font-weight bold
          white-space nowrap

    &__savings
      margin-top -30px !important

    &__promocode

      form
        position relative

        .btn
          position absolute
          right 0
          top 0
          height 39px

        .error
          display block
          margin-top 15px

      &__headline
        font-weight bold
        padding-right 40px
        margin-bottom 18px

      &__code
        inline-block()
        background-color $witt-info
        color $witt-white
        padding 12px 8px
        font-weight normal
        margin-left 10px

      &.error
        display block
        padding-top 10px

      &__info
        font-size 12px
        margin-top 15px

      &--active
        background-color $witt-white
        padding 0 0 20px 0
        position relative

      &__delete
        position absolute
        top 8px
        right 15px

      &__input
        width calc(100% - 145px)
        margin-right 10px

        padding 10px 12px
        border 1px solid $witt-default-border

        &:focus::placeholder
          color transparent


    .question
      border none
      background-color transparent
      inline-block()
      sprite($icon_question)
      margin-left 8px
      cursor pointer

      &:focus
        outline none

  &__contact-info
    background-color $witt-secondary
    padding 8px 16px
    font-size 14px

    &__container
      padding 0 20px
      position relative
      margin-bottom 20px

      @media screen and (max-width: $medium)
        padding 0 10px

    &__headline
      font-weight bold
      font-size 16px

    &__phone
      margin-top 10px

    &__phone-icon
      display inline-block
      vertical-align top
      width 28px
      height 28px
      margin-right 10px
      background-position center center
      background-size contain
      background-image url(../img/app/rwd/icon-phone.png)

    &__phone-text
      display inline-block
      vertical-align top
      span
        display block
        font-size 12px

    &__phone-image
      background-image url(/img/app/rwd/sprite.png)
      background-position 0 -25px
      width 189px
      height 24px
      display block

    &__mail
      margin-top 10px
      vertical-align top

    &__mail-icon
      display inline-block
      vertical-align top
      width 22px
      height 22px
      margin-right 10px
      background-position center center
      background-size contain
      background-image url(../img/app/rwd/icon-envelope.png)

    &__mail-text
      display inline-block

  &__buttons
    @media screen and (max-width: $medium)
      padding 0 10px

    &--mobile
      position fixed
      bottom 0
      display none
      width 100%
      z-index 1040
      left 0

      a
        border-radius 0

      @media screen and (max-width: $medium)
        display block

  .btn__checkout

    .icon
      margin-top 11px
      margin-left 20px

  .btn__continue
    .icon
      margin-right 20px
      margin-top 8px

  &__delete-confirm
    position absolute
    top 0
    bottom 0
    left 0
    right 0
    background-color $witt-white
    z-index 1
    display none

    &__popup
      padding 30px 40px
      font-size 18px
      text-align center

      &__text
        font-weight 700

      &__buttons
        margin-top 20px
        display flex
        justify-content center
        column-gap 20px

      &__link
        margin-right 10px

  &__wishlist-confirm
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba($witt-black, 0.4)
    z-index 999

    &__popup
      position absolute
      top 100px
      right 100px
      padding 30px
      background-color $witt-white
      z-index 9999
      box-shadow 0 10px 40px rgba($witt-black, 0.3)
      font-size 18px

      @media screen and (max-width: $medium)
        right 20px
        left 20px
        width calc(100% - 40px)

      &__link
        text-decoration underline
        cursor pointer

      &__close
        width 15px
        height 15px
        cursor pointer
        background-image url(../img/app/rwd/icons/close-x.svg)
        background-size contain
        background-position center center
        background-repeat no-repeat
        position absolute
        top 10px
        right 10px

  &__installment
    margin-top 20px
