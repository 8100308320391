@require 'includes/*'

.le-mag
  .breadcrumb
    background-color transparent
    
    ul
      list-style-type none
      margin 0px
      padding 0px
      
      li
        display inline-block
        
  #jwf_blog_titre
    width 100%
    text-align center
    font-size 45px!important
    color $witt-black-text!important
    margin-top 40px!important
    
  #jwf_blog_menu_cat
    width 100%
    margin 20px auto
    text-align center
    
    li
      @media screen and (max-width: $medium)
        display block
      
    
  #jwf_blog_article_complet
    width 70%
    margin 50px auto 15px auto
    color $witt-black-text
    border-bottom 1px solid $witt-default-border
    
    @media screen and (max-width: $medium)
      width 100%
      
    h1
      font-size 30px!important
      color $witt-black-text!important
      line-height 1em!important
      
    h2
      font-size 16px!important
      color $witt-black-text!important
      font-weight bold!important
      margin-top 0px
      
    p
      font-size 16px!important
      margin 25px 0
    
  #jwf_blog_date_publication
    font-size 16px
    margin 25px 0
    
  .jwf_blog_two_col_right
    max-width 50%
    float left!important
    display block
    margin-left 40px
    
    @media screen and (max-width: $medium)
      margin-left 0px
      width 100%
    
  .jwf_blog_two_col_left
    max-width 50%
    float left!important
    display block
    
    @media screen and (max-width: $medium)
      width 100%
      
  .jwf_blog_article_suite
    background-color $witt-black
    color $witt-white
    font-size 14px
    padding 9px 30px
    text-decoration none
    text-align center
    margin 0 auto
      
          