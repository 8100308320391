.gifts

  @media screen and (max-width: $medium)
    border-right none
    border-left none

  &__headline
    margin 20px 0 10px 0
    font-size 18px
    color $witt-primary
    font-weight bold

.gift-container
  border 1px solid $witt-secondary-text
  padding 0 10px 0 10px
  margin-top 20px

.gift
  width 100%
  position relative
  padding 20px 0 30px 0
  border-bottom 1px solid $witt-secondary-text

  &:last-child
    border none

  @media screen and (max-width: $medium)
    padding-bottom 25px

  .line-item
    &__delete
      @media screen and (max-width: $medium)
        margin-top 0

  &__headline
    font-size 17px
    line-height 19px
    color $witt-black-text
    font-weight bold
    margin-bottom 10px
    @media M_and_up
      margin-bottom 3px

    span
      font-weight normal
      color $witt-black

  &__attributes
    margin-top 10px

    .line-item
      &__description
        display none

        &__headline
          text-decoration underline
          cursor pointer

  &.selectable
    background-color $witt-secondary
    padding-right 10px
    padding-left 10px
    border-top 1px solid $witt-black
    border-bottom none

    &:last-child
      border-bottom 1px solid $witt-black

  .mobile-price
    position absolute
    bottom 0
    right 0

  &__picture
    inline-block()
    vertical-align top
    max-width 100px
    flex 0 0 100px
    padding 0

    img
      max-width 100%
      max-height 100%
      width auto
      height auto

  .bonus-line-item__picture-and-price
    display flex
    justify-content space-between
    width 100%

  &__right-container
    text-align right

  &__addtocart
    margin-top 10px

  .line-item__row
    inline-block()

  &.popup

    .gifts__headline
      color $witt-black
      font-weight 400
      font-size 20px
      line-height 24px
      margin-top -20px
      padding 0 15px 15px 15px
      border-bottom 1px solid $witt-neutral-border

    .gift

      &__container
        inline-block()
        vertical-align top
        margin 0 15px 20px 15px
        width 27%

        @media screen and (max-width: $medium)
          width 100%
          display flex

        .gift__radio
          @media screen and (max-width: $medium)
            padding-top 40px
            order 1
            margin-right 20px

      &__picture
        display block
        vertical-align top
        width 93px
        height 125px
        margin-top 10px

        @media screen and (max-width: $medium)
          order 2

        img
          max-width 100%
          max-height 100%
          width auto
          height auto

        label
          margin 0
          width 100%
          max-width 70px
          height 100%
          cursor pointer

      &__radio
        margin-right 10px
        inline-block()
        vertical-align top

      &__attributes
        inline-block()
        vertical-align top
        width calc(100% - 30px)
        margin-top 0

        @media screen and (max-width: $medium)
          order 3

        select
          margin-left 5px

      &__headline
        font-size 13px
        padding 0
        line-height 15px
        font-weight 700

      &__price
        margin 5px 0
        font-style italic

      &__bottom
        display flex
        justify-content flex-end
        margin-bottom -40px

        @media screen and (max-width: $medium)
          flex-wrap wrap
          justify-content center

      &__no-selection
        margin-right 10px
        align-self center

        @media screen and (max-width: $medium)
          order 2
          width 100%
          margin-top 20px

      &__addtocart
        margin 0 !important

    span
      font-size 20px
      font-weight 400 !important
      color $witt-black-text

