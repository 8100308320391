@require 'includes/*'
.pager
  padding 0

  li
    display inline-block
    list-style none

  &--bottom
    text-align center
    margin-bottom 20px

  &--top
    position absolute
    bottom 14px
    right 10px
    @media M_and_up
      bottom 20px

  &__page--previous,
  &__page--next
    display inline-block
    padding 5px
    vertical-align middle

  &__icon
    &--previous
      background-image url(../img/app/rwd/icons/arrow-left.svg)

    &--next
      background-image url(../img/app/rwd/icons/arrow-right.svg)

  &__page
    display inline-block
    width 30px
    height 30px
    padding-top 5px
    vertical-align middle
    text-align center
    border-radius 50%
    margin 5px
    border 1px solid $witt-secondary

    &:hover
      border 1px solid $witt-secondary-hover
      text-decoration none

    &--current
      background-color $witt-neutral-border
      border 1px solid $witt-neutral-border
      color $witt-white
      cursor default
      pointer-events none

      &:hover
        color $witt-inverted-text
        border 1px solid $witt-black

  &__gap
    border none !important