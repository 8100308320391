@require 'includes/*'

//account__addresses is also used in shipping, but looks slightly different.
.account
  &__navigation

    &__options
      reset-list-style()
      border-top 1px solid $witt-secondary
      @media screen and (max-width: $medium)
        display flex
        flex-wrap wrap
        border none
        gap 8px

    &__pills
      margin 16px 0
      display block
      width 100%
      @media screen and (min-width: $medium)
        display none

      &__slider
        white-space nowrap

        .owl-nav
          display none

        .owl-stage
          width max-content !important

    &__pill
      display inline-block
      border-radius 100px
      padding 8px 16px
      background-color $witt-secondary
      color $witt-black-text !important
      flex-grow 0
      flex-shrink 0

      &:hover,
      &.active
        text-decoration none
        background-color $witt-black
        color $witt-white !important

    &__entries--desktop
      reset-list-style()
      @media screen and (max-width: $medium)
        display none

    &__entry.active
      @media screen and (min-width: $medium)
        background-color $witt-secondary
        font-weight bold
    &__entry
      position relative
      border-bottom 1px solid $witt-secondary
      &:hover,&:active
        background $witt-secondary-hover

      @media up_to_M
        width calc(50% - 4px)
        background $witt-secondary
        color $witt-black-text
        text-align center
        line-height 14px
        display flex
        justify-content center
        align-items center
        position relative
        border-radius 5px

        &:hover,&:active
          background $witt-black

          a
            color $witt-white

      a
        padding 16px 8px 16px 16px
        display block
        &:hover,&:active
          text-decoration none

        @media up_to_M
          padding 23px 19px

      &--pillStyling
        margin-top 0 !important
        height 36px

        &:hover
          .account__navigation__icon
            background-color $witt-white

      &--pillStyling:hover
        height 36px
        background-color #121037

      &__container
        @media up_to_M
          display flex
          flex-wrap wrap
          justify-content center
          margin 3px 0 10px

        &__subnavigation
          display flex
          align-items center

        &--logout
          position relative

          a
            padding 16px 8px 16px 16px
            display flex
            align-items center

            &:hover,&:active
              text-decoration none

          @media up_to_M
            width fit-content
            width -moz-fit-content
            left 50%
            transform translateX(-50%)
            border-radius 100px
            padding unset
            border 1px solid $witt-black
            margin-top 10px

            a
              padding unset

            &:hover
              background-color $witt-black

              .account__navigation__icon
                background-color $witt-white

          &:hover,&:active
            @media screen and (min-width: $medium)
              background $witt-secondary-hover
            @media screen and (max-width: $medium)
              .account__navigation__headline
                background $witt-black
                border-radius: 100px
                color  $witt-white

          .account__navigation__icon
            margin-right 5px
            @media screen and (max-width: $medium)
              margin-left 5px

          .account__navigation__headline
            color $witt-black-text
            text-decoration none

            @media screen and (max-width: $medium)
              color $witt-black-text
              line-height 16px
              padding 16px 8px 16px 0
              text-align center
              border-radius 100px
              height 20px
              display flex
              align-items center

              &:before
                margin-right 10px
