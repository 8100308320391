@require 'includes/*'

.checkout
  &__advantages
    margin 10px
    font-size 14px
    
    ul
      list-style-type none
      margin 0
      padding 0
      
      li
        display flex
        justify-content flex-start
        align-items center
        padding 12px 0
        border-bottom 1px solid $witt-secondary
        
        &:last-child
          border-bottom none

    &__icon
      width 35px
      height 35px
      margin-right 10px
      flex-shrink 0
      background-size contain
      background-repeat no-repeat
      background-position center center
      
      &--payment
        background-image url(../img/app/rwd/checkout-advantage-payment.svg)
        
      &--period
        background-image url(../img/app/rwd/checkout-advantage-period.svg)
        
      &--returns
        background-image url(../img/app/rwd/checkout-advantage-returns.svg)

    &__text
      span
        font-size 12px
        display block

  &-shipping
    &__date
      font-size 16px
      line-height 20.8px

    &__address
      padding 16px
      border-radius 8px
      background-color $helline-grey-light2

      .summary-data--address &
        margin-top 20px

      &-name
        font-size 16px
        font-weight 800
        line-height 20.8px

      &-detail
        font-size 14px
        line-height 18.2px

    &__open-panel
      reset-button-style()
      font-size 16px
      line-height 20.8px
      margin-top 16px
      text-align left
      text-decoration underline

      &:hover, &:focus
        text-decoration none

  &-relais-detail
    &__headline
      margin-bottom 8px
      display flex
      align-items center
      column-gap 8px

    &__address
      font-size 14px
      line-height 18.2px
      margin-bottom 24px

    &__openingHours
      background-color $helline-grey-light2
      padding 24px 16px
      margin 0 -16px // out of the modal

      @media M_and_up
        margin 0 -40px // out of the modal
        padding 24px 40px

      &-table-wrap
        background-color white
        padding 16px 16px 8px
        line-height 18.2px
        font-size 14px

        table
          max-width 330px
          .panelModalRight--relais-info &
            width 100%

          td
            padding-bottom 8px

      &-headline
        margin-bottom 8px
        display flex
        align-items center
        column-gap 8px

      &-day
        text-transform capitalize
        font-weight 800
        padding-right 12px
        @media up_to_S
          padding-right 6px

      &-hour
        padding-right 10px
        @media up_to_S
          padding-right 4px

  .summary-data
    padding 20px
    box-shadow 0 8px 20px rgba(0, 0, 0, 0.2)
    background-color $witt-white
    font-size 14px
    margin 96px 0 0

    @media screen and (max-width: $medium)
      margin 0px -15px
      box-shadow none

    &--address
      margin-top 16px
      @media screen and (max-width: $medium)
        margin-top 0
        padding-top 0

    &--basket
      margin 0
      position relative
      z-index 1
      @media screen and (max-width: $medium)
        padding-left 0
        padding-right 0

    &__title
      margin-right 4px

    &__price
      position relative
      width 100%
      min-height 1px

    #btnCheckout
      margin-top 25px
      justify-content center

    &__mobile-header
      @media screen and (max-width: $medium)
        display flex
        flex-wrap wrap
        justify-content space-between
        align-items center

    &__big-headline
      font-size 20px
      color $witt-black-text
      padding-bottom 4px
      font-weight bold
      
      @media screen and (max-width: $medium)
        margin-bottom 0
        width 65%

    &__block
      padding-bottom 20px
      margin-bottom 10px
      border-bottom 1px solid $witt-default-border

      &:last-child
        margin-bottom 0
        padding-bottom 0
        border-bottom none

      &--no-border
        margin-bottom 0
        border none

      &-small-padding
        padding-bottom 8px !important

      &--articles
        padding-left 5px

      &__total
        line-height 31px
        font-size 24px
        padding-top 10px
        padding-bottom 10px
        display flex
        @media up_to_M
          padding 16px

        &__mobile-reminder
          display none
          @media up_to_M
            display flex
            margin-top 10px
            margin-left 0
            margin-right 0
            color #e90037
            font-weight 700
            justify-content space-between

    &__headline
      color $witt-neutral-border
      padding-bottom 10px

      &--bold
        font-weight 700

      &--no-padding
        padding-bottom 0

      .icon__premium-flag
        margin-left auto

    &__headline-small
      font-size 14px
      line-height 16px
      font-weight bold
      color $witt-neutral-border

    &__content
      font-size 14px
      line-height 16px
      flex-direction column

    &__content-small
      font-size 12px

      &__availability
        &--green
          color $availability-green
        &--orange
          color $availability-orange
        &--red
          color $availability-red

    &__price-small
      color $witt-black-text

    &__open-link
      font-size 14px
      text-decoration underline
      margin-bottom 10px
      cursor pointer

      &.active
        .summary-data__open-link__arrow
          transform scale(0.7)rotate(180deg)

    &__articles-container
      display none

    &__articles
      margin-top 8px
      padding-left 8px
      border-left 1px solid $witt-light-grey3

      &--premium
        border-left 1px solid $helline-premium

    &__article

      .summary-data__block
        border none
        padding-bottom 0

      .align-bottom
        margin-bottom 20px
        
    &__old-BAS-Price
      display inline-block
      margin-right 15px
      font-weight 400
      text-decoration line-through

    &__extracosts

      &-wrapper
        &--dom
          padding-left 8px
          padding-right 8px

      &-dom
        background-color $color-info-background-warning
        padding-top 4px
        padding-bottom 4px
        margin-left -8px
        margin-right -8px

        .summary-data__headline
          padding-bottom 0

          &-wrap
            padding-left 8px
            padding-right 8px

  .summary

    &__table
      font-size 14px

      &-premium-flag
        .icon__premium-flag
          margin-left auto

    &__savings
      display inline-block
      float right
      padding 3px 10px
      color white
      text-align right
      font-size 16px
      background-color $witt-brand-color
      margin-top -4px
      position relative

      &:before
        content ''
        display block
        width 0
        height 0
        position absolute
        border-left 5px solid transparent
        border-right 5px solid transparent
        border-bottom 5px solid $witt-brand-color
        top 0
        margin-top -5px
        right 12px
        z-index 1

    &__installment
      margin-top 20px
      padding-top 20px
      border-top 1px solid $witt-default-border

.premium-subscription
  display flex
  flex-direction column
  row-gap 16px
  border-bottom 1px solid $border-color-secondary
  padding-bottom 16px
  margin-top 6px
  margin-bottom 16px

  &--dom
    margin-top 0

  &__background
    z-index -5
    opacity 0.8
    position absolute
    right -80px
    top -30px
    transform rotate(24deg)
    height 120px
    width 240px

  &__wrapper
    z-index 0
    position relative
    overflow hidden
    border 1px solid $helline-premium
    border-radius 4px
    padding 16px
    display flex
    flex-direction column
    row-gap 8px

  &__icons-wrap
    display flex
    justify-content space-between
    align-items center

  &__intro
    font-size 16px
    line-height 20.8px

  &__hint
    padding 8px 16px
    font-size 14px
    background-color #FCEEF5
    display flex
    column-gap 8px

    &-icon
      flex 0 0 auto

    &-text
      align-self center

  &__link
    reset-button-style()
    font-size 14px
    line-height 18.2px
    text-align left
    text-decoration underline

    &:hover, &:focus, &:active
      text-decoration none
      outline none

    &--hide
      display none

      .subscription--selected &
        display block

  &__selection
    display flex
    align-items center
    justify-content space-between
    margin 0
    &-wrap
      border 1px solid $helline-premium
      padding 8px
      border-radius 4px

      .premium-subscription--extend &
        display none

    &-seperator
      border-bottom 1px solid $helline-premium
      margin-bottom 10px
      padding-bottom 8px
    &-price
      color $helline-premium
      font-size 14px
      font-weight 800
      line-height 18.2px
