@require '../includes/*'
  
#helline
  .btn
    &-primary
      &--circle-with-icon // no circle on helline change name after split
        border-radius unset
        background-color $helline-dark-blue
        border 2px solid $helline-dark-blue

        & i
          background-color $helline-white

        &:hover, &-hover, &:active:not(:focus-visible):active, &-active //the &-hover is for the styleguide, as hover can not be simulated.
          background-color $helline-white
          border 2px solid $helline-dark-blue
          text-decoration none

          & i
            background-color $helline-dark-blue !important

        &:focus, &-focus
          background-color $helline-white
          border-color $helline-dark-blue

          i
            background-color $helline-dark-blue  !important

    &-secondary
      & i
        background-color $helline-dark-blue !important

        &:hover, &-hover
          background-color $helline-dark-blue !important

      &--circle-with-icon
        background-color $secondary-navigation-pill-background

        i
          background-color $helline-dark-blue !important

        &:hover
          background-color $helline-secondary-hover

    &-tertiary
      & i
        background-color #fff

      &:hover, &-hover
        & i
          background-color #fff

      &--circle-with-icon
        border-radius 0
        background-color $helline-dark-blue
        border 2px solid $helline-dark-blue
        color $helline-white

        & i
          background-color $helline-white

        &:hover
          background-color $helline-black !important
          color $helline-white !important
