columns( count, gap )
  -moz-column-count count
  -moz-column-gap gap
  -webkit-column-count count
  -webkit-column-gap gap
  column-count count
  column-gap gap
inline-block()
  display inline-block

reset-button-style()
  -webkit-appearance none !important
  cursor pointer
  border none
  background-color transparent
  padding 0
  color inherit

reset-list-style()
  list-style none
  padding 0
  margin 0

.stay-connected-checkbox-container

    &__text
      cursor pointer

.stay-connected-text-container
  margin-top -12px
  margin-down 25px
  background-color #E5ECFF
  padding 8px 16px

  &.inactive
    display none