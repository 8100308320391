@require 'includes/*'


.lookbook
  &__product
    border-bottom 1px solid $witt-default-border
    padding 15px 0px 20px 0px
    &__image
      border 1px solid $witt-default-border
      width 92px
    &__title
      font-weight bold
      font-size 15px
  &__mainImage
    img
      width 100%
  &__additionalImages
    display flex
    margin-top 15px
    cursor pointer
    &__item
      width 85px !important
      height 115px
      border 1px solid $witt-default-border
      margin-left 10px

      &.active
        border-color $witt-black

      img
        max-height 100%
        max-width 100%
        width auto
        height auto
  .btn-ghost
    background-color $witt-white
    margin 20px 0px 20px 0px
